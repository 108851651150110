import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';
import { getCurrencySymbol, useTranslation } from '@oolio-group/localization';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import theme from '../../../../../common/default-theme';
import SelectMultiple, {
  SelectMultipleOption,
} from '../../../../../components/Shared/Select/SelectMultiple';
import {
  Adjustment,
  AdjustmentRule,
  AdjustmentType,
  AdjustmentUnit,
  AdvanceRules,
  DayOfWeek,
  DiscountOperator,
  DiscountRuleCriteria,
  DiscountRuleType,
  NewAdjustment,
  TimeRoutine,
  UpdateAdjustment,
} from '@oolio-group/domain';
import { countries } from 'countries-list';
import { useSession } from '../../../../../hooks/app/useSession';
import styles from './Adjustments.style';
import { daysOption } from '../../../../../utils/dateHelper';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import { useAdjustments } from '../../../../../hooks/app/useAdjustments';
import { useRoute } from '@react-navigation/native';
import { formatToDigitsStr } from '@oolio-group/client-utils';
import { useOrderTypes } from '../../../../../hooks/app/orderTypes/useOrderTypes';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import { AdjustmentScheduleRow } from '../../../../../components/Modals/AdjustmentSchedule/AdjustmentScheduleRow';
import { useModal } from '@oolio-group/rn-use-modal';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { useVenues } from '../../../../../hooks/app/useVenues';
import InputDropdown from '../../../../../components/Shared/Inputs/InputDropdown';
import SelectDate from '../../../../../components/Shared/Select/SelectDate';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import { cloneDeep, omit } from 'lodash';
import MessageAction from '../../../../../components/Office/MessageAction/MessageAction';
import { useNotification } from '../../../../../hooks/Notification';
import { differenceInDays } from 'date-fns';
import AdvanceDiscountRules from './AdvanceDiscountRules';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import {
  ProductNameFragment,
  useProducts,
} from '../../../../../hooks/app/products/useProducts';
import {
  CATEGORIES_AS_NAME,
  useCategories,
} from '../../../../../hooks/app/categories/useCategories';

export type ErrorState = Record<'name' | 'amount', boolean>;

export interface TableRowProp {
  label: string;
  value: DayOfWeek;
}
export interface AdjustmentObject {
  isActive: boolean;
  day?: DayOfWeek;
  startTime: string;
  endTime: string;
}

export type WeekDayAdjustmentType = {
  [key in DayOfWeek]?: AdjustmentObject;
};

const GUEST_COUNT_OPTIONS = [1, 2, 3, 4, 5, 6];

interface DateTimeRangeMap {
  id: number;
  endDate: number;
  startDate: number;
}

const setDefaultAdvanceRule = (
  discountRuleType: DiscountRuleType,
  advanceRules: AdvanceRules,
) => {
  if (
    [
      DiscountRuleType.PRODUCT_CATEGORY_RULE,
      DiscountRuleType.PRODUCT_CATEGORY_QTY_RULE,
    ].includes(discountRuleType)
  ) {
    const isProductCategoryRule =
      discountRuleType === DiscountRuleType.PRODUCT_CATEGORY_RULE;
    advanceRules.purchasedCriteria = {
      ...(advanceRules.purchasedCriteria as DiscountRuleCriteria),
      qty: isProductCategoryRule
        ? undefined
        : advanceRules.purchasedCriteria?.qty || 1,
      operator: isProductCategoryRule
        ? undefined
        : advanceRules.purchasedCriteria?.operator || DiscountOperator.EXACTLY,
    };
  } else {
    advanceRules.discountedCriteria = {
      ...(advanceRules.discountedCriteria as DiscountRuleCriteria),
      qty: advanceRules.discountedCriteria?.qty || 1,
      operator:
        advanceRules.discountedCriteria?.operator || DiscountOperator.EXACTLY,
    };
    advanceRules.purchasedCriteria = {
      ...(advanceRules.purchasedCriteria as DiscountRuleCriteria),
      qty: advanceRules.purchasedCriteria?.qty || 1,
      operator:
        advanceRules.purchasedCriteria?.operator || DiscountOperator.EXACTLY,
    };
  }
  return advanceRules;
};

const CreateOrUpdateAdjustment: React.FC = () => {
  const { translate } = useTranslation();
  const [session] = useSession();
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const [dateTimeRange, setDateTimeRange] = useState<DateTimeRangeMap[]>();
  const [weekDayAdjustment, setWeekdayAdjustment] =
    useState<WeekDayAdjustmentType>();
  const { showNotification } = useNotification();

  const [form, setForm] = useState<Adjustment>({
    adjustmentUnit: AdjustmentUnit.PERCENTAGE,
    isScheduledEnabled: false,
    isDateRangeEnabled: false,
  } as Adjustment);
  const deletingId = useRef('');

  /*
    routeType define the adjustment type (This is going to new workflow)
    if we don't have routeType then we're going to follow the old flow where we need to enter negative amount for discount
  */
  const { venueId, adjustmentId, routeType } = route.params as {
    venueId: string;
    adjustmentId: string;
    routeType?: AdjustmentType;
  };

  const { country = 'AU' } = session?.currentOrganization || {};
  const currencyCode = countries[country]?.currency;

  const adjustmentRuleOptions = [
    {
      label: translate('backofficeVenueSettingAdjustments.guestCount'),
      value: AdjustmentRule.GUEST_COUNT,
    },
  ];

  const {
    adjustment,
    loading,
    getAdjustmentById,
    updateAdjustments,
    deleteAdjustment,
  } = useAdjustments({
    venueId,
  });
  const {
    orderTypes,
    loading: orderTypesLoading,
    getOrderTypes,
  } = useOrderTypes();
  const {
    loading: venueLoading,
    getStoresByVenue,
    storesByVenue,
  } = useVenues();

  const { getAllProducts, products } = useProducts(
    undefined,
    ProductNameFragment,
  );

  const { categoryMaps, getCategories } = useCategories({
    customFragment: CATEGORIES_AS_NAME,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    adjustmentId && getAdjustmentById(adjustmentId);
    if (routeType === AdjustmentType.DISCOUNT) {
      getAllProducts();
      getCategories();
    }
  }, [
    getAdjustmentById,
    adjustmentId,
    routeType,
    getAllProducts,
    getCategories,
  ]);

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  useEffect(() => {
    if (!venueLoading && !storesByVenue?.length) {
      getStoresByVenue(venueId);
    }
  }, [getStoresByVenue, venueId, storesByVenue, venueLoading]);

  useEffect(() => {
    if (adjustment) {
      setForm({
        id: adjustment.id,
        name: adjustment.name,
        adjustmentCode: adjustment.adjustmentCode,
        displayNameOnReceipt: adjustment.displayNameOnReceipt,
        /*
        To support both the flow old and new; we need this
        Old flow: we're showing the -DISCOUNT_AMOUNT
        New flow: we're showing absolute value for both discount and surcharge
        */
        amount: routeType ? Math.abs(adjustment.amount) : adjustment.amount,
        adjustmentUnit: adjustment.adjustmentUnit,
        orderTypes: adjustment.orderTypes,
        stores: adjustment.stores,
        allowOnPaymentType: adjustment.allowOnPaymentType,
        doNotIncludeInSalesAmount: adjustment.doNotIncludeInSalesAmount,
        isAdvanceRuleEnabled: adjustment.isAdvanceRuleEnabled,
        ...(routeType === AdjustmentType.DISCOUNT && {
          isDiscountRuleEnabled: adjustment.isDiscountRuleEnabled,
          discountRuleType: adjustment.discountRuleType,
        }),
        autoApply: adjustment.autoApply,
        isDateRangeEnabled: adjustment.isDateRangeEnabled,
        isScheduledEnabled: adjustment.isScheduledEnabled,
        advanceRules: adjustment.advanceRules,
        dateTimeRange: adjustment.dateTimeRange,
      } as Adjustment);

      if (adjustment.dateTimeRange) {
        setDateTimeRange(
          (adjustment.dateTimeRange || []).map((range, index) => ({
            id: index,
            ...range,
          })),
        );
      }

      setWeekdayAdjustment(getSchedulerState(adjustment?.schedule));
    }
  }, [adjustment, routeType]);

  const onChangeWeekdayAdjustments = useCallback(
    (key: DayOfWeek, value: Partial<AdjustmentObject>) => {
      setWeekdayAdjustment({
        ...weekDayAdjustment,
        [key]: {
          ...weekDayAdjustment?.[key],
          ...value,
        },
      });
    },
    [weekDayAdjustment],
  );

  function getSchedulerState(
    schedule: TimeRoutine[] | undefined,
  ): WeekDayAdjustmentType {
    if (!schedule?.length) return {};
    const scheduleState: WeekDayAdjustmentType = {};
    schedule.forEach((s: TimeRoutine) => {
      scheduleState[s.day] = {
        day: s.day,
        isActive: s.isActive || false,
        startTime: s.timeSlot.startTime,
        endTime: s.timeSlot.endTime,
      };
    });
    return scheduleState;
  }

  const prepareSchedulerObjectForServer = (
    adjustment: AdjustmentObject,
    day: DayOfWeek,
  ) => {
    return {
      isActive: adjustment.isActive,
      day,
      timeSlot: {
        startTime: adjustment.startTime || '00:00',
        endTime: adjustment.endTime || '23:59',
      },
    };
  };

  const onConfirmDeleteAdjustment = useCallback(
    (adjustment: Adjustment) => {
      deleteAdjustment(adjustment.id as string);
      deletingId.current = adjustment.id as string;
      closeModal();
    },
    [closeModal, deleteAdjustment],
  );

  const handleDeleteItem = useCallback((): void => {
    adjustment &&
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: adjustment.name,
          })}
          onConfirm={() => onConfirmDeleteAdjustment(adjustment)}
        />,
      );
  }, [showModal, translate, adjustment, onConfirmDeleteAdjustment]);

  const onConfirmRemoveSchedule = useCallback(
    (key: string, value: boolean) => {
      setForm({ ...form, [key]: value, autoApply: false });
      closeModal();
    },
    [closeModal, form],
  );

  /**
   * key = isScheduledEnabled or isDateRangeEnabled for which we needs to show the warning model
   * value = boolean
   * preConditions to show this model is
   * 1. When we open the adjustment in edit mode
   * 2. When any opened adjustment has configured the date range or time period
   * 3. When we disabled both date range and time period
   */
  const checkAndShowScheduleWarningModal = useCallback(
    (key: string, value: boolean) => {
      if (
        adjustment &&
        form.autoApply &&
        ['isScheduledEnabled', 'isDateRangeEnabled'].includes(key) &&
        (adjustment?.isDateRangeEnabled ||
          (adjustment?.isScheduledEnabled !== false &&
            adjustment.schedule?.length)) &&
        ((key === 'isScheduledEnabled' &&
          !value &&
          !form?.isDateRangeEnabled) ||
          (key === 'isDateRangeEnabled' &&
            !value &&
            form?.isScheduledEnabled === false))
      ) {
        showModal(
          <ConfirmationDialog
            title={translate(
              'backofficeVenueSettingAdjustments.removeScheduleTitle',
            )}
            message={translate(
              'backofficeVenueSettingAdjustments.removeScheduleDescription',
            )}
            confirmLabel={translate('button.remove')}
            cancelLabel={translate('button.dismiss')}
            onConfirm={() => onConfirmRemoveSchedule(key, value)}
          />,
        );
        return false;
      }
      return true;
    },
    [adjustment, showModal, translate, onConfirmRemoveSchedule, form],
  );

  const onChangeFormInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      const isValid = checkAndShowScheduleWarningModal(key, value);
      if (!isValid) return;

      let advanceRules: AdvanceRules = form.advanceRules ?? {};
      if (key === 'isAdvanceRuleEnabled') {
        advanceRules = value
          ? {
              applyOn: AdjustmentRule.GUEST_COUNT,
              guestCount: 1,
            }
          : (null as unknown as AdvanceRules);
      }
      if (['purchasedCriteria', 'discountedCriteria'].includes(key)) {
        advanceRules = {
          ...form.advanceRules,
          [key]: value,
        };
      }
      if (key === 'isDiscountRuleEnabled' && value) {
        form.discountRuleType =
          form.discountRuleType || DiscountRuleType.PRODUCT_CATEGORY_RULE;
      }
      if (key === 'discountRuleType' && value !== form.discountRuleType) {
        advanceRules = setDefaultAdvanceRule(
          value as DiscountRuleType,
          cloneDeep(advanceRules),
        );
      }
      setForm({
        ...form,
        [key]: value,
        advanceRules,
      });
    },
    [form, setForm, checkAndShowScheduleWarningModal],
  );

  const hasScheduled = useCallback(() => {
    const activeDays = Object.values(weekDayAdjustment || {}).filter(
      s => s.isActive,
    );
    return !!(
      (form.isScheduledEnabled !== false && activeDays?.length) ||
      (form.isDateRangeEnabled && dateTimeRange?.length)
    );
  }, [form, weekDayAdjustment, dateTimeRange]);

  const onToggleBehaviour = useCallback(() => {
    if (!form.autoApply && !hasScheduled()) {
      return;
    }
    onChangeFormInput('autoApply', !form.autoApply);
  }, [form.autoApply, onChangeFormInput, hasScheduled]);

  const onChangeAdvanceRule = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      setForm({
        ...form,
        advanceRules: {
          ...form.advanceRules,
          [key]: value,
        } as AdvanceRules,
      });
    },
    [form, setForm],
  );

  // check the number of full day periods between two dates
  const hasValidDateRange = useMemo(() => {
    return dateTimeRange?.every(
      item => differenceInDays(item.endDate, item.startDate) >= 0,
    );
  }, [dateTimeRange]);

  const getAdjustmentType = () => {
    return (
      routeType
        ? routeType === AdjustmentType.DISCOUNT
          ? translate('backofficeVenueSettingAdjustments.discount')
          : translate('backofficeVenueSettingAdjustments.surcharge')
        : translate('backofficeVenueSettingAdjustments.adjustment')
    ).toLowerCase();
  };

  const filteredAdvancedDiscountCriteria = (adjustment: Adjustment) => {
    if (
      adjustment.advanceRules?.discountedCriteria &&
      adjustment.discountRuleType &&
      adjustment.discountRuleType !==
        DiscountRuleType.PRODUCT_CATEGORY_BUY_SOME_GET_SOME_RULE
    ) {
      delete adjustment.advanceRules?.discountedCriteria;
    }
  };
  const onSave = () => {
    const keys: DayOfWeek[] = Object.keys(
      weekDayAdjustment ?? {},
    ) as DayOfWeek[];
    if (dateTimeRange && dateTimeRange.length > 0 && !hasValidDateRange) {
      showNotification({
        error: true,
        message: translate('backofficeVenueSettingAdjustments.errorDateRange'),
      });
      return;
    }
    const schedule = keys
      .filter(key => weekDayAdjustment?.[key]?.isActive)
      .map((day: DayOfWeek) =>
        prepareSchedulerObjectForServer(
          weekDayAdjustment?.[day] as AdjustmentObject,
          day,
        ),
      );

    const cleanedDateTimeRange = (dateTimeRange || []).map(item =>
      omit(item, ['__typename', 'id']),
    );
    if (
      form.autoApply &&
      !(form.isDateRangeEnabled && cleanedDateTimeRange?.length) &&
      !(form.isScheduledEnabled !== false && schedule?.length)
    ) {
      showNotification({
        error: true,
        message: translate(
          'backofficeVenueSettingAdjustments.defineScheduleRange',
        ),
      });
      return;
    }

    let amount = +form.amount;

    if (routeType) {
      amount =
        routeType === AdjustmentType.DISCOUNT
          ? -Math.abs(form.amount)
          : Math.abs(form.amount);
    }

    let adjustmentInput = {
      ...(form.id && { id: form.id }),
      name: form.name,
      amount: amount,
      adjustmentUnit: form.adjustmentUnit,
      displayNameOnReceipt: form.displayNameOnReceipt,
      adjustmentCode: form.adjustmentCode,
      orderTypes: form.orderTypes,
      stores: form.stores,
      schedule,
      allowOnPaymentType: form.allowOnPaymentType,
      doNotIncludeInSalesAmount: form.doNotIncludeInSalesAmount,
      isAdvanceRuleEnabled: form.isAdvanceRuleEnabled,
      ...(routeType === AdjustmentType.DISCOUNT && {
        isDiscountRuleEnabled: form.isDiscountRuleEnabled,
        discountRuleType: form.discountRuleType,
      }),
      autoApply: form.autoApply,
      advanceRules: form.advanceRules,
      dateTimeRange: cleanedDateTimeRange,
      isDateRangeEnabled: form.isDateRangeEnabled,
      isScheduledEnabled: form.isScheduledEnabled,
    };
    adjustmentInput = stripProperties(adjustmentInput, '__typename');
    filteredAdvancedDiscountCriteria(adjustmentInput as Adjustment);
    updateAdjustments({
      newAdjustments: !form.id
        ? ([adjustmentInput] as unknown as NewAdjustment[])
        : [],
      updateAdjustments: form.id
        ? ([adjustmentInput] as unknown as UpdateAdjustment[])
        : [],
    });
  };

  /**
   * Handles updates to a DateTime range in the state based on the provided parameters.
   *
   * @param {string} id - Identifier for the DateTime range ('fromDateRange' or 'toDateRange').
   * @param {Date} date - The new Date value for the DateTime range.
   * @param {number} index - Index of the DateTime range in the state array to be updated.
   */
  const handleDateTimeRange = useCallback((id, date, index) => {
    if (id !== 'fromDateRange' && id !== 'toDateRange') {
      return;
    }

    // we want to adjust them to represent the entire day (from midnight to the end of the day),
    const timestamp =
      id === 'fromDateRange'
        ? Math.floor(new Date(date).setHours(0, 0, 0, 0) / 1000)
        : Math.floor(new Date(date).setHours(23, 59, 59, 999) / 1000);

    setDateTimeRange(prevDateTimeRange =>
      (prevDateTimeRange || []).map((item, i) =>
        i === index
          ? {
              ...item,
              startDate: id === 'fromDateRange' ? timestamp : item.startDate,
              endDate: id === 'toDateRange' ? timestamp : item.endDate,
            }
          : item,
      ),
    );
  }, []);

  const getStartDate = (date?: number) => {
    return date ? new Date(date * 1000) : new Date();
  };

  const getEndDate = (date?: number) => {
    return date ? new Date(date * 1000) : undefined;
  };

  const getMinDate = (date?: number, id?: string) => {
    const currentDate = new Date();
    if (date === undefined) return currentDate;
    if (id === 'fromDateRange')
      return new Date(Math.min(date * 1000, currentDate.getTime()));
    if (id === 'toDateRange') return new Date(date * 1000);
  };

  const onPressDateRangeAction = (action: string, index?: number) => {
    if (action === 'add') {
      setDateTimeRange(prevDateTimeRange => [
        ...(prevDateTimeRange || []),
        {
          id: dateTimeRange?.length ?? 0,
          startDate: Math.floor(new Date().getTime() / 1000),
          endDate: 0,
        },
      ]);
    }
    if (action === 'delete') {
      setDateTimeRange(prevDateTimeRange =>
        (prevDateTimeRange || []).filter(item => item.id !== index),
      );
    }
  };

  const isActiveSchedule = useMemo(() => {
    if (!weekDayAdjustment || !Object.keys(weekDayAdjustment).length) {
      return false; // or handle the undefined case accordingly
    }
    return Object.values(weekDayAdjustment).some(item => item?.isActive);
  }, [weekDayAdjustment]);

  return (
    <ScreenLayout
      loading={loading || orderTypesLoading || venueLoading}
      title="Adjustments"
      onSave={onSave}
      onDelete={handleDeleteItem}
      onSaveDisabled={!form.name?.trim() || !+form.amount}
    >
      <Section
        title={translate('backofficeVenueSettingAdjustments.details')}
        layoutWidth="small"
      >
        <View style={theme.forms.row}>
          <InputText
            testID="adjustment-name"
            title={translate('backofficeVenueSettingAdjustments.name')}
            value={form?.name}
            placeholder={translate('backofficeVenueSettingAdjustments.name')}
            onChangeText={text => onChangeFormInput('name', text)}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="adjustment-code"
            title={translate('backofficeVenueSettingAdjustments.code')}
            value={form?.adjustmentCode}
            placeholder={translate('backofficeVenueSettingAdjustments.code')}
            onChangeText={text => onChangeFormInput('adjustmentCode', text)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="adjustment-show-on-receipt-as"
            title={translate(
              'backofficeVenueSettingAdjustments.showOnReceiptAs',
            )}
            value={form?.displayNameOnReceipt}
            placeholder={translate(
              'backofficeVenueSettingAdjustments.showOnReceiptAs',
            )}
            onChangeText={text =>
              onChangeFormInput('displayNameOnReceipt', text)
            }
            containerStyle={theme.forms.inputHalf}
          />
          <InputDropdown
            testID="adjustment-input-amount"
            title={translate('backofficeVenueSettingAdjustments.amount')}
            placeholder="0.00"
            value={String(form?.amount ?? '')}
            selectedOption={form?.adjustmentUnit || AdjustmentUnit.PERCENTAGE}
            options={[
              { value: AdjustmentUnit.PERCENTAGE, label: '%' },
              {
                value: AdjustmentUnit.FLAT,
                label: getCurrencySymbol(currencyCode),
              },
            ]}
            onChangeText={value =>
              onChangeFormInput('amount', formatToDigitsStr(value, true))
            }
            onOptionChange={value => onChangeFormInput('adjustmentUnit', value)}
            direction="rtl"
            alignText="left"
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="adjustment-stores"
            title={translate('backofficeVenueSettingAdjustments.stores')}
            options={
              (storesByVenue?.map(s => ({
                label: s?.name,
                value: s?.id,
              })) as SelectMultipleOption[]) ?? []
            }
            selectedValues={form?.stores}
            containerStyle={theme.forms.inputFluid}
            onValueChange={value => onChangeFormInput('stores', value)}
          />
        </View>

        <View style={theme.forms.row}>
          <SelectMultiple
            testID="adjustment-order-types"
            title={translate('backofficeVenueSettingAdjustments.orderTypes')}
            options={
              orderTypes?.map(t => ({ label: t.name, value: t.id })) ?? []
            }
            selectedValues={form?.orderTypes}
            containerStyle={theme.forms.inputFluid}
            onValueChange={value => onChangeFormInput('orderTypes', value)}
          />
        </View>
      </Section>

      <Section
        title={translate('backofficeVenueSettingAdjustments.schedule')}
        layoutWidth="small"
      >
        <InputToggle
          type="switch"
          testID="enable-date-range-btn"
          onToggle={() =>
            onChangeFormInput('isDateRangeEnabled', !form.isDateRangeEnabled)
          }
          isToggled={form.isDateRangeEnabled ?? false}
          title={translate('backofficeVenueSettingAdjustments.setDateRange')}
          containerStyle={theme.forms.inputFluid}
        />
        <MessageAction
          message={translate(
            'backofficeVenueSettingAdjustments.setDateRangeDescription',
          )}
          type={'neutral'}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{
            marginVertical: 10,
          }}
          displayActionIcon={false}
        />
        <>
          {dateTimeRange && dateTimeRange.length > 0 ? (
            dateTimeRange.map((range, index) => (
              <View style={theme.forms.row} key={index}>
                <SelectDate
                  testID="date-from"
                  title={translate('backOfficeReports.header.startDate')}
                  valueDate={getStartDate(range.startDate)}
                  onChangeDate={date =>
                    handleDateTimeRange('fromDateRange', date, range.id)
                  }
                  minDate={getMinDate(range.startDate, 'fromDateRange')}
                  // eslint-disable-next-line react-native/no-inline-styles
                  containerStyle={{ width: 200 }}
                />
                <SelectDate
                  testID="date-to"
                  title={translate('backOfficeReports.header.endDate')}
                  valueDate={getEndDate(range.endDate)}
                  onChangeDate={date =>
                    handleDateTimeRange('toDateRange', date, range.id)
                  }
                  minDate={getMinDate(range.startDate, 'toDateRange')}
                  // eslint-disable-next-line react-native/no-inline-styles
                  containerStyle={{ width: 200 }}
                />
                <ButtonIcon
                  testID={'btn-date-range-remove'}
                  icon={'trash-alt'}
                  size={34}
                  type={'negativeLight'}
                  containerStyle={styles.removeButton}
                  onPress={() => {
                    onPressDateRangeAction('delete', range.id);
                  }}
                />
              </View>
            ))
          ) : (
            <></>
          )}
        </>
        <ButtonIcon
          testID="btn-date-range-add"
          icon={'plus'}
          size={34}
          type={'positive'}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={styles.addButton}
          onPress={() => {
            onPressDateRangeAction('add');
          }}
        />
        <InputToggle
          type="switch"
          testID="enable-time-periods-btn"
          onToggle={() =>
            onChangeFormInput(
              'isScheduledEnabled',
              form.isScheduledEnabled === null
                ? !isActiveSchedule
                : !form.isScheduledEnabled,
            )
          }
          isToggled={
            form.isScheduledEnabled === null
              ? isActiveSchedule
              : !!form.isScheduledEnabled
          }
          title={translate('backofficeVenueSettingAdjustments.setTimePeriod')}
          containerStyle={theme.forms.inputFluid}
        />
        <MessageAction
          message={translate(
            'backofficeVenueSettingAdjustments.setTimePeriodDescription',
          )}
          type={'neutral'}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{
            marginVertical: 10,
          }}
          displayActionIcon={false}
        />
        <TableComponent
          columns={[
            {
              title: translate('backOfficeFeatures.day'),
              width: 240,
            },
            {
              title: translate('backofficeVenueSettingAdjustments.starts'),
              width: 80,
            },
            {
              title: translate('backofficeVenueSettingAdjustments.ends'),
              width: 80,
            },
          ]}
          columnSpacing={0}
          data={daysOption}
          columnContainerStyle={styles.columnContainerStyle}
          renderRow={(row: TableRowProp): React.ReactNode => {
            return (
              <AdjustmentScheduleRow
                key={row.value}
                row={row}
                weekDayAdjustment={weekDayAdjustment}
                onChangeWeekdayAdjustments={onChangeWeekdayAdjustments}
                isScheduledEnabled={form.isScheduledEnabled !== false}
              />
            );
          }}
        />
      </Section>
      <Section
        title={translate('backofficeVenueSettingAdjustments.behaviour')}
        layoutWidth="small"
      >
        <View style={theme.forms.row}>
          <InputToggle
            type="switch"
            testID="toggle-autoApply"
            onToggle={onToggleBehaviour}
            isToggled={!!form.autoApply}
            title={translate(
              'backofficeVenueSettingAdjustments.behaviourTitle',
            )}
            containerStyle={theme.forms.inputFluid}
            subtitle={translate(
              'backofficeVenueSettingAdjustments.behaviourDescription',
              {
                type: getAdjustmentType(),
              },
            )}
          />
        </View>
      </Section>
      <Section
        title={translate('backofficeVenueSettingAdjustments.advancedOptions')}
        layoutWidth="small"
      >
        <>
          <View style={theme.forms.row}>
            <InputToggle
              type="switch"
              testID="toggle-allowOnPaymentType"
              onToggle={() =>
                onChangeFormInput(
                  'allowOnPaymentType',
                  !form.allowOnPaymentType,
                )
              }
              isToggled={form?.allowOnPaymentType ?? false}
              title={translate(
                'backofficeVenueSettingAdjustments.allowOnPaymentType',
              )}
              containerStyle={theme.forms.inputFluid}
              subtitle={translate(
                'backofficeVenueSettingAdjustments.allowOnPaymentSubTitle',
                { type: getAdjustmentType() },
              )}
            />
          </View>
          {(!routeType || routeType === AdjustmentType.SURCHARGE) && (
            <View style={theme.forms.row}>
              <InputToggle
                type="switch"
                testID="toggle-doNotIncludeInSalesAmount"
                onToggle={() =>
                  onChangeFormInput(
                    'doNotIncludeInSalesAmount',
                    !form.doNotIncludeInSalesAmount,
                  )
                }
                isToggled={form.doNotIncludeInSalesAmount ?? false}
                title={translate(
                  'backofficeVenueSettingAdjustments.doNotIncludeInSalesAmount',
                )}
                containerStyle={theme.forms.inputFluid}
                subtitle={translate(
                  'backofficeVenueSettingAdjustments.doNotIncludeInSalesAmountSubTitle',
                )}
              />
            </View>
          )}

          {(!routeType || routeType === AdjustmentType.SURCHARGE) && (
            <>
              <View style={theme.forms.row}>
                <InputToggle
                  type="switch"
                  testID="adjustment-advance-rule"
                  onToggle={() => {
                    onChangeFormInput(
                      'isAdvanceRuleEnabled',
                      !form.isAdvanceRuleEnabled,
                    );
                  }}
                  isToggled={form.isAdvanceRuleEnabled ?? false}
                  title={translate(
                    'backofficeVenueSettingAdjustments.advanceRules',
                  )}
                  containerStyle={theme.forms.inputFluid}
                  subtitle={translate(
                    'backofficeVenueSettingAdjustments.applyOnGuestCountSubTitle',
                  )}
                />
              </View>

              {form?.isAdvanceRuleEnabled && (
                <View style={theme.forms.row}>
                  <TreatPicker
                    testID="adjustment-rule-name"
                    title={translate(
                      'backofficeVenueSettingAdjustments.applyOn',
                    )}
                    options={adjustmentRuleOptions}
                    selectedValue={
                      adjustment?.advanceRules?.applyOn ??
                      AdjustmentRule.GUEST_COUNT
                    }
                    containerStyle={theme.forms.inputHalf}
                  />
                  <TreatPicker
                    testID="adjustment-rule-value"
                    title={translate(
                      'backofficeVenueSettingAdjustments.guestCount',
                    )}
                    options={GUEST_COUNT_OPTIONS.map(val => ({
                      label:
                        val === 6
                          ? translate(
                              'backofficeVenueSettingAdjustments.6_or_more',
                            )
                          : String(val),
                      value: String(val),
                    }))}
                    selectedValue={String(form.advanceRules?.guestCount)}
                    onValueChange={value =>
                      onChangeAdvanceRule('guestCount', +value)
                    }
                    containerStyle={theme.forms.inputHalf}
                  />
                </View>
              )}
            </>
          )}

          {routeType === AdjustmentType.DISCOUNT && (
            <AdvanceDiscountRules
              form={form}
              onChangeFormInput={onChangeFormInput}
              categories={categoryMaps}
              products={products}
            />
          )}
        </>
      </Section>
    </ScreenLayout>
  );
};

export default CreateOrUpdateAdjustment;
