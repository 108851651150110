import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View, Platform, TouchableOpacity } from 'react-native';
import Search from '../../../../components/Shared/Search/Search';
import TreatPicker from '../../../../components/Shared/Select/Picker';
import { useTranslation } from '@oolio-group/localization';
import { ProductListItem } from './types';
import { DEFAULT_ENTITY_ID } from '@oolio-group/domain';
import { FilterValue } from './ProductList';
import Icon from '../../../../components/Icon/Icon';
import theme from '../../../../common/default-theme';
import ModalWrapper from '../../../../hooks/ModalWrapper';
import ProductFilterModal from './Modals/ProductFilterModal';
import { useExportEntityInterval } from '../../../../hooks/app/exportEntities/useExportEntityInterval';
import ButtonActions from '../../../../components/Shared/TreatButton/ButtonActions';
import { useNotification } from '../../../../hooks/Notification';

export interface LabelFilter {
  value: string;
  label: string;
}

interface ProductRowProps {
  categories: LabelFilter[];
  storeOptions: LabelFilter[];
  filter: FilterValue;
  onChangeFilter: (value: FilterValue) => void;
  onPressImport: () => void;
  isImportProductFeatureEnabled: boolean;
  printerProfileOptions: { value: string; label: string }[];
  optionsGroupOptions: { value: string; label: string }[];
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdown: {
    width: Platform.select({
      web: 180,
      default: 140,
    }),
    marginRight: 10,
  },
  search: {
    flex: 1,
    height: 44,
    marginRight: 10,
  },
  uploadButton: {
    marginRight: 10,
  },
  btnContainer: {
    marginRight: 10,
  },
  filterContainer: {
    ...theme.forms.inputHalf,
    height: 200,
  },
  btn: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
  },
});

export const getProductsBySearchQuery = (
  products: ProductListItem[],
  searchQuery: string,
): ProductListItem[] => {
  const searchString = searchQuery.toLowerCase();
  const searchResult = products.filter(
    product =>
      product.name.toLowerCase().includes(searchString) ||
      product.barcode?.toLowerCase().includes(searchString),
  );
  searchResult.forEach(searchItem => {
    const variantId = searchItem.id;
    const parentProduct = products.find(product =>
      product.productIds?.includes(variantId),
    );
    if (parentProduct && searchResult.every(x => x.id !== parentProduct.id)) {
      searchResult.push(parentProduct);
    }
  });

  return searchResult;
};

export const getProductByFilteredStore = (
  product: ProductListItem[],
  selectedStore: string,
): ProductListItem[] => {
  return product.filter(
    product => product.storeIds?.filter(store => store == selectedStore).length,
  );
};
export const getProductByPrinterProfile = (
  products: ProductListItem[],
  printerProfiles: string[],
  productsMap: {
    [key: string]: ProductListItem;
  },
): ProductListItem[] => {
  return products.filter(product => {
    if (product.isVariant) {
      // checking if child variants is having any match if yes including parent product
      const variants = product.productIds?.map(
        (varId: string) => productsMap[varId],
      );
      if (
        variants &&
        variants?.some(variant => {
          if (
            variant?.printerProfiles?.length &&
            variant?.printerProfiles.some(profile =>
              printerProfiles.includes(profile),
            )
          )
            return true;
          else if (
            !variant.printerProfiles?.length &&
            printerProfiles.includes('none')
          )
            return true;
          else return false;
        })
      )
        return product;
    }

    // checking if products is having any match if yes including product
    if (
      product?.printerProfiles?.length &&
      product?.printerProfiles.some(profile =>
        printerProfiles.includes(profile),
      )
    )
      return product;
    else if (
      (!product?.printerProfiles || product?.printerProfiles?.length === 0) &&
      printerProfiles.includes('none')
    )
      return product;
  });
};
export const getProductByOptionGroups = (
  products: ProductListItem[],
  optionGroups: string[],
  productsMap: {
    [key: string]: ProductListItem;
  },
): ProductListItem[] => {
  return products.filter(product => {
    if (product.isVariant) {
      // checking if child variants is having any match if yes including parent product
      const variants = product.productIds?.map(
        (varId: string) => productsMap[varId],
      );
      if (
        variants &&
        variants?.some(variant => {
          if (
            variant?.modifierGroups?.length &&
            variant?.modifierGroups.some(option =>
              optionGroups.includes(option.id),
            )
          )
            return true;
          else if (
            (!variant?.modifierGroups ||
              variant?.modifierGroups?.length === 0) &&
            optionGroups.includes('none')
          )
            return true;
          else return false;
        })
      )
        return product;
    } else {
      // checking if products is having any match if yes including product
      if (
        product?.modifierGroups?.length &&
        product?.modifierGroups.some(option => optionGroups.includes(option.id))
      )
        return product;
      else if (
        (!product?.modifierGroups || product?.modifierGroups?.length === 0) &&
        optionGroups.includes('none')
      )
        return product;
    }
  });
};

export const getProductByFilteredCategory = (
  products: ProductListItem[],
  selectedCategory: string,
): ProductListItem[] => {
  return products.filter(product => {
    if (selectedCategory === DEFAULT_ENTITY_ID) {
      // filter all uncategorized product
      return !product.category;
    }
    return product.category === selectedCategory;
  });
};

export const canRenderVariant = (
  variant: ProductListItem,
  filter: FilterValue,
) => {
  const printerProfilesFilter = filter.printerProfilesFilter;
  const optionGroupsFilter = filter.optionGroups;

  let printerProfilesMatch = false;
  let optionGroupsMatch = false;

  if (!printerProfilesFilter?.length) {
    printerProfilesMatch = true;
  }
  if (!optionGroupsFilter?.length) {
    optionGroupsMatch = true;
  }

  if (
    variant?.printerProfiles?.length &&
    variant?.printerProfiles.some(profile =>
      printerProfilesFilter.includes(profile),
    )
  )
    printerProfilesMatch = true;
  else if (
    (!variant?.printerProfiles || variant?.printerProfiles?.length === 0) &&
    printerProfilesFilter.includes('none')
  )
    printerProfilesMatch = true;

  if (
    variant?.modifierGroups?.length &&
    variant?.modifierGroups.some(option =>
      optionGroupsFilter.includes(option.id),
    )
  )
    optionGroupsMatch = true;
  else if (
    (!variant?.modifierGroups || variant?.modifierGroups?.length === 0) &&
    optionGroupsFilter.includes('none')
  )
    optionGroupsMatch = true;

  const canRender = optionGroupsMatch && printerProfilesMatch;

  return canRender;
};

export const ProductHeaderFilters: React.FC<ProductRowProps> = ({
  storeOptions,
  categories,
  filter,
  printerProfileOptions,
  optionsGroupOptions,
  onChangeFilter,
  onPressImport,
  isImportProductFeatureEnabled,
}) => {
  const { translate } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { processExportProduct } = useExportEntityInterval();
  const { showNotification } = useNotification();
  const onChange = useCallback(
    (name: keyof FilterValue, value: string | string[]) => {
      onChangeFilter({ ...filter, [name]: value });
    },
    [filter, onChangeFilter],
  );

  const onSetFilters = useCallback(
    (printerProfiles: string[], optionGroups: string[]) => {
      onChangeFilter({
        ...filter,
        printerProfilesFilter: printerProfiles,
        optionGroups: optionGroups,
      });
    },
    [filter, onChangeFilter],
  );

  const onPressExport = useCallback(() => {
    showNotification({
      info: true,
      message: translate('backOfficeProducts.exportRunning'),
    });
    processExportProduct();
  }, [processExportProduct, showNotification, translate]);

  const ACTION_OPTIONS = useMemo(
    () => [
      {
        testID: 'btn-import',
        label: translate('backOfficeProducts.import'),
        icon: 'cloud-upload',
        iconColor: theme.colors.states.neutral,
        action: onPressImport,
      },
      {
        testID: 'btn-export',
        label: translate('backOfficeProducts.export'),
        icon: 'cloud-download',
        iconColor: theme.colors.states.neutral,
        action: onPressExport,
      },
    ],
    [onPressExport, onPressImport, translate],
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID="btn-filter"
        onPress={() => setIsModalVisible(true)}
        style={[
          styles.btn,
          styles.btnContainer,
          { backgroundColor: theme.colors.blueLight },
        ]}
      >
        <Icon name="sliders-v" size={20} color={theme.colors.blue} />
      </TouchableOpacity>
      <TreatPicker
        testID="select-venue"
        selectedValue={filter.store}
        options={[
          {
            label: translate('backOfficeProducts.productFilterByVenue'),
            value: '',
          },
        ].concat(...storeOptions)}
        onValueChange={onChange.bind(null, 'store')}
        containerStyle={styles.dropdown}
      />
      <TreatPicker
        testID="select-category"
        selectedValue={filter.category}
        options={[
          {
            label: translate('backOfficeProducts.productFilterByCategory'),
            value: '',
          },
          {
            label: translate('backOfficeProducts.notCategorizedProducts'),
            value: DEFAULT_ENTITY_ID,
          },
        ].concat(...(categories || []))}
        onValueChange={onChange.bind(null, 'category')}
        containerStyle={styles.dropdown}
      />
      <Search
        testID="search-products"
        value={filter.searchString}
        onChangeText={onChange.bind(null, 'searchString')}
        placeholder={translate('backOfficeProducts.productSearchByName')}
        containerStyle={styles.search}
      />
      {isImportProductFeatureEnabled && (
        <ButtonActions
          icon="exchange"
          actions={ACTION_OPTIONS}
          type="neutralLight"
          containerStyle={styles.btnContainer}
          label=""
        />
      )}
      <ModalWrapper isVisible={isModalVisible}>
        <ProductFilterModal
          selectedFilters={filter}
          onChangeFilter={onSetFilters}
          printerProfileOptions={printerProfileOptions}
          optionsGroupOptions={optionsGroupOptions}
          onCloseModal={() => setIsModalVisible(false)}
        />
      </ModalWrapper>
    </View>
  );
};
