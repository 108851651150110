import { useMutation } from '@apollo/client/react/hooks';
import { useMemo, useCallback, useState } from 'react';
import { getProductPricingFragment } from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { Operation } from '../../../types/Operation';
import {
  ProductPricingInput,
  DeleteProductPricingInput,
} from '@oolio-group/domain';
import { useNotification } from '../../Notification';
import { translate } from '@oolio-group/localization';

interface UseProductPricingsInput {
  customFragment?: string;
}

export interface useProductPricingsProps {
  update: (input: ProductPricingInput[]) => void;
  delete: (input: DeleteProductPricingInput[]) => void;
  addProductPricing: (
    productId: string,
    pricings: {
      pricingGroupId: string;
      productPricing: ProductPricingInput;
    }[],
  ) => void;
  addBulkProductPricings: (
    input: {
      productId: string;
      pricings: {
        pricingGroupId: string;
        productPricing: ProductPricingInput;
      }[];
    }[],
  ) => void;
  loading: boolean;
  error: string | undefined;
  operation: Operation | null;
  deletedIds: Array<string>;
}

export function useProductPricings(
  input?: UseProductPricingsInput,
): useProductPricingsProps {
  const [operation, setOperation] = useState<Operation | null>(Operation.READ);
  const [deletedIds, setDeletedIds] = useState<Array<string>>([]);
  const { showNotification } = useNotification();
  const {
    UPDATE_PRODUCT_PRICING_MUTATION,
    ADD_BULK_PRODUCT_PRICING_MUTATION,
    ADD_PRODUCT_PRICING_MUTATION,
    DELETE_PRODUCT_PRICING_MUTATION,
  } = getProductPricingFragment(input?.customFragment);
  const [update, updateProductPricingsRes] = useMutation(
    UPDATE_PRODUCT_PRICING_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const [addProductPricing, addProductPricingRequest] = useMutation(
    ADD_PRODUCT_PRICING_MUTATION,
    {
      onError: noopHandler,
      onCompleted: () => {
        showNotification({
          success: true,
          message: translate('productSettings.productPricesSavedSuccessfully'),
        });
      },
    },
  );

  const [addBulkProductPricing, addBulkProductPricingRequest] = useMutation(
    ADD_BULK_PRODUCT_PRICING_MUTATION,
    {
      onError: noopHandler,
      onCompleted: () => {
        showNotification({
          success: true,
          message: translate('productSettings.updatedSuccessfully'),
        });
      },
    },
  );

  const [deletePricings, deleteOperation] = useMutation(
    DELETE_PRODUCT_PRICING_MUTATION,
    {
      onError: noopHandler,
      onCompleted: () => {
        showNotification({
          success: true,
          message: translate('backOfficeProducts.priceItemDeletedSuccessfully'),
        });
      },
    },
  );
  const updateProductPricings = useCallback(
    input => {
      update({ variables: { input } });
      setOperation(Operation.UPDATE);
    },
    [update],
  );
  const deleteProductPricings = useCallback(
    (input: DeleteProductPricingInput[]) => {
      deletePricings({ variables: { input } });
      setOperation(Operation.DELETE);
      setDeletedIds(input.map(x => x.productId));
    },
    [deletePricings],
  );

  const addProductPricingDetails = useCallback(
    (
      productId: string,
      pricings: {
        pricingGroupId: string;
        productPricing: ProductPricingInput;
      }[],
    ) => {
      addProductPricing({ variables: { productId, pricings } });
      setOperation(Operation.CREATE);
    },
    [addProductPricing],
  );

  const addBulkProductPricingsDetails = useCallback(
    input => {
      addBulkProductPricing({ variables: { input } });
      setOperation(Operation.CREATE);
    },
    [addBulkProductPricing],
  );

  const error =
    updateProductPricingsRes.error ||
    deleteOperation.error ||
    addProductPricingRequest.error ||
    addBulkProductPricingRequest.error;

  const loading =
    deleteOperation.loading ||
    addProductPricingRequest.loading ||
    addBulkProductPricingRequest.loading ||
    updateProductPricingsRes.loading;

  return useMemo(
    () => ({
      update: updateProductPricings,
      delete: deleteProductPricings,
      addProductPricing: addProductPricingDetails,
      addBulkProductPricings: addBulkProductPricingsDetails,
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      deletedIds,
    }),
    [
      updateProductPricings,
      deleteProductPricings,
      addProductPricingDetails,
      addBulkProductPricingsDetails,
      loading,
      error,
      operation,
      deletedIds,
    ],
  );
}
