import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import {
  Table,
  Order,
  OrderStatus,
  OrderTypeCode,
  TableStatus,
} from '@oolio-group/domain';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { ordersReceivedViaPollingAt } from '../../../../../state/cache';
import { refetchOrderObservable } from '../../../../../hooks/app/orders/ordersObservableUtils';
import { useOrders } from '../../../../../hooks/app/orders/useOrders';
import { useSession } from '../../../../../hooks/app/useSession';
import useBehaviorSubjectEffect from '../../../../../hooks/app/useSubjectEffect';
import useBehaviorSubjectState from '../../../../../hooks/app/useSubjectState';
import { useTimeout } from '../../../../../hooks/useTimeout';
import { getDayFormat } from '../../../../../utils/dateHelper';
import { sortTablesByName } from '../../../../../utils/TableHelper';
import {
  sectionIdController,
  sectionStatsVisibilityController,
} from '../Sections/floorViewObservables';
import { styles, getStickerType } from './FloorViewSidePanel.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import { constantCase } from 'change-case';

export enum SectionStatsFilterOption {
  currentSpend = 'currentSpend',
  timeSinceOccupied = 'timeSinceOccupied',
}

export const getSectionStatsFilterOption = (t: Function) => [
  {
    value: SectionStatsFilterOption.currentSpend,
    label: t('statisticsPanel.currentSpend'),
  },
  {
    value: SectionStatsFilterOption.timeSinceOccupied,
    label: t('statisticsPanel.timeSinceOccupied'),
  },
];

const SECTION_SUMMARY_ID = 'section-summary-id';

export interface TableWithOrder extends Table {
  order: Order;
}

const SectionStatsSidePanel: React.FC = ({}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const [{ deviceProfile }] = useSession();
  const { returnOrdersFromCache } = useOrders();

  const [statsViewOption, setStatsViewOption] =
    useState<SectionStatsFilterOption>(SectionStatsFilterOption.currentSpend);
  const { value: visible, setValue: setVisible } =
    useBehaviorSubjectState<boolean>(sectionStatsVisibilityController);
  const { value: sectionId } =
    useBehaviorSubjectState<string>(sectionIdController);

  const section = deviceProfile?.sections?.find(s => s.id === sectionId);
  const [tableWithOrdersInfo, setTableWithOrdersInfo] = useState<
    Record<string, Order[]>
  >({} as Record<string, Order[]>);

  const ordersUpdatedThroughPolling = useReactiveVar<number>(
    ordersReceivedViaPollingAt,
  );

  const makeTableOrderMap = useCallback(
    (
      result: Record<string, Order[]>,
      order: Order,
    ): Record<string, Order[]> => {
      if (order.orderType?.code === OrderTypeCode.DINE_IN && order?.table?.id) {
        result[order.table.id] = !!result[order.table.id]
          ? [...result[order.table.id], order].sort(
              (a, b) => a.createdAt - b.createdAt,
            )
          : [order];
      }
      return result;
    },
    [],
  );

  const makeSectionSummaryOrder = useCallback(
    (summaryOrder, order: Order): Order => {
      if (order.orderType?.code === OrderTypeCode.DINE_IN && order?.table?.id) {
        if (section?.tables.find(tab => tab.id === order?.table?.id)) {
          summaryOrder.totalPaymentAmount += order.totalPaymentAmount;
        }
      }
      return summaryOrder;
    },
    [section],
  );

  const refreshTableWithOrderInfo = useCallback(() => {
    const inProgressOrders = returnOrdersFromCache(OrderStatus.IN_PROGRESS);
    const holdOrders = returnOrdersFromCache(OrderStatus.ON_HOLD);
    const listOpenOrders = [...inProgressOrders, ...holdOrders];
    const mapToOrder = listOpenOrders.reduce<Record<string, Order[]>>(
      makeTableOrderMap,
      {},
    );
    const sectionSummary = listOpenOrders.reduce<Order>(
      makeSectionSummaryOrder,
      {
        table: {
          id: SECTION_SUMMARY_ID,
          status: TableStatus.AVAILABLE,
        } as Table,
        totalPaymentAmount: 0,
      } as Order,
    );

    mapToOrder[SECTION_SUMMARY_ID] = [sectionSummary];
    setTableWithOrdersInfo(mapToOrder);
  }, [returnOrdersFromCache, makeTableOrderMap, makeSectionSummaryOrder]);
  const delayRefreshTableWithOrderInfo = useTimeout(refreshTableWithOrderInfo);

  useEffect(() => {
    if (!visible) return;
    refreshTableWithOrderInfo();
  }, [visible, refreshTableWithOrderInfo]);

  useBehaviorSubjectEffect(refetchOrderObservable, refreshTableWithOrderInfo);

  useEffect(() => {
    if (ordersUpdatedThroughPolling) delayRefreshTableWithOrderInfo.start(2000);
  }, [ordersUpdatedThroughPolling, delayRefreshTableWithOrderInfo]);

  const tablesToShow = useMemo(() => {
    const tables = sortTablesByName(section?.tables ?? []).reduce<
      TableWithOrder[]
    >((acc, table) => {
      const orders = tableWithOrdersInfo[table.id] ?? [{}];
      const tablesWithOrders = orders.map((order, index) => {
        return {
          ...table,
          id: `${table.id}-${index}`,
          name: orders.length > 1 ? `${table.name}-${index + 1}` : table.name,
          status: order.table?.status ?? TableStatus.AVAILABLE,
          order,
        };
      });
      return [...acc, ...tablesWithOrders];
    }, []);

    return statsViewOption === SectionStatsFilterOption.currentSpend
      ? [
          {
            id: SECTION_SUMMARY_ID,
            status: TableStatus.IN_USE,
            name: constantCase(translate('statisticsPanel.all')),
            order: tableWithOrdersInfo[SECTION_SUMMARY_ID]?.[0],
          } as TableWithOrder,
          ...tables,
        ]
      : tables;
  }, [section, tableWithOrdersInfo, statsViewOption, translate]);

  const onClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  if (!visible) return null;

  return (
    <>
      <TouchableOpacity onPress={onClose} style={styles.overlay} />
      <View testID="sidePanel-sectionStats" style={styles.container}>
        <View style={styles.title}>
          <TouchableOpacity
            testID="btn-close"
            onPress={onClose}
            style={styles.btnClose}
          >
            <Icon name="times" size={18} color={theme.colors.dark} />
          </TouchableOpacity>
          <Text style={styles.titleText}>
            {`${translate('statisticsPanel.statistic')} (${section?.name})`}
          </Text>
        </View>
        <View style={styles.content}>
          <ScrollView style={styles.details}>
            <TreatPicker
              testID="select-statistic"
              selectedValue={statsViewOption}
              options={getSectionStatsFilterOption(translate)}
              onValueChange={setStatsViewOption}
            />
            <View style={styles.actions}>
              {tablesToShow.map(table => {
                const isOccupied = table.status !== TableStatus.AVAILABLE;
                const currentSpend = table.order?.totalPaymentAmount ?? 0;
                const currentOccupiedTime = table.order?.createdAt
                  ? getDayFormat(table.order.createdAt)
                  : '00:00';

                return (
                  <View
                    key={table.id}
                    testID="stat-row"
                    style={styles.tableRow}
                  >
                    <View style={styles.tableRowName}>
                      <View
                        style={[
                          styles.dot,
                          { backgroundColor: getStickerType(table.status) },
                        ]}
                      />
                      <Text style={styles.tableRowText}>{table.name}</Text>
                    </View>
                    <Text
                      style={[
                        styles.tableRowValue,
                        // eslint-disable-next-line react-native/no-inline-styles
                        { opacity: isOccupied ? 1 : 0.3 },
                      ]}
                    >
                      {statsViewOption === SectionStatsFilterOption.currentSpend
                        ? formatCurrency(currentSpend)
                        : currentOccupiedTime}
                    </Text>
                  </View>
                );
              })}
            </View>
          </ScrollView>
        </View>
      </View>
    </>
  );
};

export default SectionStatsSidePanel;
