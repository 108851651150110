import {
  useLazyQuery,
  useMutation,
  useApolloClient,
} from '@apollo/client/react/hooks';
import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import {
  CONVERT_PRODUCT_TO_VARIANT_MUTATION,
  DELETE_VARIANTS_MUTATION,
  BULK_UPDATE_VARIANT_CATEGORY,
  updateVariantMutation,
  getVariantsQuery,
  getVariantQuery,
  updateVariantsMutation,
  createVariantMutation,
  copyVariantMutation,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import {
  UpdateVariantInput,
  Variant,
  ConvertProductToVariantInput,
  CreateVariantProductInput,
  CopyVariantInput,
  BulkUpdateEntityAndCategoryInput,
} from '@oolio-group/domain';
import { Operation } from '../../../types/Operation';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { filterEntityByStore } from '@oolio-group/client-utils';
import keyBy from 'lodash/keyBy';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import { useNotification } from '../../Notification';
import { translate } from '@oolio-group/localization';
import { VARIANTS_FOR_COPY } from '../../../hooks/app/variants/graphql';

export interface useVariantsProps {
  variants: { [key: string]: Variant };
  updateVariant: (
    variantDetails: UpdateVariantInput,
  ) => Promise<Variant | undefined>;
  updateVariants: (variantDetails: UpdateVariantInput[]) => void;
  getAllVariants: () => void;
  convertProductToVariant: (
    productDetails: ConvertProductToVariantInput,
  ) => Promise<Variant | undefined>;
  createVariant: (
    variantInput: CreateVariantProductInput,
  ) => Promise<Variant | undefined>;
  copyVariant: (
    copyVariantInput: CopyVariantInput,
  ) => Promise<Variant | undefined>;
  deleteVariants: (ids: string[]) => void;
  getVariantData: (id: string) => void;
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  bulkUpdateVariantsCategory: (input: BulkUpdateEntityAndCategoryInput) => void;
  refreshVariants: () => void;
  updateCachedVariants: (variants: Variant[]) => void;
  deleteVariantProductInCache: (productId: string, variantId: string) => void;
}

export function useVariants(
  variantId?: string,
  customFragment?: string,
  filteringByStoreIds?: string[],
  fetchPolicyProp?: WatchQueryFetchPolicy,
): useVariantsProps {
  const [variants, setVariants] = useState<Record<string, Variant>>({});
  const [operation, setOperation] = useState<Operation>(Operation.READ);
  const client = useApolloClient();
  const bulkUpdateReqVariableRef = useRef<BulkUpdateEntityAndCategoryInput>({
    category: '',
    entityIds: [],
  });

  const deletingVariantIdsRef = useRef<string[]>([]);
  const GET_VARIANTS_QUERY = getVariantsQuery(customFragment);
  const { showNotification } = useNotification();

  const getCachedVariants = useCallback(() => {
    const cachedData = client.cache.readQuery<{ variants: Variant[] }>({
      query: GET_VARIANTS_QUERY,
    });
    return cachedData?.variants || [];
  }, [GET_VARIANTS_QUERY, client.cache]);

  const updateCachedVariants = useCallback(
    (variants: Variant[]) => {
      client.cache.writeQuery({
        query: GET_VARIANTS_QUERY,
        data: {
          variants,
        },
      });
    },
    [GET_VARIANTS_QUERY, client.cache],
  );

  const updateNewCreatedVariantToCached = useCallback(
    (createdVariant: Variant) => {
      showNotification({
        success: true,
        message: translate('productSettings.productSuccessfullyAdded', {
          name: createdVariant?.name,
        }),
      });
      const existingVariants = getCachedVariants();
      updateCachedVariants([...existingVariants, createdVariant]);
    },
    [getCachedVariants, showNotification, updateCachedVariants],
  );

  const [getVariantReq, getVariantRes] = useLazyQuery<{ variant: Variant }>(
    getVariantQuery(customFragment),
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
    },
  );

  const [getVariantsReq, getVariantsRes] = useLazyQuery<{
    variants: Variant[];
  }>(GET_VARIANTS_QUERY, {
    fetchPolicy: fetchPolicyProp || 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: noopHandler,
  });

  const refreshVariants = useCallback(() => {
    getVariantsRes.refetch && getVariantsRes.refetch();
  }, [getVariantsRes]);

  const [updateVariantReq, updateVariantRes] = useMutation<{
    updateVariant: Variant;
  }>(updateVariantMutation(customFragment), {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        success: true,
        message: translate('variants.updated'),
      });
    },
  });

  const [updateVariantsReq, updateVariantsRes] = useMutation(
    updateVariantsMutation(customFragment),
    {
      onError: noopHandler,
    },
  );

  const [createVariantReq, createVariantRes] = useMutation<{
    createVariant: Variant;
  }>(createVariantMutation(customFragment), {
    onError: noopHandler,
    onCompleted: data => {
      const createdVariant = data.createVariant;
      updateNewCreatedVariantToCached(createdVariant);
    },
  });

  const [copyVariantReq, copyVariantRes] = useMutation<{
    copyVariant: Variant;
  }>(copyVariantMutation(VARIANTS_FOR_COPY), {
    onError: noopHandler,
    onCompleted: data => {
      const createdVariant = data.copyVariant;
      updateNewCreatedVariantToCached(createdVariant);
      showNotification({
        success: true,
        message: translate('productSettings.variantSuccessfullyAdded', {
          name: createdVariant?.name,
        }),
      });
    },
  });

  const [deleteVariantsReq, deleteVariantsRes] = useMutation(
    DELETE_VARIANTS_MUTATION,
    {
      onError: noopHandler,
      onCompleted: () => {
        const existingVariants = getCachedVariants();
        const updatedVariants = existingVariants.filter(
          variant => !deletingVariantIdsRef.current.includes(variant.id),
        );
        updateCachedVariants(updatedVariants);
        showNotification({
          success: true,
          message: translate('productSettings.productDeletedSuccessfully'),
        });
      },
    },
  );

  const [convertProductToVariantReq, convertRes] = useMutation<{
    convertProductToVariant: Variant;
  }>(CONVERT_PRODUCT_TO_VARIANT_MUTATION, {
    onError: noopHandler,
  });

  const [bulkUpdateCategoryReq, bulkUpdateCategoryRes] = useMutation<{
    bulkUpdateVariantsCategory: boolean;
  }>(BULK_UPDATE_VARIANT_CATEGORY, {
    onError: noopHandler,
    onCompleted: response => {
      if (response.bulkUpdateVariantsCategory) {
        if (response.bulkUpdateVariantsCategory) {
          const existingVariants = getCachedVariants();
          const { category, entityIds } = bulkUpdateReqVariableRef.current;
          const updatedCategoryVariants = existingVariants.map(variant => {
            if (entityIds.includes(variant.id)) {
              return {
                ...variant,
                category: {
                  id: category,
                  __typename: 'Category',
                },
              };
            }
            return variant;
          }) as Variant[];
          updateCachedVariants(updatedCategoryVariants);
        }
      }
    },
  });

  const bulkUpdateVariantsCategory = useCallback(
    async (input: BulkUpdateEntityAndCategoryInput) => {
      bulkUpdateReqVariableRef.current = input;
      bulkUpdateCategoryReq({ variables: { input } });
    },
    [bulkUpdateCategoryReq],
  );

  useEffect(() => {
    if (variantId) {
      getVariantReq({ variables: { id: variantId } });
    }
  }, [getVariantReq, variantId]);

  const getVariantData = useCallback(
    id => {
      getVariantReq({ variables: { id } });
      setOperation(Operation.READ);
    },
    [getVariantReq],
  );

  // GET ALL VARIANTS
  const getAllVariants = useCallback(() => {
    getVariantsReq();
    setOperation(Operation.READ);
  }, [getVariantsReq]);

  const updateVariant = useCallback(
    async (variantInput: UpdateVariantInput) => {
      setOperation(Operation.UPDATE);
      const response = await updateVariantReq({
        variables: {
          input: variantInput,
        },
      });
      return response.data?.updateVariant;
    },
    [updateVariantReq],
  );

  const updateVariants = useCallback(
    (variantInput: UpdateVariantInput[]) => {
      updateVariantsReq({
        variables: {
          input: variantInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateVariantsReq],
  );

  // convert product to variant
  const convertProductToVariant = useCallback(
    async (input: ConvertProductToVariantInput) => {
      const response = await convertProductToVariantReq({
        variables: {
          input,
        },
      });

      return response?.data?.convertProductToVariant;
    },
    [convertProductToVariantReq],
  );

  const createVariant = useCallback(
    async (input: CreateVariantProductInput) => {
      const response = await createVariantReq({
        variables: {
          input,
        },
      });
      return response.data?.createVariant;
    },
    [createVariantReq],
  );

  const deleteVariants = useCallback(
    (varIds: string[]) => {
      deletingVariantIdsRef.current = varIds;
      deleteVariantsReq({
        variables: {
          input: varIds,
        },
      });
    },
    [deleteVariantsReq],
  );

  const copyVariant = useCallback(
    async (input: CopyVariantInput) => {
      setOperation(Operation.CREATE);
      const response = await copyVariantReq({ variables: { input } });
      return response.data?.copyVariant;
    },
    [copyVariantReq],
  );

  const deleteVariantProductInCache = useCallback(
    (productId: string, variantId: string) => {
      const existingVariants = getCachedVariants();
      const updatingVariant = existingVariants.find(
        variant => variant.id === variantId,
      );
      if (!updatingVariant) return;
      const updatedVariant = {
        ...updatingVariant,
        products: updatingVariant.products.filter(pro => pro.id !== productId),
      };
      updateCachedVariants(
        existingVariants
          .filter(variant => variant.id !== variantId)
          .concat(updatedVariant),
      );
    },
    [getCachedVariants, updateCachedVariants],
  );

  useEffect(() => {
    const singleVariant = getVariantRes.data?.variant;
    const variants = {
      ...keyBy(getVariantsRes.data?.variants || [], 'id'),
      ...(singleVariant && {
        [singleVariant.id]: singleVariant,
      }),
    };
    setVariants(variants);
  }, [getVariantsRes.data?.variants, getVariantRes.data?.variant]);

  const filteredByStores = useMemo(() => {
    if (!filteringByStoreIds) return variants;
    return keyBy(
      filterEntityByStore(Object.values(variants), filteringByStoreIds),
      'id',
    );
  }, [filteringByStoreIds, variants]);

  const RESPONSE_ENTITIES = [
    updateVariantRes,
    updateVariantsRes,
    getVariantRes,
    convertRes,
    getVariantsRes,
    createVariantRes,
    deleteVariantsRes,
    copyVariantRes,
    bulkUpdateCategoryRes,
  ];

  const error: ApolloError | undefined = getError(RESPONSE_ENTITIES);
  const loading: boolean = isLoading(RESPONSE_ENTITIES);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).getCachedVariants = getCachedVariants;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).refreshVariants = getVariantsRes.refetch;

  return useMemo(
    () => ({
      variants: filteredByStores,
      updateVariant,
      updateVariants,
      getAllVariants,
      convertProductToVariant,
      createVariant,
      deleteVariants,
      getVariantData,
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      copyVariant,
      bulkUpdateVariantsCategory,
      refreshVariants,
      updateCachedVariants,
      deleteVariantProductInCache,
    }),
    [
      filteredByStores,
      updateVariant,
      updateVariants,
      getAllVariants,
      convertProductToVariant,
      createVariant,
      deleteVariants,
      getVariantData,
      loading,
      error,
      operation,
      copyVariant,
      bulkUpdateVariantsCategory,
      refreshVariants,
      updateCachedVariants,
      deleteVariantProductInCache,
    ],
  );
}
