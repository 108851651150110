import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { DeputyStoreLocationLink, Store } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useWorkforceIntegration } from '../../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';
import { useStores } from '../../../../../../hooks/app/useStores';
import {
  clone,
  difference,
  differenceWith,
  isEqual,
  keyBy,
  omit,
} from 'lodash';
import styles from './Settings.styles';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import Message from '../../../../../../components/Office/Message/Message';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';
import TreatPicker, {
  PickerOption,
} from '../../../../../../components/Shared/Select/Picker';

export const Settings: React.FC = () => {
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { minimalStore: stores, getMinimalStores: getStores } = useStores();
  const navigation = useNavigation();
  const [storeAndLocationMaps, setStoreAndLocationMaps] = useState<
    Record<string, DeputyStoreLocationLink>
  >({});

  const {
    loading,
    locations,
    getLocations,
    deputyIntegrationData,
    linkStoresWithDeputyLocations,
    getDeputyIntegrationData,
  } = useWorkforceIntegration();

  const savedStoreAndMaps = useMemo(
    () =>
      keyBy(
        deputyIntegrationData?.preferences?.workforce?.deputy?.storeLocationMappings?.map(
          mapping => omit(mapping, '__typename'),
        ),
        'storeId',
      ),
    [
      deputyIntegrationData?.preferences?.workforce?.deputy
        ?.storeLocationMappings,
    ],
  );

  useEffect(() => {
    setStoreAndLocationMaps(savedStoreAndMaps);
  }, [savedStoreAndMaps, setStoreAndLocationMaps]);

  useEffect(() => {
    if (isFocused) {
      getLocations();
      getStores();
      getDeputyIntegrationData();
    }
  }, [isFocused, getLocations, getStores, getDeputyIntegrationData]);

  const storesData = useMemo(
    () => Object.values(stores || []) as Store[],
    [stores],
  );

  const removedStoreLinks = useMemo(() => {
    const savedStoreLinkIds = Object.keys(savedStoreAndMaps);
    const currentStoreLinkIds = Object.keys(storeAndLocationMaps);
    return difference(savedStoreLinkIds, currentStoreLinkIds);
  }, [savedStoreAndMaps, storeAndLocationMaps]);

  const updateStoreLinks = useMemo(() => {
    const savedStoreLinks = Object.values(savedStoreAndMaps);
    const currentStoreLinks = Object.values(storeAndLocationMaps);
    return differenceWith(currentStoreLinks, savedStoreLinks, isEqual);
  }, [savedStoreAndMaps, storeAndLocationMaps]);

  const onPressSave = useCallback(() => {
    linkStoresWithDeputyLocations(
      Object.values(storeAndLocationMaps).filter(item => item),
      removedStoreLinks,
      updateStoreLinks,
    );
  }, [
    linkStoresWithDeputyLocations,
    removedStoreLinks,
    storeAndLocationMaps,
    updateStoreLinks,
  ]);

  const locationMaps = useMemo(() => {
    return keyBy(locations, 'id');
  }, [locations]);

  const showSaveButton = updateStoreLinks.length || removedStoreLinks.length;

  const onChangeStore = useCallback(
    (storeId: string, locationId: string) => {
      setStoreAndLocationMaps(preMaps => {
        const updateMaps = clone(preMaps);
        const locationInfo = locationMaps[locationId];
        if (!locationInfo) {
          delete updateMaps[storeId];
        } else {
          updateMaps[storeId] = {
            companyName: locationInfo?.companyName,
            locationId: Number(locationId),
            storeId,
          };
        }
        return updateMaps;
      });
    },
    [locationMaps],
  );

  const navigateToStoreDetails = (
    name: string,
    storeId: string,
    locationId: number,
  ) => {
    navigation.navigate('DeputyStoreTab', {
      screen: 'DeputyAboutTab',
      params: {
        storeName: name,
        storeId: storeId,
        locationId: locationId,
      },
    });
  };

  const locationOptions = useMemo(() => {
    return [
      {
        value: '',
        label: translate('settingSection.deputy.selectLocation'),
      },
      ...locations.map(location => {
        return {
          value: location?.id,
          label: location?.companyName,
        };
      }),
    ] as PickerOption[];
  }, [locations, translate]);

  return (
    <ScreenLayout
      loading={loading}
      title="Deputy Settings | Oolio"
      hideFooter={!showSaveButton}
      onSave={onPressSave}
    >
      <Section title={translate('tabHeaders.deputy.connectedLocations')}>
        {!deputyIntegrationData?.isActive ? (
          <View>
            <View style={theme.tables.header}>
              <Text style={theme.tables.headerText}>
                {translate('settingSection.deputy.store')}
              </Text>
              <Text style={[theme.tables.headerText, styles.headerMapLocation]}>
                {translate('settingSection.deputy.locationToMap')}
              </Text>
            </View>
            <View>
              {Object.values(storesData)?.map(
                ({ id, name }: Store, i: number) => (
                  <View key={i} style={theme.tables.row}>
                    <Text style={styles.cellStore}>{name}</Text>
                    <TreatPicker
                      testID="select-location"
                      options={locationOptions}
                      selectedValue={
                        String(storeAndLocationMaps[id]?.locationId) || ''
                      }
                      onValueChange={onChangeStore.bind(null, id)}
                      containerStyle={styles.cellMapLocation}
                    />
                    <ButtonIcon
                      testID="btn-navigate"
                      type="neutralLight"
                      icon="cog"
                      disabled={!savedStoreAndMaps[id]?.locationId}
                      onPress={() =>
                        navigateToStoreDetails(
                          name,
                          id,
                          Number(savedStoreAndMaps[id]?.locationId),
                        )
                      }
                    />
                  </View>
                ),
              )}
            </View>
          </View>
        ) : (
          <Message
            type="negative"
            message={translate('settingSection.deputy.notActive')}
          />
        )}
      </Section>
    </ScreenLayout>
  );
};
