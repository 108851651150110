import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    height: 44,
    width: 122,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.states.positive,
    overflow: 'hidden',
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    paddingHorizontal: 16,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
    textAlign: 'center',
  },
  // eslint-disable-next-line react-native/no-color-literals
  icon: {
    width: 36,
    height: 36,
    margin: 4,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});

export default styles;
