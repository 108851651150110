import React, { FC } from 'react';
import { View, Text } from 'react-native';
import styles from './CartNote.styles';

interface CartNoteProps {
  note: string;
  testID: string;
  productNote?: boolean;
}

const CartNote: FC<CartNoteProps> = ({ note, testID, productNote }) => {
  return (
    <View style={[styles.container, productNote ? styles.product : null]}>
      <Text
        testID={testID}
        style={[styles.text, productNote ? styles.productNote : null]}
      >
        {note}
      </Text>
    </View>
  );
};

export default CartNote;
