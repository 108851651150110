/* eslint-disable react-native/no-raw-text */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { userUtility } from '../../../state/userUtility';
import styles from './PrintingError.styles';
import PrintFailedOverlay from './PrintFailedOverlay';

interface PrintFailedBannerProps {
  failedPrintJobsCount: number;
}

export const PrintFailedBanner: React.FC<PrintFailedBannerProps> = ({
  failedPrintJobsCount,
}) => {
  const navigation = useNavigation();

  const previousCountRef = useRef(failedPrintJobsCount);

  const [showOverlay, setShowOverlay] = useState<boolean>(true);

  const navigateToPrintJobs = useCallback(() => {
    if (userUtility.posUser?.id) {
      navigation.navigate('PrintJobs');
    }
  }, [navigation]);

  useEffect(() => {
    if (failedPrintJobsCount > previousCountRef.current) {
      setShowOverlay(true);
    }
    previousCountRef.current = failedPrintJobsCount;
  }, [failedPrintJobsCount]);

  const renderPrintFailedOverlay = () => {
    if (showOverlay) {
      return <PrintFailedOverlay onDismiss={() => setShowOverlay(false)} />;
    }
    return null;
  };

  return (
    <>
      {renderPrintFailedOverlay()}
      <TouchableOpacity
        style={styles.errorContainer}
        onPress={navigateToPrintJobs}
        disabled={false}
      >
        <Text style={styles.errorText}>There are</Text>
        <Text style={[styles.errorText, styles.errorCount]}>
          {failedPrintJobsCount}
        </Text>
        <Text style={styles.errorText}>
          print jobs that have failed. Press here to visit Print Queue to either
          re-print or to clear queue.
        </Text>
      </TouchableOpacity>
    </>
  );
};
