import { useCallback, useRef, useMemo } from 'react';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/client/react/hooks';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';

import {
  Category,
  CreateOrUpdateCategoryInput,
  BatchUpdateCategoriesInput,
} from '@oolio-group/domain';
import {
  CREATE_OR_UPDATE_CATEGORY,
  DELETE_CATEGORY,
  getCategoriesQuery,
  GET_CATEGORY_QUERY,
  BATCH_UPDATE_CATEGORIES,
} from './graphql';
import { useNotification } from '../../Notification';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';
import { keyBy } from 'lodash';
import { WatchQueryFetchPolicy } from '@apollo/client/core';

export interface UseCategoryProps {
  loading: boolean;
  error: string | undefined;
  getCategories: () => void;
  categoryMaps: Record<string, Category>;
  category?: Category;
  getCategory: (id: string) => void;
  createOrUpdateCategory: (
    input: CreateOrUpdateCategoryInput,
  ) => Promise<Category | undefined>;
  deleteCategory: (id: string) => Promise<boolean | undefined>;
  batchUpdateCategories: (
    categoriesToUpdate: BatchUpdateCategoriesInput[],
  ) => void;
}
interface CategoryInput {
  customFragment?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
}

export function useCategories(input?: CategoryInput): UseCategoryProps {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const isCreatingMode = useRef(false);
  const deletingCategoryRef = useRef<string>('');
  const client = useApolloClient();

  const GET_CATEGORIES_QUERY = getCategoriesQuery(input?.customFragment);

  const getCachedCategories = useCallback(() => {
    const cachedData = client.cache.readQuery<{ categories: Category[] }>({
      query: GET_CATEGORIES_QUERY,
    });
    return cachedData?.categories || [];
  }, [GET_CATEGORIES_QUERY, client.cache]);

  const updateCachedCategories = useCallback(
    (categories: Category[]) => {
      client.cache.writeQuery({
        query: GET_CATEGORIES_QUERY,
        data: {
          categories,
        },
      });
    },
    [GET_CATEGORIES_QUERY, client.cache],
  );

  const [getCategoriesRequest, getCategoriesRes] = useLazyQuery<{
    categories: Category[];
  }>(GET_CATEGORIES_QUERY, {
    onError: noopHandler,
    fetchPolicy: input?.fetchPolicy || 'cache-and-network',
    nextFetchPolicy: input?.nextFetchPolicy || 'cache-first',
  });

  const [getCategoryDetailRequest, getCategoryDetailRes] = useLazyQuery<{
    category: Category;
  }>(GET_CATEGORY_QUERY, {
    onError: noopHandler,
  });

  const [deleteCategoryRequest, deleteCategoryRes] = useMutation<{
    deleteCategory: boolean;
  }>(DELETE_CATEGORY, {
    onError: () => {
      showNotification({
        message: translate('backOfficeProducts.categoryFailedToDelete'),
        error: true,
      });
    },
    onCompleted: () => {
      showNotification({
        message: translate('backOfficeProducts.deleteCategorySuccessfully'),
        success: true,
      });
      const existingCategories = getCachedCategories();
      const updatedCategories = existingCategories.filter(
        category => category?.id !== deletingCategoryRef.current,
      );
      updateCachedCategories(updatedCategories);
      navigation.navigate('Categories');
    },
  });

  const [createOrUpdateCategoryRequest, createOrUpdateCategoryRes] =
    useMutation<{ createOrUpdateCategory: Category }>(
      CREATE_OR_UPDATE_CATEGORY,
      {
        onError: noopHandler,
        onCompleted: response => {
          if (isCreatingMode.current) {
            const existingCategories = getCachedCategories();
            const createdCategory = response.createOrUpdateCategory;
            updateCachedCategories([...existingCategories, createdCategory]);
          }

          const message = isCreatingMode.current
            ? 'backOfficeProducts.createCategorySuccessfully'
            : 'backOfficeProducts.updateCategorySuccessfully';
          showNotification({
            message: translate(message),
            success: true,
          });
        },
      },
    );

  const [batchUpdateCategoriesRequest, batchUpdateCategoriesRes] = useMutation<
    { batchUpdateCategories: Category[] },
    { input: CreateOrUpdateCategoryInput[] }
  >(BATCH_UPDATE_CATEGORIES, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        message: translate('backOfficeProducts.batchUpdateSuccessfully'),
        success: true,
      });
    },
  });

  const getCategory = useCallback(
    (categoryId: string) => {
      getCategoryDetailRequest({ variables: { categoryId } });
    },
    [getCategoryDetailRequest],
  );

  const getCategories = useCallback(() => {
    getCategoriesRequest({ variables: {} });
  }, [getCategoriesRequest]);

  const deleteCategory = useCallback(
    async (categoryId: string) => {
      deletingCategoryRef.current = categoryId;
      const response = await deleteCategoryRequest({
        variables: { id: categoryId },
      });
      return response.data?.deleteCategory;
    },
    [deleteCategoryRequest],
  );

  const createOrUpdateCategory = useCallback(
    async (input: CreateOrUpdateCategoryInput) => {
      isCreatingMode.current = !Boolean(input.id);
      const res = await createOrUpdateCategoryRequest({ variables: { input } });
      return res.data?.createOrUpdateCategory;
    },
    [createOrUpdateCategoryRequest],
  );

  const batchUpdateCategories = useCallback(
    (input: BatchUpdateCategoriesInput[]) => {
      batchUpdateCategoriesRequest({ variables: { input } });
    },
    [batchUpdateCategoriesRequest],
  );

  const categoryMaps = useMemo(
    () => keyBy(getCategoriesRes?.data?.categories || [], 'id'),
    [getCategoriesRes?.data?.categories],
  );

  const error =
    getCategoryDetailRes.error ||
    getCategoriesRes.error ||
    batchUpdateCategoriesRes.error ||
    deleteCategoryRes.error ||
    createOrUpdateCategoryRes.error;

  const loading =
    getCategoriesRes.loading ||
    batchUpdateCategoriesRes.loading ||
    getCategoryDetailRes.loading ||
    deleteCategoryRes.loading ||
    createOrUpdateCategoryRes.loading;

  return {
    loading,
    error: error ? parseApolloError(error) : undefined,
    getCategories,
    getCategory,
    deleteCategory,
    createOrUpdateCategory,
    batchUpdateCategories,
    category:
      getCategoryDetailRes.data?.category ||
      createOrUpdateCategoryRes.data?.createOrUpdateCategory,
    categoryMaps,
  };
}

export * from './graphql';
