import React from 'react';
import { AboutTab } from './About/About';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useTranslation } from '@oolio-group/localization';
import { Settings } from './Settings/Settings';
import { useWorkforceIntegration } from '../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';

const Tab = createMaterialTopTabNavigator();

const ManageDeputyTabs: React.FC = () => {
  const { translate } = useTranslation();
  const { deputyIntegrationData } = useWorkforceIntegration();

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'ManageFeatures'}
        />
      )}
      initialRouteName="AboutTab"
    >
      <Tab.Screen
        name="AboutTab"
        component={AboutTab}
        options={{ tabBarLabel: translate('tabNames.about') }}
      />
      {deputyIntegrationData?.isActive && (
        <Tab.Screen
          name="SettingsTab"
          component={Settings}
          options={{ tabBarLabel: translate('tabNames.settings') }}
        />
      )}
    </Tab.Navigator>
  );
};

export default ManageDeputyTabs;
