import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { LoyaltySettings, RewardRule } from '@oolio-group/domain';
import { getLoyaltyUnit } from '@oolio-group/client-utils';
import styles from './RewardRow.styles';
import Icon from '../../../Icon/Icon';
import theme from '../../../../common/default-theme';
import Counter from '../../../Shared/Counter/Counter';

export interface RewardRowProps {
  additionalInfo?: string;
  disabled?: boolean;
  loyaltySettings: Partial<LoyaltySettings>;
  multiSteps?: boolean;
  onChange: (reward: RewardRule, quantity?: number) => void;
  quantity: number;
  reward: RewardRule;
}

const RewardRow: React.FC<RewardRowProps> = ({
  additionalInfo,
  disabled,
  loyaltySettings,
  multiSteps,
  onChange,
  quantity,
  reward,
}) => {
  const showDisclosure = multiSteps && disabled === false;
  const term = getLoyaltyUnit(reward.pointsRequired, loyaltySettings);
  const isSelected = quantity > 0;

  const onIncreaseQty = () => {
    if (!disabled) {
      onChange(reward, quantity + 1);
    }
  };

  const onDecreaseQty = () => {
    if (quantity > 0) {
      onChange(reward, quantity - 1);
    }
  };

  return (
    <View style={[styles.container, isSelected && styles.selected]}>
      <View style={styles.text}>
        {reward.pointsRequired && (
          <Text
            style={styles.points}
          >{`${reward.pointsRequired} ${term}`}</Text>
        )}
        <Text style={styles.name}>{reward.rewardName}</Text>
        {additionalInfo && <Text style={styles.note}>{additionalInfo}</Text>}
      </View>
      {!multiSteps ? (
        <Counter
          value={quantity}
          decrease={{
            disabled: quantity <= 0,
            onPress: onDecreaseQty,
          }}
          increase={{
            disabled: !!disabled,
            onPress: onIncreaseQty,
          }}
        />
      ) : showDisclosure ? (
        <TouchableOpacity
          testID="next-step-button"
          disabled={!!disabled}
          onPress={() => onChange(reward)}
          style={styles.icon}
        >
          <Icon size={24} name="angle-right" color={theme.colors.grey5} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default RewardRow;
