import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const containerHeight = 28;
export const styles = StyleSheet.create({
  container: {
    height: containerHeight,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  text: {
    fontSize: 13,
    minWidth: 60,
    width: '100%',
    paddingHorizontal: 10,
    textTransform: 'uppercase',
    color: theme.colors.black1,
    fontFamily: theme.fonts.medium,
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    marginRight: 6,
  },
});

export const getContainerColor = (type?: string) => {
  switch (type) {
    case 'primary':
      return { backgroundColor: theme.colors.primary };
    case 'primaryLight':
      return { backgroundColor: theme.colors.primaryLight };
    case 'accent':
      return { backgroundColor: theme.colors.accent };
    case 'positive':
      return { backgroundColor: theme.colors.states.positive };
    case 'negative':
      return { backgroundColor: theme.colors.states.negative };
    case 'neutral':
      return { backgroundColor: theme.colors.states.neutral };
    case 'focus':
      return { backgroundColor: theme.colors.states.focus };
    case 'positiveLight':
      return { backgroundColor: theme.colors.states.positiveXL };
    case 'negativeLight':
      return { backgroundColor: theme.colors.states.negativeXL };
    case 'neutralLight':
      return { backgroundColor: theme.colors.states.neutralXL };
    case 'focusLight':
      return { backgroundColor: theme.colors.states.focusXL };
    case 'teal':
      return { backgroundColor: theme.colors.tealLightest };
    default:
      return { backgroundColor: theme.colors.grey1 };
  }
};

export const getBorderRadius = (rounded?: boolean) => {
  return {
    borderRadius: rounded ? containerHeight / 2 : theme.radius.s,
  };
};

export default styles;
