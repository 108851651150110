import { useCallback, useEffect, useState } from 'react';
import {
  OrderStatus,
  OrderTypeCode,
  IntegrationApps,
  TableStatus,
  Order,
} from '@oolio-group/domain';

import { useOrders } from '../orders/useOrders';

export interface useTablesData {
  tableStatusMap: Record<string, TableStatus>;
  tableOrdersMap: Record<string, Order[]>;
  getTablesData: () => void;
}

export interface Props {
  sectionId?: string;
  tableId?: string;
}

export const useTablesData = (): useTablesData => {
  const [tableStatusMap, setTableStatusMap] = useState<
    Record<string, TableStatus>
  >({} as Record<string, TableStatus>);
  const [tableOrdersMap, setTableOrdersMap] = useState<Record<string, Order[]>>(
    {} as Record<string, Order[]>,
  );
  const { returnOrdersFromCache } = useOrders();

  const getTablesData = useCallback(() => {
    const inProgressOrders = returnOrdersFromCache(OrderStatus.IN_PROGRESS);
    const onlineInprogressOrders = returnOrdersFromCache(
      OrderStatus.IN_PROGRESS,
      true,
    );
    // Orders from online store v2 are marked as oom orders
    const filteredDineInOnlineOrders = onlineInprogressOrders.filter(
      x =>
        x?.orderType?.code === OrderTypeCode.DINE_IN &&
        (x?.integrationInfo?.app === IntegrationApps.OOLIO_STORE ||
          x?.integrationInfo?.app === IntegrationApps.OOM),
    );
    const holdOrders = returnOrdersFromCache(OrderStatus.ON_HOLD);
    const createdOrders = returnOrdersFromCache(OrderStatus.CREATED);
    const mapToStatus: Record<string, TableStatus> = {};
    const mapToOrder: Record<string, Order[]> = {};

    [
      ...inProgressOrders,
      ...holdOrders,
      ...createdOrders,
      ...filteredDineInOnlineOrders,
    ].forEach(order => {
      if (order.orderType?.code === OrderTypeCode.DINE_IN && order?.table?.id) {
        mapToOrder[order.table.id] = !!mapToOrder[order.table.id]
          ? [...mapToOrder[order.table.id], order].sort(
              (a, b) => a.createdAt - b.createdAt,
            )
          : [order];
        mapToStatus[order.table.id] = mapToStatus[order.table.id]
          ? TableStatus.OCCUPIED
          : order.table.status;
      }
    });
    setTableStatusMap(mapToStatus);
    setTableOrdersMap(mapToOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnOrdersFromCache]);

  useEffect(() => {
    getTablesData();
  }, [getTablesData]);

  return {
    tableStatusMap,
    tableOrdersMap,
    getTablesData,
  };
};
