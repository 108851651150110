import { gql } from '@apollo/client';

const categoryFragment = `
id
name
createdAt
products {
  id
  name
}
variants {
  id
  name
}
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
`;

export const productsFragment = `
id
name
category {
  id
  name
}
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
`;

export const variantsFragment = `
id
name
products {
  id
}
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
category {
  id
  name
}
`;

export const getCategoriesQuery = (fragment?: string) => {
  return gql`
  query getCategories {
    categories {
      ${fragment || categoryFragment}
    }
  }
`;
};

export const CREATE_OR_UPDATE_CATEGORY = gql`
  mutation CreateOrUpdateCategoryInput($input: CreateOrUpdateCategoryInput) {
    createOrUpdateCategory(input: $input) {
      ${categoryFragment}
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`;

export const BATCH_UPDATE_CATEGORIES = gql`
  mutation updateCategories($input: [BatchUpdateCategoriesInput]) {
    batchUpdateCategories(input: $input) {
      ${categoryFragment}
    }
  }
`;

export const GET_CATEGORY_QUERY = gql`
  query Category($categoryId: ID!) {
    category(id: $categoryId) {
      ${categoryFragment}
    }
  }
`;

// CUSTOM CATEGORY FRAGMENT

export const CATEGORIES_AS_OPTIONS = `
id
name
alternateNames {
  locale
  display
  kitchen
  receipt
  description
}
`;

export const CATEGORIES_AS_NAME = `
  id
  name
  products {
    id
  }
  variants {
    id
  }
`;
