import { PersistentStorage } from 'apollo3-cache-persist';
import { RxDatabase } from 'rxdb';

/**
 * This defines a wrapper to rxdb instance to return compatible format for apollo cache persist
 */
export class ApolloStorageWrapper implements PersistentStorage {
  // storage instance
  private storage;

  constructor(storage: RxDatabase) {
    this.storage = storage?.collections?.apollo_cache;
  }

  /**
   * Function to fetch cache data from database
   * @param {String} key apollo cache persist key
   * @returns {Promise<String>} Cached data
   */
  async getItem(key: string): Promise<string | null> {
    return (await this.storage.findOne({ selector: { key } }).exec())?.toJSON()
      ?.value;
  }

  /**
   * Function to remove cache data from database
   * @param {String} key apollo cache persist key
   */
  async removeItem(key: string): Promise<void> {
    await this.storage.findOne({ selector: { key } }).remove();
  }

  /**
   * Function to update cache data in database
   * @param {String} key apollo cache persist key
   * @param {String} value cache data
   */
  async setItem(key: string, value: string): Promise<void> {
    await this.storage.upsert({
      key,
      value,
    });
  }
}
