import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import debounce from 'lodash/debounce';
import { Product } from '@oolio-group/domain';
import { ProductsFiltersProps } from './Products.type';
import { useTranslation } from '@oolio-group/localization';
import { useProducts } from '../../../../../../hooks/app/products/useProducts';
import { useCategories } from '../../../../../../hooks/app/categories/useCategories';
import SelectSearch, {
  OptionsType,
} from '../../../../../../components/Shared/Select/SelectSearch';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import ButtonActions from '../../../../../../components/Shared/TreatButton/ButtonActions';
import styles from '../../PriceLists.styles';

export const ProductsFilters: React.FC<ProductsFiltersProps> = ({
  variantsArray,
  defaultPricing,
  addedProducts,
  categoryOptions,
  selectedCategory,
  onPressAdd,
  onPressAddAll,
  setPrices,
  modifyPrices,
  resetPrices,
  onChangeCategory,
}) => {
  const { translate } = useTranslation();
  const { products, getProductsByName } = useProducts();
  const { categoryMaps, getCategories } = useCategories({
    fetchPolicy: 'cache-and-network',
  });

  const [loadingOptions, setLoadingOptions] = useState(false);
  const [productData, setProductData] = useState<Record<string, Product>>({});

  useEffect(() => {
    if (products) {
      setProductData(products);
    }
  }, [products]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const debouncedSearch = useMemo(
    () => debounce(getProductsByName, 500),
    [getProductsByName],
  );

  const onSearchTextChange = (value: string) => {
    debouncedSearch(value);
  };

  const combinedOptions = useMemo(() => {
    setLoadingOptions(true);
    const productOptions = Object.values(productData).map(
      (product: Product) => ({
        title: product.name,
        value: product.id,
        subtitle:
          product.category?.name || translate('backOfficeProducts.product'),
        type: 'product',
      }),
    );

    const categoryOptions = Object.values(categoryMaps).map(category => ({
      title: category.name,
      value: category.id,
      subtitle: translate('backOfficeProducts.category'),
      type: 'category',
    }));

    setLoadingOptions(false);
    return [...productOptions, ...categoryOptions];
  }, [categoryMaps, productData, translate]);

  const handleOptionSelect = (option: OptionsType) => {
    if (option.type === 'category') {
      const categoryProductIds =
        categoryMaps[option.value]?.products.map(product => product.id) || [];

      const categoryVariants = variantsArray.filter(
        variant => variant?.category?.id === option.value,
      );

      categoryVariants.forEach(categoryVariant => {
        if (categoryVariant?.products) {
          categoryProductIds.push(
            ...categoryVariant.products.map(product => product.id),
          );
        }
      });

      const categoryProductPricings = Object.values(defaultPricing).filter(
        pricing => categoryProductIds.includes(pricing.productId),
      );
      onPressAddAll(categoryProductPricings);
    } else {
      onPressAdd(defaultPricing[option.value]);
    }
    debouncedSearch.cancel();
  };

  const bulkOptions = [
    {
      label: translate('pricings.addAllProducts'),
      action: () => onPressAddAll(Object.values(defaultPricing)),
    },
    { label: translate('productSettings.setPrice'), action: setPrices },
    { label: translate('productSettings.modifyPrice'), action: modifyPrices },
    { label: translate('productSettings.resetPrice'), action: resetPrices },
  ];

  return (
    <View style={styles.filters}>
      <TreatPicker
        testID="select-category"
        options={categoryOptions}
        selectedValue={selectedCategory}
        onValueChange={onChangeCategory}
        containerStyle={styles.dropdown}
      />
      <SelectSearch
        testID="search-products"
        loading={loadingOptions}
        placeholder={translate('pricings.searchCategoriesOrProducts')}
        options={combinedOptions}
        selectedOptions={addedProducts}
        onSearchChange={onSearchTextChange}
        onChangeOption={(_s, i) => handleOptionSelect(i)}
        containerStyle={styles.search}
      />
      <ButtonActions
        type="neutral"
        label={translate('pricings.bulkOptions')}
        actions={bulkOptions}
        containerStyle={styles.btnOptions}
      />
    </View>
  );
};
