import React, { FC, useCallback, useMemo } from 'react';
import { View, Text, ViewStyle, TouchableOpacity } from 'react-native';
import {
  AdjustmentType,
  Order,
  OrderPaymentStatus,
  OrderStatus,
  OrderPayment,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import {
  limitDecimalCount,
  getAdjustmentValue,
  getPaymentSurchargeValueAndPaidAmount,
  getNetSubTotal,
  sumDecimals,
  isCompletedOrRefundedPayment,
  getAdjustmentLabel,
} from '@oolio-group/order-helper';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './CartTotals.styles';
import theme from '../../../../common/default-theme';
import OrderPaymentsModal from '../../Modals/OrderPayments/OrderPayments';
import Icon from '../../../../components/Icon/Icon';
import RefundRequestModal from '../../Modals/Refunds/RefundRequestModal/RefundRequestModal';

interface CartTotalsProps {
  order: Order;
  minimised?: boolean;
  isPaymentScreen?: boolean;
  containerStyle?: ViewStyle | ViewStyle[];
}

interface CartTotalRowProps {
  name: string;
  value: number;
  testID: string;
  major?: boolean;
  payment?: boolean;
  isNegative?: boolean;
  marginTop?: number;
  isFailedPayment?: boolean;
  onPress?: () => void;
}

export const CartTotalRow: FC<CartTotalRowProps> = ({
  name,
  value,
  testID,
  major = false,
  payment = false,
  isNegative = false,
  isFailedPayment = false,
  marginTop = 8,
  onPress,
}) => {
  const { formatCurrency } = useCurrency();
  const { textMinor, textMajor, textPayment } = styles;

  const textStyle = payment ? textPayment : major ? textMajor : textMinor;

  return (
    <View testID={testID} style={[styles.row, { marginTop }]}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <View style={{ flexDirection: 'row' }}>
        <Text
          numberOfLines={1}
          style={[
            textStyle,
            isFailedPayment && { color: theme.colors.states.negative },
          ]}
        >
          {name}
        </Text>
        {isFailedPayment && (
          <TouchableOpacity style={styles.btnFailedPayment} onPress={onPress}>
            <Icon
              name="question-circle"
              size={14}
              color={theme.colors.states.neutral}
            />
          </TouchableOpacity>
        )}
      </View>
      <Text
        numberOfLines={1}
        style={[
          textStyle,
          isNegative && { color: theme.colors.states.negative },
        ]}
      >
        {`${isNegative ? '-' : ''}${formatCurrency(value)}`}
      </Text>
    </View>
  );
};

const CartTotals: FC<CartTotalsProps> = ({
  order,
  minimised = false,
  isPaymentScreen = false,
  containerStyle,
}) => {
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const isCompletedOrder = order?.status === OrderStatus.COMPLETED;
  const isRefundedOrder = order?.status === OrderStatus.REFUNDED;
  const hasPayments = order?.payments.length > 1;

  const discounts = order?.adjustments?.filter(
    adj => adj.adjustmentType === AdjustmentType.DISCOUNT,
  );

  const surcharges = order?.adjustments?.filter(
    adj =>
      adj.adjustmentType === AdjustmentType.SURCHARGE &&
      !adj.allowOnPaymentType,
  );

  const { paymentSurcharge } = getPaymentSurchargeValueAndPaidAmount(
    order?.payments ?? [],
  );

  const paymentSurcharges = order?.adjustments?.filter(
    surcharge => surcharge.allowOnPaymentType,
  );

  // FIXME: Need a better way to show refunded payment type
  const paymentsToShow = order?.payments?.filter(payment =>
    isCompletedOrRefundedPayment(payment.status as OrderPaymentStatus),
  );

  const totalPaid = sumDecimals(paymentsToShow.map(payment => payment.amount));

  const totalToShow =
    isCompletedOrder && !isPaymentScreen
      ? totalPaid
      : order?.totalPaymentAmount;

  const getOrderTotalLabel = () => {
    if (isPaymentScreen) {
      return translate('payment.orderTotal');
    } else if (isCompletedOrder) {
      return translate('refundOrder.totalPaid');
    } else if (isRefundedOrder) {
      return translate('refundOrder.totalRefunded');
    } else if (order?.status === OrderStatus.ON_HOLD && !hasPayments) {
      return translate('payment.totalDue');
    } else if (order?.status !== OrderStatus.ON_HOLD && !isCompletedOrder) {
      return translate('payment.totalDue');
    }
    return translate('payment.orderTotal');
  };

  const subTotalAfterAdjustment = useMemo(
    () => getNetSubTotal(order?.subTotal, order?.adjustments),
    [order?.subTotal, order?.adjustments],
  );

  const onPressFailedPayment = useCallback(
    (payment: OrderPayment) => {
      const title = translate('refundOrder.refundFailedModalHeading');
      const description = payment.failedReason || '';
      showModal(
        <RefundRequestModal
          title={title}
          description={description}
          iconName="info-circle"
          type="cancel"
        />,
      );
    },
    [showModal, translate],
  );

  const onPressShowPayments = () => {
    const payments = order?.payments?.filter(payment =>
      isCompletedOrRefundedPayment(payment.status as OrderPaymentStatus),
    );

    showModal(
      <OrderPaymentsModal
        orderNo={order?.orderNumber}
        subtotal={order?.subTotal || 0}
        rewardsDiscount={order?.rewardDiscountAmount}
        discounts={discounts || []}
        surcharges={surcharges || []}
        paymentSurcharge={paymentSurcharge || 0}
        tip={order?.tip}
        deliveryFee={order?.deliveryFee}
        serviceCharge={order?.serviceCharge}
        taxes={order?.taxes || []}
        total={totalToShow}
        payments={payments}
        amountDue={order?.amountDue}
        onPress={onPressFailedPayment}
      />,
    );
  };

  if (!order) {
    return <></>;
  }

  if (minimised) {
    return (
      <TouchableOpacity
        onPress={onPressShowPayments}
        style={[styles.container, containerStyle]}
      >
        <CartTotalRow
          major
          testID="cart-totalToShow"
          marginTop={8}
          name={`${translate('payment.totalDue')} ↗`}
          value={order?.amountDue || 0}
          isNegative={isRefundedOrder}
        />
      </TouchableOpacity>
    );
  }

  return (
    <View style={[styles.container, containerStyle]}>
      {/* Sub Total */}
      <CartTotalRow
        testID="cart-subtotal"
        name={translate('payment.subTotal')}
        value={order?.subTotal}
      />
      {/* Rewards */}
      {order?.rewardDiscountAmount ? (
        <CartTotalRow
          testID="reward-amount"
          name={translate('customerLoyalty.rewardSummary')}
          value={order?.rewardDiscountAmount}
        />
      ) : null}
      {/* Discounts */}
      {discounts?.map((discount, i) => (
        <CartTotalRow
          key={`discount-${i}`}
          testID="discount"
          name={getAdjustmentLabel(discount)}
          value={limitDecimalCount(
            getAdjustmentValue(
              discount.doNotIncludeInSalesAmount
                ? subTotalAfterAdjustment
                : order.subTotal,
              [discount],
            ),
          )}
        />
      ))}
      {/* Surcharges */}
      {surcharges?.map((surcharge, i) => (
        <CartTotalRow
          key={`surcharge-${i}`}
          testID="surcharge"
          name={getAdjustmentLabel(surcharge)}
          value={limitDecimalCount(
            getAdjustmentValue(
              surcharge.doNotIncludeInSalesAmount
                ? subTotalAfterAdjustment
                : order.subTotal,
              [surcharge],
            ),
          )}
        />
      ))}
      {/* Payment Surcharge */}
      {paymentSurcharges?.length && paymentSurcharge ? (
        <CartTotalRow
          testID="cart-totalPaymentSurcharge"
          name={translate('shift.totalPaymentSurcharge')}
          value={paymentSurcharge}
        />
      ) : null}
      {/* Tips */}
      {order?.tip ? (
        <CartTotalRow
          testID="cart-tips"
          name={translate('onlineOrders.tip')}
          value={order?.tip}
        />
      ) : null}
      {/* Delivery Fees */}
      {order?.deliveryFee ? (
        <CartTotalRow
          testID="cart-deliveryFee"
          name={translate('onlineOrders.deliveryFee')}
          value={order?.deliveryFee}
        />
      ) : null}
      {/* Service Charges */}
      {order?.serviceCharge ? (
        <CartTotalRow
          testID="cart-serviceCharge"
          name={translate('onlineOrders.serviceCharge')}
          value={order?.serviceCharge}
        />
      ) : null}
      {/* Cash Rounding */}
      {!isPaymentScreen && order?.roundingAmount ? (
        <CartTotalRow
          testID="cart-rounding"
          name={translate('backOfficeSalesSummary.CashRounding')}
          value={order?.roundingAmount}
        />
      ) : null}
      {/* Taxes */}
      {order?.taxes?.map(({ tax, amount }, i) => (
        <CartTotalRow
          key={`tax-${i}`}
          testID={`cart-tax-${i}`}
          name={tax.name}
          value={amount}
        />
      ))}
      {/* Payments */}
      <CartTotalRow
        major
        testID="cart-totalToShow"
        marginTop={12}
        name={getOrderTotalLabel()}
        value={totalToShow}
        isNegative={isRefundedOrder}
      />
      {paymentsToShow?.length > 0 ? (
        paymentsToShow.length > 1 ? (
          <TouchableOpacity
            testID="cart-payments"
            onPress={onPressShowPayments}
            style={[styles.groupPaid, styles.row]}
          >
            <Text style={styles.textPayment}>{`${
              paymentsToShow.length
            } ${translate('backOfficeReports.Payments')} ↗`}</Text>
            <Text style={styles.textPayment}>{formatCurrency(totalPaid)}</Text>
          </TouchableOpacity>
        ) : (
          <View style={styles.groupPaid}>
            {paymentsToShow.map((payment, i) => {
              const isRefundPaymentFailed =
                payment.status === OrderPaymentStatus.REFUND_FAILED;
              return (
                <CartTotalRow
                  payment
                  key={`payment-${i}`}
                  testID={`cart-payment-${i}`}
                  value={payment.amount}
                  name={
                    (isRefundPaymentFailed
                      ? translate('refundOrder.failedPaymentType', {
                          paymentType: payment.paymentType?.name,
                        })
                      : payment.paymentType?.name) ?? order?.salesChannel?.name
                  }
                  marginTop={i === 0 ? 0 : 8}
                  isNegative={isRefundedOrder}
                  isFailedPayment={isRefundPaymentFailed}
                  onPress={() => onPressFailedPayment(payment)}
                />
              );
            })}
          </View>
        )
      ) : null}
    </View>
  );
};

export default CartTotals;
