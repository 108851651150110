import React, {
  useContext,
  createContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Platform } from 'react-native';
import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
import { netWorkStatus } from './useNetworkStatusVar';
import { useTimeout } from '../useTimeout';

interface NetworkStatusContextType {
  isConnected: boolean;
  isLoading: boolean;
}

interface NetworkProviderProps {
  children: React.ReactNode;
}

NetInfo.configure({
  useNativeReachability: Platform.OS !== 'web',
  reachabilityUrl: 'https://ok.oolio.dev',
  reachabilityTest: async response => response.status === 200,
  reachabilityLongTimeout: 10 * 1000, // 10s
  reachabilityShortTimeout: 10 * 1000, // 10s
  reachabilityRequestTimeout: 5 * 1000, // 5s
  reachabilityMethod: 'GET',
});

const NetworkContext = createContext<NetworkStatusContextType | null>(null);

export const NetworkStatusProvider: React.FC<NetworkProviderProps> = ({
  children,
}: NetworkProviderProps) => {
  const { isConnected: isWiFiConnected, isInternetReachable } = useNetInfo();
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const setOffline = useCallback(() => {
    setIsConnected(false);
    setIsLoading(false);
  }, []);

  const setOnlineWithDelay = useTimeout(() => setIsConnected(true));

  const setOnline = useCallback(() => {
    setOnlineWithDelay.start(2000);
    setIsLoading(false);
  }, [setOnlineWithDelay]);

  useEffect(() => {
    netWorkStatus(isConnected);
  }, [isConnected]);

  useEffect(() => {
    if (isWiFiConnected === false) {
      setOffline();
      return;
    }
  }, [setOffline, setOnline, isWiFiConnected]);

  useEffect(() => {
    if (isInternetReachable) {
      setOnline();
      return;
    } else if (isInternetReachable === false) {
      setOffline();
      return;
    }
  }, [isInternetReachable, setOnline, setOffline]);

  return (
    <NetworkContext.Provider
      value={{
        isConnected,
        isLoading,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetworkStatus = () => {
  const context = useContext(NetworkContext);
  if (!context) {
    throw Error('useNetworkStatus must be used inside of NetworkProvider');
  }

  return context;
};
