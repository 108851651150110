import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import {
  ModifierGroup,
  CreateModifierGroupInput,
  DEFAULT_ENTITY_ID,
  UpdateModifierGroupInput,
  Currency,
  ComboItem,
  Product,
  Modifier as ModifierDefault,
  Money,
  ProductPricingInput,
  CreateModifierInput,
  ModifierPricingInput,
  ModifierType,
} from '@oolio-group/domain';
import { useNotification } from '../../../../../../hooks/Notification';
import { useModifierGroups } from '../../../../../../hooks/app/modifierGroups/useModifierGroups';
import { useModifiers } from '../../../../../../hooks/app/modifiers/useModifiers';
import { useTaxes } from '../../../../../../hooks/app/useTaxes';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { Operation } from '../../../../../../types/Operation';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModifierRow } from './ModifierRow';
import { findIndex } from 'lodash';
import { useModal } from '@oolio-group/rn-use-modal';
import { usePricingGroups } from '../../../../../../hooks/app/usePricingGroups';
import { useProductPricings } from '../../../../../../hooks/app/productPricings/useProductPricings';
import { useModifierPricings } from '../../../../../../hooks/app/modifierPricings/useModifierPricings';
import { getBestPriceOfModifier } from '@oolio-group/catalog-helper';
import { useProducts } from '../../../../../../hooks/app/products/useProducts';
import { PRODUCTS_IN_OPTIONS_GROUP_SCREEN_FRAGMENT } from '../../../../../../hooks/app/products/graphql';
import { CreateModifierModal } from '../../Modifiers/CreateModifier';
import theme from '../../../../../../common/default-theme';
import styles from '../../Options.styles';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import DraggableFlatList from '../../../../../../components/DraggableFlatList/DraggableFlatList';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import { Option } from '../../../../../../components/Shared/Select/Select';

interface CreateModifierInputAlias extends CreateModifierInput {
  tax?: string;
  price?: Money;
}

interface Modifier extends ModifierDefault {
  isSelected?: boolean;
  tax?: string;
}

const createProductAsModifier = (
  product: Product,
  currency: string,
  index: number,
): ComboItem => {
  return {
    id: product.id,
    name: product.name,
    price: {
      amount: 0,
      currency: currency,
    },
    pricingGroups: [...product.pricingGroups],
    type: ModifierType.PRODUCT,
    isDefault: false,
    priority: index,
  } as ComboItem;
};

export const OptionGroup: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const { currency } = useCurrency();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();
  const [createModifiersData, setCreateModifiersData] = useState(
    {} as CreateModifierInputAlias,
  );
  const [modifierToReplaceAfterAdded, setModifierToReplaceAfterAdded] =
    useState('');
  const [modifierGroup, setModifierGroup] = useState({
    selectionLimit: {
      max: 1,
      min: 1,
    },
    modifiers: [] as Modifier[],
    name: '',
    maxSelectionPerOption: 1,
  } as ModifierGroup);

  const modifierGroupIdParam = (
    route.params as {
      modifierGroupId: string;
    }
  ).modifierGroupId;

  const modifierGroupId =
    (modifierGroupIdParam != DEFAULT_ENTITY_ID && modifierGroupIdParam) || '';

  const {
    error: modGroupError,
    loading: modGroupLoading,
    modifierGroups,
    operation,
    deleteModifierGroups,
    createModifierGroups,
    updateModifierGroups,
    getModifierGroupById,
    getAllModifierGroups,
    createdIds: modifierGroupCreatedIds,
  } = useModifierGroups(modifierGroupId);

  const {
    modifiers,
    loading: modLoading,
    getAllModifiers,
    error: modError,
    createModifiers,
    operation: modOperation,
    createdIds,
  } = useModifiers();

  const { taxesOptions, loading: taxesLoading, error: taxesError } = useTaxes();

  const {
    defaultPricingGroup,
    error: PGErr,
    loading: PGLoading,
    getAllPricingGroups,
  } = usePricingGroups();

  const {
    error: errorPP,
    loading: loadingPP,
    operation: PPOperation,
    addProductPricing,
  } = useProductPricings();

  const {
    addModifierPricing,
    error: MPError,
    loading: MPLoading,
    operation: MPOperation,
  } = useModifierPricings();

  const {
    products,
    error: prodErr,
    getAllProducts,
    loading: prodLoading,
  } = useProducts('', PRODUCTS_IN_OPTIONS_GROUP_SCREEN_FRAGMENT);

  const isModifierCreated =
    !modError && !modLoading && modOperation === Operation.CREATE;

  const isProductPricingCreated =
    !errorPP && !loadingPP && PPOperation === Operation.CREATE;

  const isModifierPriceCreated =
    !MPError && !MPLoading && MPOperation === Operation.CREATE;

  const loading =
    modGroupLoading || modLoading || taxesLoading || PGLoading || prodLoading;

  const error = modGroupError || modError || taxesError || PGErr || prodErr;

  useEffect(() => {
    getAllModifiers();
    getAllModifierGroups();
    getAllPricingGroups();
    getAllProducts();
  }, [
    getAllModifiers,
    getAllModifierGroups,
    getAllPricingGroups,
    getAllProducts,
  ]);

  const productsData = useMemo(() => {
    return Object.values(products).filter(product => !product.isCombo);
  }, [products]);

  useEffect(() => {
    if (modifierGroupId && modifierGroups[modifierGroupId]) {
      // update the tab title
      navigation.setOptions({
        tabBarLabel: modifierGroups[modifierGroupId].name,
      });
    }
  }, [navigation, modifierGroups, modifierGroupId, modifierGroupCreatedIds]);

  useEffect(() => {
    if (
      modifierGroupCreatedIds.length &&
      modifierGroups[modifierGroupCreatedIds[0]]
    ) {
      // update the tab title
      navigation.setOptions({
        tabBarLabel: modifierGroups[modifierGroupCreatedIds[0]].name,
      });
    }
  }, [navigation, modifierGroups, modifierGroupId, modifierGroupCreatedIds]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (
      !loading &&
      modifierGroupCreatedIds.length &&
      modifierGroups[modifierGroupCreatedIds[0]]
    ) {
      setModifierGroup(modifierGroups[modifierGroupCreatedIds[0]]);
    }
  }, [modifierGroupId, modifierGroups, loading, modifierGroupCreatedIds]);

  useEffect(() => {
    if (!loading && modifierGroupId && modifierGroups[modifierGroupId]) {
      const selectingModifierGroup = modifierGroups[modifierGroupId];
      const modifiers = [...selectingModifierGroup.modifiers];
      setModifierGroup({
        ...selectingModifierGroup,
        maxSelectionPerOption:
          selectingModifierGroup?.maxSelectionPerOption || 1,
        modifiers,
      });
    }
  }, [modifierGroupId, modifierGroups, loading]);

  const modifiersMap = useMemo(() => modifiers, [modifiers]);
  const productsMap = useMemo(() => products, [products]);

  const modifierArrayValues = useMemo(
    () => Object.values(modifiers),
    [modifiers],
  );

  const isOnlyProductAsOption = useMemo(() => {
    if (
      modifierGroup.products &&
      modifierGroup.modifiers.length < modifierGroup.products?.length &&
      modifierGroup.products.some(pro => pro.id !== '')
    ) {
      return true;
    }

    return false;
  }, [modifierGroup]);

  const isOnlyModifierAsOption = useMemo(() => {
    if (
      !modifierGroup.products &&
      modifierGroup.modifiers.some(mod => mod.id !== '')
    ) {
      return true;
    }
    if (
      modifierGroup.products &&
      modifierGroup.products.length < modifierGroup.modifiers.length &&
      modifierGroup.modifiers.some(mod => mod.id !== '')
    )
      return true;
    return false;
  }, [modifierGroup]);

  const modifierOptions = useMemo(() => {
    let optionsToShow: Option[] = [];

    modifierArrayValues.forEach(modifier => {
      optionsToShow.push({
        value: modifier.id,
        label: modifier.name,
      });
    });

    productsData.forEach(prod =>
      optionsToShow.push({ value: prod.id, label: prod.name }),
    );

    if (isOnlyModifierAsOption) {
      const productsDataSet = new Set(productsData.map(prod => prod.id));
      optionsToShow = optionsToShow.filter(
        ({ value }) => !productsDataSet.has(value),
      );
    }

    if (isOnlyProductAsOption) {
      const modifierSet = new Set(modifierArrayValues.map(mod => mod.id));
      optionsToShow = optionsToShow.filter(
        ({ value }) => !modifierSet.has(value),
      );
    }
    return optionsToShow;
  }, [
    modifierArrayValues,
    productsData,
    isOnlyModifierAsOption,
    isOnlyProductAsOption,
  ]);

  const onChange = useCallback((prop, value) => {
    if (prop === 'min' || prop === 'max') {
      setModifierGroup(prev => {
        const parsedValue = parseInt(value);
        if (
          prop === 'max' &&
          prev?.maxSelectionPerOption &&
          prev?.maxSelectionPerOption > parsedValue
        ) {
          prev.maxSelectionPerOption = parsedValue;
        }
        return {
          ...prev,
          selectionLimit: {
            ...prev.selectionLimit,
            [prop]: parseInt(value),
          },
        };
      });
    } else {
      setModifierGroup(prev => ({ ...prev, [prop]: value }));
    }
  }, []);

  useEffect(() => {
    if (
      !error &&
      !loading &&
      operation === Operation.CREATE &&
      modifierGroupCreatedIds.length
    ) {
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupCreatedSuccessfully'),
      });
      if (modifierGroupCreatedIds.length) {
        getModifierGroupById(modifierGroupCreatedIds[0]);
        navigation.navigate('OptionGroup', {
          modifierGroupId: modifierGroupCreatedIds[0],
        });
      }
    }
  }, [
    loading,
    operation,
    showNotification,
    error,
    translate,
    getModifierGroupById,
    modifierGroupCreatedIds,
    navigation,
  ]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.UPDATE) {
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupUpdatedSuccessfully'),
      });
      getAllModifiers();
      getModifierGroupById(modifierGroup.id);
    }
  }, [
    loading,
    operation,
    showNotification,
    error,
    translate,
    modifierGroup,
    getModifierGroupById,
    getAllModifiers,
  ]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.DELETE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupsDeletedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  const updateModifierGroupsData = useCallback(
    (createdModifier?: {
      id: string;
      price: Money;
      type: ModifierType;
      name: string;
    }) => {
      const updateModifierGroupInput = {
        id: modifierGroup.id,
        name: modifierGroup.name.trim(),
        kitchenPrintLabel: modifierGroup.kitchenPrintLabel,
        maxSelectionPerOption: +modifierGroup?.maxSelectionPerOption,
        selectionLimit: {
          max: modifierGroup.selectionLimit.max,
          min: modifierGroup.selectionLimit.min,
        },
        modifiers: (
          (modifierGroup?.modifiers || []).filter(x => x && x.id) as Modifier[]
        ).map((x, index) => ({
          id: x.id,
          price: {
            amount:
              x?.price?.amount === undefined
                ? getBestPriceOfModifier({ ...modifiersMap[x.id] })
                : +x.price?.amount,
            currency: currency as Currency,
          },
          name: modifiersMap[x.id]?.name || x.name,
          type: modifiersMap[x.id]?.type || x.type,
          priority: index,
        })),
        products: (modifierGroup?.products || [])
          .filter(x => x && x.id)
          .map(
            (x, index) =>
              ({
                id: x.id,
                price: {
                  amount: x?.price?.amount === undefined ? 0 : +x.price?.amount,
                  currency: currency as Currency,
                },
                name: x.name || productsMap[x.id]?.name,
                type: x.type || ModifierType.PRODUCT,
                priority: index,
                isDefault: false,
              } as ComboItem),
          ),
      } as UpdateModifierGroupInput;
      if (createdModifier?.id && updateModifierGroupInput.modifiers) {
        const modifiersCopy = [...updateModifierGroupInput.modifiers];
        !updateModifierGroupInput?.modifiers?.find(
          x => x.id === createdModifier.id,
        ) &&
          modifiersCopy.push({
            id: createdModifier.id,
            price: createdModifier.price,
            name: createdModifier.name,
            type: createdModifier.type,
            priority: modifierGroup.modifiers.length,
          });
        updateModifierGroupInput.modifiers = modifiersCopy;
      }
      updateModifierGroups([updateModifierGroupInput]);
    },
    [currency, modifierGroup, modifiersMap, updateModifierGroups, productsMap],
  );

  const onSave = useCallback(() => {
    const minVal = modifierGroup?.selectionLimit?.min;
    const maxVal = modifierGroup?.selectionLimit?.max;
    if (!modifierGroup?.name?.trim().length) {
      // no name
      showNotification({
        error: true,
        message: translate('modifiers.modifierNameIsRequired'),
      });
    } else if (isNaN(minVal) || isNaN(maxVal)) {
      // max value and min value should be numbers
      showNotification({
        error: true,
        message: translate('modifiers.integerError'),
      });
    } else if (maxVal < minVal) {
      // max value should always be more than min value
      showNotification({
        error: true,
        message: translate('modifiers.maxValueError'),
      });
    } else if (!modifierGroup?.id && modifierGroup?.name) {
      // create
      createModifierGroups([
        {
          name: modifierGroup.name.trim(),
          kitchenPrintLabel: modifierGroup.kitchenPrintLabel,
          selectionLimit: modifierGroup.selectionLimit,
          modifiers: modifierGroup.modifiers.map((x, index) => ({
            id: x.id,
            price: { amount: x.price?.amount || 0, currency: currency },
            name: x.name || modifiersMap[x.id]?.name,
            type: x.type || modifiersMap[x.id]?.type,
            priority: index,
          })),
          maxSelectionPerOption: +modifierGroup?.maxSelectionPerOption,
        },
      ] as CreateModifierGroupInput[]);
    } else {
      // update
      updateModifierGroupsData();
    }
  }, [
    modifierGroup,
    createModifierGroups,
    showNotification,
    translate,
    currency,
    modifiersMap,
    updateModifierGroupsData,
  ]);

  const selections = useMemo(() => {
    const selectionTemp = modifierGroup?.selectionLimit;
    return {
      max: selectionTemp?.max || 0,
      min: selectionTemp?.min || 0,
    };
  }, [modifierGroup?.selectionLimit]);

  const onChangeModifier = useCallback(
    (currentModifierId, newModifierId, rowIndex) => {
      setModifierGroup(prev => {
        const prevTemp = { ...prev };

        if (
          prevTemp.modifiers.every(mod => mod.id === '') &&
          prevTemp.products?.every(prod => prod.id === '')
        ) {
          if (modifiers?.[newModifierId]) {
            const index = currentModifierId
              ? findIndex(prev.modifiers, { id: currentModifierId })
              : rowIndex;

            if (index >= 0 && modifiers?.[newModifierId]) {
              prevTemp.modifiers[index] = {
                ...modifiers?.[newModifierId],
              } as Modifier;

              prevTemp.products = [];
            }
          }

          if (products?.[newModifierId]) {
            const indexOfProduct = currentModifierId
              ? findIndex(prev.products, {
                  id: currentModifierId,
                })
              : rowIndex;
            if (indexOfProduct >= 0 && products?.[newModifierId]) {
              prevTemp.products[indexOfProduct] = createProductAsModifier(
                products[newModifierId],
                currency,
                indexOfProduct,
              );
              prevTemp.modifiers = [];
            }
          }
        } else if (prevTemp.modifiers.length) {
          const index = currentModifierId
            ? findIndex(prev.modifiers, { id: currentModifierId })
            : rowIndex;
          if (index >= 0 && modifiers?.[newModifierId]) {
            prevTemp.modifiers[index] = {
              ...modifiers?.[newModifierId],
            } as Modifier;
          }
        } else if (prevTemp?.products?.length) {
          const indexOfProduct = currentModifierId
            ? findIndex(prev.products, {
                id: currentModifierId,
              })
            : rowIndex;
          if (indexOfProduct >= 0 && products?.[newModifierId]) {
            prevTemp.products[indexOfProduct] = createProductAsModifier(
              products[newModifierId],
              currency,
              indexOfProduct + 1,
            );
          }
        }

        return prevTemp;
      });
    },
    [currency, modifiers, products],
  );

  const onChangePrice = useCallback(
    (modId, value) => {
      setModifierGroup(prev => {
        const tempPrev = { ...prev };
        const modifierValIndex = findIndex(tempPrev.modifiers, { id: modId });
        const productModifierIndex = findIndex(tempPrev.products, {
          id: modId,
        });
        if (modifierValIndex >= 0) {
          tempPrev.modifiers[modifierValIndex] = {
            ...tempPrev.modifiers[modifierValIndex],
            price: {
              amount: value,
              currency: currency,
            },
          } as unknown as Modifier;
        }
        if (productModifierIndex >= 0 && tempPrev.products?.length) {
          tempPrev.products[productModifierIndex] = {
            ...tempPrev.products[productModifierIndex],
            price: {
              amount: value,
              currency: currency,
            },
          } as unknown as ComboItem;
        }
        return tempPrev;
      });
    },
    [currency],
  );

  const onDeleteRow = useCallback(
    (currentModifierId, rowIndex) => {
      setModifierGroup(prev => {
        const prevTemp = { ...prev };
        if (!currentModifierId) {
          prevTemp.modifiers.splice(rowIndex, 1);
          (prevTemp?.products || []).splice(rowIndex, 1);
        }

        if (modifiers?.[currentModifierId]) {
          const index = currentModifierId
            ? findIndex(prev.modifiers, { id: currentModifierId })
            : rowIndex;
          prevTemp.modifiers.splice(index, 1);
        }
        if (products?.[currentModifierId]) {
          prevTemp.products = prevTemp.products?.filter(
            prod => prod.id !== currentModifierId,
          );
        }
        return prevTemp;
      });
    },
    [modifiers, products],
  );

  const addModifierColumn = useCallback(() => {
    // add empty modifier column
    setModifierGroup(prev => {
      const prevTemp = { ...prev };
      const modifiersCopy = [...prevTemp.modifiers];
      const productsCopy = [...(prevTemp?.products || [])];

      if (!modifiersCopy?.length && !productsCopy.length) {
        prevTemp.modifiers = [{ id: '' }] as Modifier[];
        prevTemp.products = [{ id: '' }] as ComboItem[];
        return { ...prevTemp };
      }

      if (modifiersCopy?.length > 0) {
        modifiersCopy.push({ id: '' } as Modifier);
      }

      if (productsCopy?.length > 0) {
        productsCopy.push({ id: '' } as ComboItem);
      }

      return {
        ...prevTemp,
        modifiers: modifiersCopy,
        products: productsCopy,
      };
    });
  }, []);

  const onCreateModifier = useCallback(
    (input: CreateModifierInputAlias): void => {
      createModifiers([
        {
          name: input.name,
          modifierGroups: input.modifierGroups,
          type: input.type,
        },
      ]);
      setCreateModifiersData(input);
    },
    [createModifiers],
  );

  const modifierGroupsDropDownData = useMemo(() => {
    return Object.values(modifierGroups).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [modifierGroups]);

  const onAddModifier = useCallback(
    (modifierName: string, modifierIdToReplace: string) => {
      if (defaultPricingGroup?.id) {
        showModal(
          <CreateModifierModal
            modifierGroups={modifierGroupsDropDownData}
            onCreate={onCreateModifier}
            taxesOptions={taxesOptions}
            modifierDefaultValues={{
              name: modifierName,
              modifierGroups: [modifierGroup.id],
            }}
          />,
        );
        setModifierToReplaceAfterAdded(modifierIdToReplace);
      } else {
        showNotification({
          error: true,
          message: translate('productSettings.defaultPricingGroupMissing'),
        });
      }
    },
    [
      modifierGroupsDropDownData,
      onCreateModifier,
      taxesOptions,
      showModal,
      modifierGroup,
      showNotification,
      defaultPricingGroup,
      translate,
    ],
  );

  useEffect(() => {
    if (isModifierCreated && defaultPricingGroup && createdIds.length) {
      if (createModifiersData.type === ModifierType.MODIFIER) {
        addModifierPricing(createdIds[0], [
          {
            pricingGroupId: defaultPricingGroup.id,
            modifierPricing: {
              sellingPrice: createModifiersData?.price,
              sellingTax: createModifiersData?.tax,
            } as ModifierPricingInput,
          },
        ]);
      } else {
        addProductPricing(createdIds[0], [
          {
            pricingGroupId: defaultPricingGroup.id,
            productPricing: {
              sellingPrice: createModifiersData?.price,
              sellingTax: createModifiersData?.tax,
            } as ProductPricingInput,
          },
        ]);
      }
    }
  }, [
    isModifierCreated,
    defaultPricingGroup,
    createdIds,
    addModifierPricing,
    addProductPricing,
    createModifiersData,
  ]);

  useEffect(() => {
    if (
      createModifiersData?.name &&
      (isProductPricingCreated || isModifierPriceCreated) &&
      isModifierCreated &&
      createdIds.length
    ) {
      closeModal();
      const modifiersData = modifiers?.[createdIds?.[0]];
      if (modifiersData && modifiersData?.id && modifiersData?.name) {
        updateModifierGroupsData({
          id: createdIds[0],
          price:
            createModifiersData?.price || ({ amount: 0, currency } as Money),
          type: createModifiersData.type,
          name: createModifiersData.name,
        });
      }
    }
  }, [
    modifiers,
    createModifiersData,
    isProductPricingCreated,
    isModifierPriceCreated,
    isModifierCreated,
    createdIds,
    closeModal,
    updateModifierGroupsData,
    currency,
  ]);

  useEffect(() => {
    if (!loading && createdIds.length && modOperation === Operation.CREATE) {
      onChangeModifier(modifierToReplaceAfterAdded || '', createdIds[0], '');
    }
  }, [
    createdIds,
    modifierToReplaceAfterAdded,
    modOperation,
    onChangeModifier,
    loading,
  ]);

  const onDragEnd = useCallback((data: Array<Modifier>) => {
    setModifierGroup(prev => {
      const tempPrev = { ...prev };
      if (tempPrev.modifiers.length) {
        tempPrev.modifiers = data.map((modifierRow, index) => ({
          ...modifierRow,
          priority: index,
        }));
      } else if (tempPrev.products?.length) {
        tempPrev.products = data.map(
          (modifierRow, index) =>
            ({
              id: modifierRow.id,
              name: modifierRow.name,
              price: modifierRow.price,
              isDefault: modifierRow.isDefault,
              priority: index,
            } as unknown as ComboItem),
        );
      }
      return tempPrev;
    });
  }, []);

  const getProductAsModifier = useCallback(
    (id, index) => {
      const product = products?.[id];
      if (product) {
        const dataProductAsModifier = createProductAsModifier(
          product,
          currency,
          index,
        );
        return {
          ...dataProductAsModifier,
          modifierGroups: [],
        } as unknown as Modifier;
      }
    },
    [products, currency],
  );

  const getDataList = useCallback(() => {
    if (modifierGroup?.modifiers.length) return modifierGroup.modifiers;
    return (modifierGroup.products || []).map(prodMod => ({
      ...prodMod,
      id: prodMod.id,
    }));
  }, [modifierGroup]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationModal
        title={translate('modifiers.deletingModifierGroups')}
        confirmLabel={translate('dialog.deleteTitle')}
        message={translate('modifiers.deleteModifierGroupMessage', {
          name: modifierGroup?.name,
        })}
        onConfirm={async () => {
          await deleteModifierGroups([modifierGroup.id]);
          closeModal();
          navigation.navigate('OptionGroups');
        }}
      />,
    );
  }, [
    showModal,
    translate,
    modifierGroup?.name,
    modifierGroup.id,
    deleteModifierGroups,
    closeModal,
    navigation,
  ]);

  const maxSelectionOptions = useMemo(() => {
    const max = modifierGroup?.selectionLimit?.max || 1;
    return Array(max)
      .fill(null)
      .map((_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
  }, [modifierGroup?.selectionLimit?.max]);

  return (
    <ScreenLayout
      loading={loading}
      title={`${modifierGroup?.name || 'Modifier'} | Oolio`}
      onSave={onSave}
      onDelete={onPressDelete}
      onDeleteDisabled={!modifierGroup?.id}
    >
      <Section title={translate('backOfficeProducts.courseDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('form.name')}
            value={modifierGroup?.name || ''}
            placeholder={translate('form.name')}
            onChangeText={onChange.bind(null, 'name')}
            maxLength={50}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-kot"
            title={translate('productSettings.kitchenName')}
            value={modifierGroup?.kitchenPrintLabel || ''}
            placeholder={translate('productSettings.kitchenName')}
            onChangeText={onChange.bind(null, 'kitchenPrintLabel')}
            maxLength={50}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-minSelection"
            title={translate('modifiers.selections')}
            label={translate('productSettings.min')}
            value={selections?.min.toString()}
            placeholder="0"
            onChangeText={value => onChange('min', value)}
            keyboardType="number-pad"
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-maxSelection"
            title={translate('modifiers.selections')}
            label={translate('productSettings.max')}
            value={selections?.max.toString()}
            placeholder="0"
            onChangeText={value => onChange('max', value)}
            keyboardType="number-pad"
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            title={translate('modifiers.maxSelectionPerOption')}
            options={maxSelectionOptions}
            selectedValue={modifierGroup?.maxSelectionPerOption?.toString()}
            onValueChange={(value: string) =>
              onChange('maxSelectionPerOption', parseInt(value))
            }
            testID="input-maxSelection-per-option"
            containerStyle={{ ...theme.forms.inputHalf }}
          />
        </View>
      </Section>
      {modifierGroup?.id ? (
        <Section title={translate('modifiers.assignModifiers')}>
          <View style={styles.tableContainer}>
            <View style={theme.tables.header}>
              <Text style={[theme.tables.headerText, styles.cellOptions]}>
                {translate('modifiers.options')}
              </Text>
              <Text style={[theme.tables.headerText, styles.headerAddOnPrice]}>
                {translate('modifiers.price')}
              </Text>
            </View>
            {getDataList().length > 0 ? (
              <DraggableFlatList
                columns={[]}
                onDragEnd={(data: Array<Modifier>) => onDragEnd(data)}
                data={getDataList()}
                normalRows
                renderRow={(
                  modifier: Modifier,
                  i: number,
                  drag: () => void,
                ): React.ReactNode => (
                  <ModifierRow
                    modifier={
                      ({
                        ...(modifiersMap[modifier?.id] ||
                          getProductAsModifier(modifier?.id, i)),
                        price: modifier.price,
                      } || {}) as Modifier
                    }
                    availableModifiers={modifierOptions}
                    onChangeModifier={onChangeModifier}
                    onDeleteRow={onDeleteRow}
                    rowIndex={i}
                    onAddModifier={onAddModifier}
                    onChangePrice={onChangePrice}
                    drag={drag}
                    isOnlyShowProductAsOption={isOnlyProductAsOption}
                    isOnlyShowModifierOption={isOnlyModifierAsOption}
                  />
                )}
              />
            ) : (
              <View style={theme.tables.emptyView}>
                <Text style={theme.tables.emptyText}>
                  {translate('modifiers.noModifiersMessage')}
                </Text>
              </View>
            )}
          </View>
          <TreatButton
            testID="btn-addModifier"
            type="positiveLight"
            onPress={addModifierColumn}
            label={translate('modifiers.addOption')}
            uppercase
          />
        </Section>
      ) : (
        <></>
      )}
    </ScreenLayout>
  );
};
