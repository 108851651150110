import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import format from 'date-fns/format';
import { OrderStatus, OrderTypeCode } from '@oolio-group/domain';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { OpenOrdersDataProps } from './OpenOrdersTable';
import {
  getAgeFormat,
  getDurationFormat,
} from '../../../../../utils/dateHelper';
import theme from '../../../../../common/default-theme';
import styles from './OpenOrderRow.styles';
import Icon from '../../../../../components/Icon/Icon';
import Sticker from '../../../../../components/Shared/Sticker/Sticker';
import Identifier from '../../../../../components/Shared/Identifier/Identifier';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import PrintReceiptButton from '../../../../../components/POS/Buttons/PrintReceipt/PrintReceiptButton';

interface OpenOrderRowProps {
  order: OpenOrdersDataProps;
  onPress: ((id: string) => void) | undefined;
  onPressVoid: (id: string) => void;
  onPressNote: (orderNo: string, note: string) => void;
  onPressPrintDocket: (id: string) => void;
  onPressPrintReceipt: (id: string, nthPayment?: number) => void;
}

const OpenOrderRow: React.FC<OpenOrderRowProps> = ({
  order,
  onPress,
  onPressVoid,
  onPressNote,
  onPressPrintDocket,
  onPressPrintReceipt,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { width: vw } = theme.useResponsiveDimensions();

  const timerStatus = useCallback((input: number) => {
    const diff = Date.now() - input;
    return diff <= 600000
      ? 'positiveLight'
      : diff > 600000 && diff <= 900000
      ? 'focusLight'
      : 'negativeLight';
  }, []);

  const getOrderName = (): string => {
    const { orderType, table, customer, tokenNumber } = order;
    const hasCustomer = customer !== 'N/A';
    const tableName = table?.name
      ? `${translate('reservations.table')} ${table.name}`
      : '';

    switch (orderType.code as OrderTypeCode) {
      case OrderTypeCode.DINE_IN:
        return `${tableName}${hasCustomer ? ` (${customer})` : ''}`;
      case OrderTypeCode.TAKEAWAY:
        return hasCustomer
          ? `${tokenNumber ? `#${tokenNumber} (${customer})` : customer}`
          : tokenNumber
          ? `#${tokenNumber}`
          : 'N/A';
      default:
        return customer;
    }
  };

  const hasNote = order.orderNote.length > 0;
  const orderName = getOrderName();

  return (
    <TouchableOpacity
      testID="row-openOrder"
      style={styles.container}
      onPress={() => onPress && onPress(order.id)}
    >
      <Identifier orderType={order.orderType.code as OrderTypeCode} />
      <Sticker
        testID="order-age"
        type={timerStatus(order.createdAt)}
        label={getAgeFormat(order.createdAt)}
        containerStyle={styles.cellAge}
      />
      <Text testID="order-createdAt" style={styles.cellPlacedAt}>
        {format(new Date(order.createdAt), 'MMM dd, hh:mm a')}
      </Text>
      <Text testID="order-no" numberOfLines={1} style={styles.cell100}>
        {order.orderNumber.slice(0, -9)}
      </Text>
      {vw >= 1280 ? (
        <Text testID="order-user" numberOfLines={1} style={styles.cell100}>
          {order.staff}
        </Text>
      ) : null}
      <Text
        testID="order-name"
        numberOfLines={1}
        style={[
          styles.cellName,
          {
            color:
              orderName === 'N/A' ? theme.colors.grey4 : theme.colors.black1,
          },
        ]}
      >
        {orderName}
      </Text>
      <Text
        testID="order-note"
        numberOfLines={1}
        style={[
          styles.cellNote,
          {
            color: !hasNote ? theme.colors.grey4 : theme.colors.black1,
          },
        ]}
      >
        {hasNote ? order.orderNote : translate('moneyMovements.noNote')}
      </Text>
      {vw >= 1280 ? (
        <Text
          testID="order-updatedAt"
          numberOfLines={1}
          style={styles.cellLastAction}
        >
          {getDurationFormat(order.updatedAt)}
        </Text>
      ) : null}
      <Text testID="order-amount" style={styles.cellAmount}>
        {formatCurrency(order.totalValue)}
      </Text>
      <View style={styles.actions}>
        <ButtonIcon
          icon="notes"
          type="neutral"
          testID="btn-note"
          disabled={!order.orderNote}
          onPress={() =>
            onPressNote(order.orderNumber.slice(0, -9), order.orderNote)
          }
        />
        <PrintReceiptButton
          options={order.payTypes}
          onPrintReceipt={paymentIndex =>
            onPressPrintReceipt(order.id, paymentIndex)
          }
          reprintDocket={() => onPressPrintDocket(order.id)}
          orderStatus={OrderStatus.IN_PROGRESS}
        />
        <ButtonIcon
          icon="times"
          testID="btn-void"
          type="negativeLight"
          onPress={onPressVoid.bind(null, order.id)}
        />
      </View>
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey5} />
      </View>
    </TouchableOpacity>
  );
};

export default OpenOrderRow;
