import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { View, Text, Linking } from 'react-native';
import {
  IntegrationApps,
  OnlineOrderingPreferences,
  TimeBlock,
  UpdateIntegrationPartnerInput,
  DayOfWeek,
  TimeSlot,
  DEFAULT_ONLINE_STORE_OPEN_TIME,
  DEFAULT_ONLINE_STORE_CLOSE_TIME,
  ImageUploadInput,
  DEFAULT_ONLINE_STORE_ACCENT_COLOR,
  DEFAULT_COMPLETE_PERIOD_MINS,
  MenuLayout,
  TimeRange,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useStores } from '../../../../../../../hooks/app/useStores';
import { useIntegrationPartners } from '../../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../../types/Operation';
import { useNotification } from '../../../../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import { keyBy, mergeWith, isNull } from 'lodash';
import { stripProperties } from '../../../../../../../utils/stripObjectProps';
import { REACT_APP_ONLINE_STORE_PREVIEW_URL } from 'react-native-dotenv';
import { convertAlphaNumbericToNumber } from '@oolio-group/client-utils';
import { useDevices } from '../../../../../../../hooks/app/useDevices';
import { stringToUrlPattern } from '@oolio-group/client-utils';
import { useIsFocused } from '@react-navigation/native';
import { useClipboard } from '@react-native-clipboard/clipboard';
import { timeSlotOptions } from '../../../../../../../utils/dateHelper';
import { pascalCase } from 'pascal-case';
import theme from '../../../../../../../common/default-theme';
import styles from './Details.styles';
import { Option } from '../../../../../../../components/Shared/Select/Select';
import ScreenLayout from '../../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../../components/Shared/Inputs/InputText';
import InputToggle from '../../../../../../../components/Shared/Inputs/InputToggle';
import ButtonIcon from '../../../../../../../components/Shared/TreatButton/ButtonIcon';
import ImagePicker from '../../../../../../../components/Office/ImagePicker/ImagePicker';
import Message from '../../../../../../../components/Office/Message/Message';
import TreatPicker from '../../../../../../../components/Shared/Select/Picker';
import SelectMultiple from '../../../../../../../components/Shared/Select/SelectMultiple';
import InputColour from '../../../../../../../components/Shared/Inputs/InputColour';
import { NO_VALUE_OPTION } from '../../../../../../../types/Common';
import { useMenuSchedules } from '../../../../../../../hooks/app/menuSchedules/useMenuSchedules';

export enum OrderAcceptance {
  Enable = 'Enable',
  Disable = 'Disable',
}

export const urlPrefix =
  process.env['REACT_APP_ONLINE_STORE_PREVIEW_URL'] ||
  REACT_APP_ONLINE_STORE_PREVIEW_URL ||
  'https://order.oolio.io';

type IntegrationPreferences = OnlineOrderingPreferences & {
  operatingHoursMap: { [key: string]: TimeBlock };
  storeId?: string;
} & { locationId?: string; accountId?: string };

export const Details: React.FC = () => {
  const [, setString] = useClipboard();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [imageRawData, setImageRawData] = useState<ImageUploadInput>();
  const [integrationPreferences, setIntegrationPreferences] =
    useState<IntegrationPreferences>({
      menu: '',
      menuLayout: MenuLayout.PRODUCT_FIRST,
      pricingGroup: '',
      pricingGroups: [],
      operatingHoursMap: {},
      storeSlug: '',
      accentColour: DEFAULT_ONLINE_STORE_ACCENT_COLOR,
      favImage: '',
      autoAcceptOrders: false,
      isPrepTimeEnabled: false,
      defaultPrepTime: 0,
      autoCompleteOrders: false,
      autoCompletePeriod: DEFAULT_COMPLETE_PERIOD_MINS,
      printDevice: '',
      storeId: '',
    });

  const route = useRoute();
  const params = route.params as {
    storeId: string;
    app: IntegrationApps;
  };
  const [isStoreSlugVerified, setStoreSlugverified] = useState(false);
  const storeId = params.storeId;

  const isOolio = params.app === IntegrationApps.OOLIO_STORE;
  const isDeliverect = params.app === IntegrationApps.DELIVERECT;
  const isOOM = params.app === IntegrationApps.OOM;

  const { menuSchedules, getMenuSchedules } = useMenuSchedules();

  const {
    stores,
    loading: storesLoading,
    error: storesError,
    slugs,
    getSlugsByStore,
  } = useStores({ storeId });

  const { devices, getDevices } = useDevices({ storeId });
  const deviceSelectOptions = useMemo(() => {
    return [
      NO_VALUE_OPTION,
      ...Object.values(devices).map(device => ({
        label: device.name,
        value: device.id,
      })),
    ];
  }, [devices]);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  const {
    loading: integrationLoading,
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
    updateIntegrationPartnerSettings,
    uploadOnlineStoreFavImageFile,
    createIntegrationPartner,
    operation,
    error: integrationErr,
    checkStoreSlugExists,
  } = useIntegrationPartners();

  const venueId = useMemo(() => {
    return storeId && stores[storeId] ? stores[storeId].venue.id : '';
  }, [storeId, stores]);

  const defaultStoreSlug = useMemo(() => {
    if (slugs?.store) {
      return stringToUrlPattern(slugs.store);
    }
  }, [slugs?.store]);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!defaultStoreSlug && storeId) {
      getSlugsByStore(storeId);
    }
  }, [defaultStoreSlug, storeId, getSlugsByStore]);

  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);

  const mappingScheduleToTimeBlocks = useCallback((timeBlocks: TimeRange[]) => {
    const timeBlocksMapped: TimeBlock[] = [];
    timeBlocks.forEach(item => {
      if (item.days?.length === 1) {
        if (item.days[0] === DayOfWeek.ALL) {
          [
            DayOfWeek.MONDAY,
            DayOfWeek.TUESDAY,
            DayOfWeek.WEDNESDAY,
            DayOfWeek.THURSDAY,
            DayOfWeek.FRIDAY,
            DayOfWeek.SATURDAY,
            DayOfWeek.SUNDAY,
          ].map(day => {
            timeBlocksMapped.push({
              day: day,
              isActive: true,
              timeSlots: [
                {
                  startTime: item.timeSlot.startTime,
                  endTime: item.timeSlot.endTime,
                },
              ],
            });
          });
        } else {
          timeBlocksMapped.push({
            day: item.days[0],
            isActive: true,
            timeSlots: [
              {
                startTime: item.timeSlot.startTime,
                endTime: item.timeSlot.endTime,
              },
            ],
          });
        }
      } else {
        item.days.map(day => {
          timeBlocksMapped.push({
            day: day,
            isActive: true,
            timeSlots: [
              {
                startTime: item.timeSlot.startTime,
                endTime: item.timeSlot.endTime,
              },
            ],
          });
        });
      }
    });
    return timeBlocksMapped;
  }, []);

  useEffect(() => {
    if (storeId && isFocused) {
      getIntegrationPartnerSettings({
        appName: params.app,
        store: storeId,
      });
    }
  }, [getIntegrationPartnerSettings, params.app, storeId, isFocused]);

  useEffect(() => {
    if (isOOM && storeId && integrationPartners[storeId]?.preferences) {
      getMenuSchedules();
    }
  }, [getMenuSchedules, integrationPartners, isOOM, storeId]);

  useEffect(() => {
    if (storeId && integrationPartners[storeId]?.preferences) {
      const defaultTimeSlot: TimeSlot = {
        endTime: DEFAULT_ONLINE_STORE_CLOSE_TIME,
        startTime: DEFAULT_ONLINE_STORE_OPEN_TIME,
      };

      const preferences =
        integrationPartners[storeId]?.preferences?.onlineOrdering;

      const operatingHoursArray = preferences?.operatingHours?.map(opHours => ({
        ...opHours,
        timeSlots: [
          {
            ...defaultTimeSlot,
            ...opHours.timeSlots?.[0],
          },
        ],
      }));

      const operatingHoursObj = keyBy(operatingHoursArray, 'day');

      setIntegrationPreferences({
        pricingGroup: preferences?.pricingGroup,
        pricingGroups: preferences?.pricingGroups,
        menu: preferences?.menu,
        menuLayout: preferences?.menuLayout,
        operatingHoursMap: operatingHoursObj,
        devices: preferences?.devices || [],
        locationId: integrationPartners[storeId].id,
        accountId: integrationPartners[storeId].accountId,
        accentColour:
          preferences?.accentColour || DEFAULT_ONLINE_STORE_ACCENT_COLOR,
        favImage: preferences?.favImage || '',
        autoAcceptOrders: preferences?.autoAcceptOrders || false,
        autoCompleteOrders: preferences?.autoCompleteOrders || false,
        autoCompletePeriod: DEFAULT_COMPLETE_PERIOD_MINS,
        defaultPrepTime: preferences?.defaultPrepTime || 0,
        printDevice: preferences?.printDevice || '',
        storeSlug: stringToUrlPattern(
          preferences?.storeSlug || defaultStoreSlug || '',
        ),
        storeId: integrationPartners[storeId]?.store,
      });
    }
  }, [integrationPartners, storeId, defaultStoreSlug, isOOM, menuSchedules]);

  const loading = storesLoading || integrationLoading;

  const error = storesError || integrationErr;

  const isUpdated =
    !integrationErr && !integrationLoading && operation === Operation.UPDATE;

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: params.app,
        }),
      });
    }
  }, [isUpdated, params.app, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const menuOptions = useMemo(() => {
    let result: Option[] = [];
    if (storeId && stores[storeId]?.catalogues?.length) {
      result = stores[storeId]?.catalogues?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }

    return [...result];
  }, [stores, storeId]);

  useEffect(() => {
    if (menuOptions?.length && !integrationPreferences.menu) {
      setIntegrationPreferences(prevState => ({
        ...prevState,
        menu: menuOptions[0]?.value,
      }));
    }
  }, [integrationPreferences.menu, menuOptions]);

  const pricelistOptions = useMemo(() => {
    let result: Option[] = [];
    if (stores[storeId]?.pricingGroups?.length) {
      result = stores[storeId]?.pricingGroups?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }

    return [...result];
  }, [stores, storeId]);

  useEffect(() => {
    if (
      !isOolio &&
      pricelistOptions?.length &&
      !integrationPreferences.pricingGroup
    ) {
      setIntegrationPreferences(prevState => ({
        ...prevState,
        pricingGroup: pricelistOptions[0]?.value,
      }));
    }
  }, [isOolio, integrationPreferences.pricingGroup, pricelistOptions]);

  const menuLayouts = useMemo(() => {
    return [
      {
        label: translate('backOfficeFeatures.menuLayoutOption.product'),
        value: MenuLayout.PRODUCT_FIRST,
      },
      {
        label: translate('backOfficeFeatures.menuLayoutOption.page'),
        value: MenuLayout.PAGE_FIRST,
      },
    ];
  }, [translate]);

  const deviceOptions = useMemo(() => {
    let result: Option[] = [];
    if (stores[storeId]?.devices?.length) {
      result = stores[storeId]?.devices?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }
    return result;
  }, [storeId, stores]);

  const onChangeOperatingHours = useCallback((day, key, value) => {
    setIntegrationPreferences(prev => {
      const defaultTimeBlock: TimeBlock = {
        day,
        timeSlots: [
          {
            endTime: DEFAULT_ONLINE_STORE_CLOSE_TIME,
            startTime: DEFAULT_ONLINE_STORE_OPEN_TIME,
          },
        ],
        isActive: false,
      };

      const result = {
        ...prev,
        operatingHoursMap: {
          ...prev.operatingHoursMap,
          [day]: {
            ...defaultTimeBlock,
            ...prev.operatingHoursMap[day],
            timeSlots: [
              {
                ...defaultTimeBlock.timeSlots[0],
                ...prev.operatingHoursMap[day]?.timeSlots?.[0],
              },
            ],
          },
        } as { [key: string]: TimeBlock },
      };

      if (key === 'startTime' || key === 'endTime') {
        result.operatingHoursMap[day].timeSlots = [
          {
            ...result.operatingHoursMap[day].timeSlots?.[0],
            [key]: value,
          },
        ];
      } else {
        const existingTimeSlot = prev?.operatingHoursMap[day]?.timeSlots?.[0];
        result.operatingHoursMap[day] = {
          [key]: value,
          day,
          timeSlots: [
            {
              startTime:
                existingTimeSlot?.startTime || DEFAULT_ONLINE_STORE_OPEN_TIME,
              endTime:
                existingTimeSlot?.endTime || DEFAULT_ONLINE_STORE_CLOSE_TIME,
            },
          ],
        };
      }

      return result;
    });
  }, []);

  const saveIntegrationSettings = useCallback(() => {
    if (isOolio && !isStoreSlugVerified) {
      showNotification({
        error: true,
        message: translate('backOfficeFeatures.storeSlugError'),
      });
      return;
    }

    let operatingHoursMappingSchedule;

    if (isOOM && menuSchedules) {
      const selectedScheduledMenu = Object.values(menuSchedules).find(
        schedule =>
          schedule.menu === integrationPreferences.menu && schedule.isActive,
      );
      if (selectedScheduledMenu) {
        operatingHoursMappingSchedule = mappingScheduleToTimeBlocks(
          selectedScheduledMenu.schedules?.[0]?.timeBlocks,
        );
      }
    }

    if (!isOOM) {
      operatingHoursMappingSchedule = Object.values(
        stripProperties(integrationPreferences.operatingHoursMap, '__typename'),
      ) as TimeBlock[];
    }

    const onlineOrdering =
      integrationPartners[storeId] &&
      mergeWith(
        {},
        {
          ...integrationPartners[storeId].preferences?.onlineOrdering,
          operatingHours: operatingHoursMappingSchedule,
        },
        {
          storeSlug: integrationPreferences?.storeSlug || defaultStoreSlug,
          menu: integrationPreferences?.menu,
          pricingGroups: integrationPreferences?.pricingGroups,
          pricingGroup: integrationPreferences?.pricingGroup,
          operatingHours: operatingHoursMappingSchedule,
          devices: integrationPreferences?.devices,
          accentColour: integrationPreferences?.accentColour,
          autoAcceptOrders: integrationPreferences?.autoAcceptOrders,
          defaultPrepTime: integrationPreferences?.defaultPrepTime,
          autoCompleteOrders: integrationPreferences?.autoCompleteOrders,
          autoCompletePeriod: integrationPreferences?.autoCompletePeriod,
          printDevice: integrationPreferences?.printDevice,
          deliverySettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.deliverySettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.deliverySettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          pickUpSettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.pickUpSettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.pickUpSettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          dineInSettings: {
            ...integrationPartners[storeId].preferences?.onlineOrdering
              ?.dineInSettings,
            adjustments: integrationPartners[
              storeId
            ].preferences?.onlineOrdering?.dineInSettings?.adjustments?.map(
              adjustment => adjustment.id,
            ),
          },
          menuLayout: integrationPreferences?.menuLayout,
        },
        (sourceValue, destinationValue) =>
          isNull(destinationValue) ? sourceValue : destinationValue,
      );

    const updatedOnlineOrderingPref = (
      isOolio
        ? stripProperties(onlineOrdering, 'pricingGroup')
        : stripProperties(onlineOrdering, 'pricingGroups')
    ) as OnlineOrderingPreferences;

    const updateSetting: UpdateIntegrationPartnerInput[] = [
      {
        id: integrationPartners[storeId]?.id,
        isActive: true,
        appName: params.app,
        modules: {
          onlineOrdering: true,
        },
        store: storeId,
        preferences: {
          onlineOrdering: updatedOnlineOrderingPref,
        },
        link: '',
        venue: venueId,
      },
    ];
    imageRawData &&
      updateSetting[0].id &&
      uploadOnlineStoreFavImageFile(imageRawData, updateSetting[0].id);

    const isPricingGroupExists =
      (isOolio && integrationPreferences?.pricingGroups?.length) ||
      integrationPreferences?.pricingGroup;
    if (!integrationPreferences?.menu || !isPricingGroupExists) {
      showNotification({
        error: true,
        message: translate('backOfficeFeatures.pleaseSelectMenuAndPriceGroup'),
      });
    } else {
      if (isOolio && !integrationPartners[storeId]) {
        createIntegrationPartner(
          stripProperties({ ...updateSetting[0] }, '__typename'),
        );
      } else {
        updateIntegrationPartnerSettings(
          stripProperties(updateSetting, '__typename'),
        );
      }
    }
  }, [
    isOolio,
    isStoreSlugVerified,
    isOOM,
    menuSchedules,
    integrationPartners,
    storeId,
    integrationPreferences,
    defaultStoreSlug,
    params.app,
    venueId,
    imageRawData,
    uploadOnlineStoreFavImageFile,
    showNotification,
    translate,
    mappingScheduleToTimeBlocks,
    createIntegrationPartner,
    updateIntegrationPartnerSettings,
  ]);

  const onChangeMainDetails = useCallback((key, value) => {
    setIntegrationPreferences(prev => ({ ...prev, [key]: value }));
  }, []);

  const checkSlug = useCallback(async () => {
    if (
      integrationPreferences?.storeSlug &&
      integrationPreferences?.storeSlug?.length > 3 &&
      isOolio
    ) {
      const res = await checkStoreSlugExists(
        IntegrationApps.OOLIO_STORE,
        integrationPreferences?.storeSlug,
        params.storeId,
      );
      setStoreSlugverified(res);
    }
  }, [
    checkStoreSlugExists,
    integrationPreferences?.storeSlug,
    params.storeId,
    isOolio,
  ]);

  useEffect(() => {
    checkSlug();
  }, [checkSlug]);

  const previewLink = `${urlPrefix}/${stringToUrlPattern(
    integrationPreferences.storeSlug || defaultStoreSlug || '',
  )}`;

  const orderAcceptanceOptions = useMemo(
    () => [
      {
        value: OrderAcceptance.Enable,
        label: translate('backOfficeFeatures.enabled'),
      },
      {
        value: OrderAcceptance.Disable,
        label: translate('backOfficeFeatures.disabled'),
      },
    ],
    [translate],
  );

  const renderOperatingHours = useMemo(() => {
    if (isDeliverect || isOOM) return <></>;
    return (
      <Section title={translate('backOfficeFeatures.operatingHours')}>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerDay]}>
              {translate('backOfficeFeatures.day')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerStart]}>
              {translate('backOfficeFeatures.opens')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerClose]}>
              {translate('backOfficeFeatures.closes')}
            </Text>
          </View>
          <View>
            {Object.values(DayOfWeek)
              .filter(x => x && x != DayOfWeek.ALL)
              .map((day: DayOfWeek, i: number) => {
                const isActive =
                  integrationPreferences.operatingHoursMap[day]?.isActive ||
                  false;
                return (
                  <View testID="row-day" key={i} style={theme.tables.row}>
                    <InputToggle
                      testID="toggle-active"
                      type="switch"
                      isToggled={isActive}
                      onToggle={() =>
                        onChangeOperatingHours(day, 'isActive', !isActive)
                      }
                    />
                    <Text style={styles.cellDay}>{pascalCase(day)}</Text>
                    <TreatPicker
                      testID="select-startTime"
                      options={timeSlotOptions}
                      selectedValue={
                        integrationPreferences.operatingHoursMap[day]
                          ?.timeSlots?.[0]?.startTime ||
                        DEFAULT_ONLINE_STORE_OPEN_TIME
                      }
                      onValueChange={value =>
                        isActive &&
                        onChangeOperatingHours(day, 'startTime', value)
                      }
                      containerStyle={styles.cellTime}
                    />
                    <TreatPicker
                      testID="select-endTime"
                      options={timeSlotOptions}
                      selectedValue={
                        integrationPreferences.operatingHoursMap[day]
                          ?.timeSlots?.[0]?.endTime ||
                        DEFAULT_ONLINE_STORE_CLOSE_TIME
                      }
                      onValueChange={value =>
                        isActive &&
                        onChangeOperatingHours(day, 'endTime', value)
                      }
                      containerStyle={styles.cellTime}
                    />
                  </View>
                );
              })}
          </View>
        </View>
      </Section>
    );
  }, [
    integrationPreferences.operatingHoursMap,
    isDeliverect,
    isOOM,
    onChangeOperatingHours,
    translate,
  ]);

  const copyToClipboard = useCallback(
    (text: string) => {
      setString(text);
    },
    [setString],
  );

  const handleOnExternalAction = useCallback(() => {
    const oolioOrdersLink = process.env.REACT_APP_OOM_URL as string;
    Linking.openURL(oolioOrdersLink);
  }, []);

  return (
    <ScreenLayout
      loading={loading}
      title="Online Store Settings – Oolio"
      onSave={saveIntegrationSettings}
      onExternalAction={isOOM ? handleOnExternalAction : undefined}
      onExternalLabel={translate('backOfficeFeatures.oolioOrders')}
      onSaveLabel={
        isOOM ? translate('backOfficeFeatures.sync&Save') : undefined
      }
    >
      {!isDeliverect && !isOOM ? (
        <Section>
          <View>
            <View style={styles.imageContainer}>
              <ImagePicker
                imageUrl={
                  imageRawData?.base64 || integrationPreferences?.favImage
                }
                onComplete={imageRawData => {
                  setImageRawData(imageRawData);
                }}
              />
            </View>
            <Message
              type="neutral"
              message="We recommend an image size of at least 256✗256."
              containerStyle={styles.message}
            />
          </View>
        </Section>
      ) : (
        <></>
      )}
      <Section title={translate('backOfficeFeatures.storeDetails')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            editable={false}
            title={translate('backOfficeFeatures.storeName')}
            value={stores[storeId]?.name}
            placeholder="Store Name"
            containerStyle={
              isOOM ? theme.forms.inputFluid : theme.forms.inputHalf
            }
          />
          {!isDeliverect && !isOOM && (
            <InputColour
              testID="colour-accent"
              title={translate('backOfficeFeatures.accentColour')}
              label="HEX"
              value={integrationPreferences?.accentColour?.toString()}
              onChangeColour={colourString =>
                onChangeMainDetails('accentColour', colourString)
              }
              containerStyle={theme.forms.inputHalf}
            />
          )}
        </View>
        {isOOM ? (
          <View style={theme.forms.row}>
            <InputText
              testID="input-posLocationID"
              editable={false}
              title={translate('backOfficeFeatures.posLocationID')}
              value={integrationPreferences.storeId}
              placeholder=""
              containerStyle={theme.forms.inputFluid}
            />
          </View>
        ) : (
          <></>
        )}
        {isOolio ? (
          <View style={theme.forms.row}>
            <InputText
              testID="input-slug"
              title={translate('backOfficeFeatures.storeUrlSlug')}
              value={integrationPreferences.storeSlug}
              placeholder={defaultStoreSlug || ''}
              label={urlPrefix.replace(/^https:\/\//, '') + '/'}
              isVerified={isStoreSlugVerified}
              onChangeText={slug =>
                onChangeMainDetails('storeSlug', stringToUrlPattern(slug))
              }
              errorMessage={
                !isStoreSlugVerified ? 'Enter valid slug' : undefined
              }
              containerStyle={theme.forms.inputFluid}
            />
            <ButtonIcon
              size={44}
              icon="copy"
              type="neutralLight"
              onPress={() => copyToClipboard(previewLink)}
              containerStyle={styles.btnCopy}
            />
          </View>
        ) : (
          <></>
        )}
      </Section>
      <Section title={translate('backOfficeFeatures.preferences')}>
        {isDeliverect ? (
          <View style={theme.forms.row}>
            <InputText
              isVerified
              testID="input-locationID"
              title={translate('backOfficeFeatures.locationID')}
              value={integrationPreferences.locationId}
              placeholder=""
              containerStyle={theme.forms.inputHalf}
            />
            <InputText
              isVerified
              testID="input-accountID"
              title={translate('backOfficeFeatures.accountID')}
              value={integrationPreferences.accountId}
              placeholder=""
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        ) : (
          <></>
        )}
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-menu"
            title={translate('backOfficeFeatures.selectMenus')}
            options={menuOptions}
            selectedValue={integrationPreferences.menu}
            onValueChange={value => onChangeMainDetails('menu', value)}
            containerStyle={theme.forms.inputHalf}
          />
          {isOolio && (
            <TreatPicker
              testID="select-layout"
              title={translate('backOfficeFeatures.menuLayout')}
              options={menuLayouts}
              selectedValue={integrationPreferences.menuLayout}
              onValueChange={value => onChangeMainDetails('menuLayout', value)}
              containerStyle={theme.forms.inputHalf}
            />
          )}
          {isOOM && (
            <TreatPicker
              testID="select-pricelist"
              title={translate('backOfficeFeatures.selectPricingGroup')}
              options={pricelistOptions}
              selectedValue={integrationPreferences.pricingGroup}
              onValueChange={value =>
                onChangeMainDetails('pricingGroup', value)
              }
              containerStyle={theme.forms.inputHalf}
            />
          )}
        </View>
        {isOOM ? (
          <View style={theme.forms.row}>
            <TreatPicker
              testID="select-printRoutingSelection"
              title={translate('backOfficeFeatures.printRoutingSelection')}
              options={deviceSelectOptions}
              selectedValue={integrationPreferences.printDevice}
              onValueChange={value => onChangeMainDetails('printDevice', value)}
              containerStyle={theme.forms.inputHalf}
            />
            <TreatPicker
              testID="select-acceptance"
              title={translate('backOfficeFeatures.autoAcceptOrders')}
              options={orderAcceptanceOptions}
              selectedValue={
                integrationPreferences?.autoAcceptOrders
                  ? OrderAcceptance.Enable
                  : OrderAcceptance.Disable
              }
              onValueChange={value =>
                onChangeMainDetails(
                  'autoAcceptOrders',
                  value === OrderAcceptance.Enable ? true : false,
                )
              }
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        ) : (
          <></>
        )}
        <View style={theme.forms.row}>
          {isOolio ? (
            <SelectMultiple
              testID="select-pricelist"
              title={translate('backOfficeFeatures.selectPricingGroup')}
              options={pricelistOptions}
              selectedValues={integrationPreferences.pricingGroups}
              onValueChange={value =>
                onChangeMainDetails('pricingGroups', value)
              }
              containerStyle={theme.forms.inputFluid}
            />
          ) : (
            !isOOM && (
              <TreatPicker
                testID="select-pricelist"
                title={translate('backOfficeFeatures.selectPricingGroup')}
                options={pricelistOptions}
                selectedValue={integrationPreferences.pricingGroup}
                onValueChange={value =>
                  onChangeMainDetails('pricingGroup', value)
                }
                containerStyle={theme.forms.inputFluid}
              />
            )
          )}
        </View>
        {!isOolio && !isDeliverect && !isOOM ? (
          <View style={theme.forms.row}>
            <SelectMultiple
              testID="select-devices"
              title={translate('backOfficeFeatures.selectDevices')}
              options={deviceOptions}
              selectedValues={integrationPreferences.devices}
              onValueChange={value => onChangeMainDetails('devices', value)}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
        ) : (
          <></>
        )}
      </Section>
      {!isOolio && !isOOM ? (
        <Section title={translate('backOfficeFeatures.autoAcceptOrders')}>
          <View style={theme.forms.row}>
            <TreatPicker
              testID="select-acceptance"
              title={translate('backOfficeFeatures.autoAcceptOrders')}
              options={orderAcceptanceOptions}
              selectedValue={
                integrationPreferences?.autoAcceptOrders
                  ? OrderAcceptance.Enable
                  : OrderAcceptance.Disable
              }
              onValueChange={value =>
                onChangeMainDetails(
                  'autoAcceptOrders',
                  value === OrderAcceptance.Enable ? true : false,
                )
              }
              containerStyle={theme.forms.inputHalf}
            />
            <InputText
              testID="input-preptime"
              title={translate('backOfficeFeatures.defaultPrepTime')}
              value={integrationPreferences?.defaultPrepTime?.toString()}
              placeholder={translate('backOfficeFeatures.defaultPrepTime')}
              onChangeText={value =>
                onChangeMainDetails(
                  'defaultPrepTime',
                  convertAlphaNumbericToNumber(value),
                )
              }
              containerStyle={theme.forms.inputHalf}
            />
          </View>
          <View style={theme.forms.row}>
            <TreatPicker
              testID="select-devices"
              title={translate('backOfficeFeatures.printRoutingSelection')}
              options={deviceSelectOptions}
              selectedValue={integrationPreferences.printDevice}
              onValueChange={value => onChangeMainDetails('printDevice', value)}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        </Section>
      ) : (
        <></>
      )}
      {renderOperatingHours}
    </ScreenLayout>
  );
};
