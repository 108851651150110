import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import keyBy from 'lodash/keyBy';
import {
  Order,
  OrderAction,
  OrderPaymentStatus,
  OrderType,
  Product,
  Resource,
  Table,
  VoidOrderEvent,
  VoidReason,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { cloneJSON } from '@oolio-group/client-utils';
import { useTranslation } from '@oolio-group/localization';
import {
  inventoryProductFragment,
  useProducts,
} from '../../../../../hooks/app/products/useProducts';
import { useCart } from '../../../../../hooks/orders/useCart';
import { useSession } from '../../../../../hooks/app/useSession';
import { useNotification } from '../../../../../hooks/Notification';
import usePOSUserAuthorization from '../../../../../hooks/app/users/usePOSUserAuthorization';
import { useSyncItemAvailability } from '../../../../../hooks/app/orders/useSyncItemAvailability';
import { productQuantitiesToReturnOnVoidOrder } from '../../../../../utils/OpenOrdersHelper';
import { PaymentTypeDisplay } from '../OpenOrders.types';
import styles from '../OpenOrders.styles';
import theme, { DEFAULT_PAGE_SIZE } from '../../../../../common/default-theme';
import OpenOrderRow from './OpenOrderRow';
import Pagination from '../../../../../components/POS/Pagination/Pagination';
import TreatButton from '../../../../../components/Shared/TreatButton/TreatButton';
import { VoidOrderModal } from '../../../../../components/POS/Modals/VoidOrder/VoidOrder';
import Empty from '../../../../../components/Shared/Empty/Empty';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
  code: string;
}

export interface OpenOrdersDataProps {
  id: string;
  orderNumber: string;
  orderItems: Array<Partial<Product>>;
  createdAt: number;
  updatedAt?: number;
  customer: string;
  staff: string;
  orderNote: string;
  orderType: OrderTypeDisplayProps;
  payTypes: Array<PaymentTypeDisplay>;
  totalValue: number;
  table: Table;
  tokenNumber?: string;
}

export interface OpenOrdersViewProps {
  data: OpenOrdersDataProps[];
  orders: Record<string, Order>;
  onSelectOrder?: (id: string) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  onPressRePrintDocket: (orderId: string) => void;
  onVoidOrder: () => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const OpenOrdersTable: React.FC<OpenOrdersViewProps> = ({
  data,
  orders,
  onSelectOrder,
  onPressPrintReceipt,
  onPressRePrintDocket,
  onVoidOrder: voidOrderCallback,
  currentPage,
  setCurrentPage,
}: OpenOrdersViewProps) => {
  const {
    setCartParams,
    updateCart,
    resetCart,
    clearPriorPendingEvents,
    closeOrderCart,
  } = useCart();
  const [session] = useSession();
  const { translate } = useTranslation();
  const { canI } = usePOSUserAuthorization();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { width: vw } = theme.useResponsiveDimensions();
  const { syncItemAvailability } = useSyncItemAvailability();
  const { getProductsFromCache, updateCacheProductQuantities } = useProducts(
    undefined,
    inventoryProductFragment,
  );

  const currentStoreId = session.currentStore?.id ?? '';
  const { defaultOrderType } = session.deviceProfile || {};

  const onPressNote = useCallback(
    (orderNo: string, note: string) => {
      showModal(
        <View style={styles.noteContainer}>
          <View style={styles.note}>
            <Text style={styles.noteTitle}>{`${translate(
              'orderHistory.order',
            )} ${translate('moneyMovements.note')}: ${orderNo}`}</Text>
            <Text>{note}</Text>
          </View>
          <TreatButton
            testID="btn-closeNote"
            type="cancel"
            onPress={closeModal}
            label={translate('button.dismiss')}
          />
        </View>,
      );
    },
    [closeModal, showModal, translate],
  );

  const onVoidOrder = useCallback(
    async (reason: VoidReason, description: string, selectedOrder: Order) => {
      const order = orders[selectedOrder.id];

      const isAnyPaymentCompleted = (order?.payments || []).some(
        x => x.status == OrderPaymentStatus.COMPLETE && !x.isPrePayment,
      );

      if (isAnyPaymentCompleted) {
        return showNotification({
          error: true,
          message: translate('order.voidPartiallyPaid'),
        });
      }

      const allProducts = cloneJSON(keyBy(getProductsFromCache(), 'id'));
      const productQuantities = productQuantitiesToReturnOnVoidOrder(
        allProducts,
        order,
        currentStoreId,
      );

      if (Object.keys(productQuantities).length) {
        const updatedProducts = updateCacheProductQuantities(
          productQuantities,
          currentStoreId,
        );
        syncItemAvailability(updatedProducts);
      }

      clearPriorPendingEvents();
      await updateCart<VoidOrderEvent>(OrderAction.ORDER_VOID, {
        reason,
        description,
        productQuantities: Object.keys(productQuantities).map(key => {
          return { id: key, quantity: productQuantities[key] };
        }),
        inventoryTracked: !!Object.keys(productQuantities).length,
      });

      resetCart();
      voidOrderCallback();
      await closeOrderCart();
    },
    [
      clearPriorPendingEvents,
      closeOrderCart,
      currentStoreId,
      getProductsFromCache,
      orders,
      resetCart,
      showNotification,
      syncItemAvailability,
      translate,
      updateCacheProductQuantities,
      updateCart,
      voidOrderCallback,
    ],
  );

  const onPressVoid = useCallback(
    (orderId: string) => {
      const order = data.find(order => order.id === orderId);
      if (order) {
        const allowVoidOrder = canI([{ onResource: Resource.VOID_ORDERS }], {
          prompt: true,
        });
        if (!allowVoidOrder) return;
        setCartParams(order.id, defaultOrderType?.id, undefined, true);
        showModal(
          <VoidOrderModal
            order={order as unknown as Order}
            onCancel={onVoidOrder}
          />,
        );
      }
    },
    [data, canI, setCartParams, defaultOrderType?.id, showModal, onVoidOrder],
  );

  const pageItems = useMemo(() => {
    return data?.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [data, currentPage]);

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.cellPlacedAt]}>
          {translate('openOrders.placedAt')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cell100]}>
          {translate('backOfficeSalesFeed.rowPreview.orderNumber')}
        </Text>
        {vw >= 1280 ? (
          <Text style={[theme.tables.headerText, styles.cell100]}>
            {translate('openOrders.user')}
          </Text>
        ) : null}
        <Text style={[theme.tables.headerText, styles.cellName]}>
          {translate('openOrders.orderName')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellNote]}>
          {translate('openOrders.notes')}
        </Text>
        {vw >= 1280 ? (
          <Text style={[theme.tables.headerText, styles.cellLastAction]}>
            {translate('openOrders.lastAction')}
          </Text>
        ) : null}
        <Text style={[theme.tables.headerText, styles.cellAmount]}>
          {translate('openOrders.amount')}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {data.length > 0 ? (
          pageItems.map((order: OpenOrdersDataProps, i: number) => (
            <OpenOrderRow
              key={`${i}-${order.id}`}
              order={order}
              onPress={onSelectOrder}
              onPressVoid={onPressVoid}
              onPressNote={onPressNote}
              onPressPrintReceipt={onPressPrintReceipt}
              onPressPrintDocket={onPressRePrintDocket}
            />
          ))
        ) : (
          <Empty label={translate('openOrders.empty')} />
        )}
      </View>
      {data.length > 10 ? (
        <Pagination
          page={currentPage}
          dataLength={data.length}
          pageLength={pageItems.length}
          onPageChange={setCurrentPage}
        />
      ) : null}
    </View>
  );
};
export default OpenOrdersTable;
