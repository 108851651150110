import React, { useEffect } from 'react';
import { useSyncOrderEvents } from './hooks/app/useSyncOrderEvents';
import { useAppState } from '@react-native-community/hooks';
import { AuthState } from './constants';
import { useNetworkStatus } from './hooks/app/useNetworkStatus';
import { usePreviousValue } from '@oolio-group/hooks';
import { useTimeout } from './hooks/useTimeout';

export interface OrderSyncEffectProps {
  authState: AuthState;
}

const OrderSyncEffect: React.FC<OrderSyncEffectProps> = ({
  authState,
}: OrderSyncEffectProps) => {
  const { syncAllOrderEvents } = useSyncOrderEvents();
  const { isConnected } = useNetworkStatus();
  const previousNetworkState = usePreviousValue(isConnected);
  const syncOrderEventsWithDelay = useTimeout(syncAllOrderEvents);

  const currentAppState = useAppState();

  useEffect(() => {
    if (
      authState === AuthState.LOGGED_IN &&
      currentAppState == 'active' &&
      !previousNetworkState &&
      isConnected
    ) {
      syncOrderEventsWithDelay.start(1000);
    }
  }, [
    currentAppState,
    authState,
    syncAllOrderEvents,
    isConnected,
    previousNetworkState,
    syncOrderEventsWithDelay,
  ]);

  return <></>;
};

export default OrderSyncEffect;
