import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { View, Text } from 'react-native';
import {
  DEFAULT_PRICING_GROUP,
  PricingGroup as PricingGroupDefault,
} from '@oolio-group/domain';
import { useNotification } from '../../../../hooks/Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { usePricingGroups } from '../../../../hooks/app/usePricingGroups';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { Operation } from '../../../../types/Operation';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import Search from '../../../../components/Shared/Search/Search';
import CreatePriceListModal from './CreatePriceList/CreatePriceListModal';
import theme, { DEFAULT_PAGE_SIZE } from '../../../../common/default-theme';
import styles from './PriceLists.styles';
import PriceListRow from './PriceListRow';
import Pagination from '../../../../components/Office/Pagination/Pagination';

interface PricingGroup extends PricingGroupDefault {
  isSelected?: boolean;
  isChanged?: boolean;
}

export const PriceLists: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [priceListsData, setPriceListsData] = useState<
    Record<string, PricingGroup>
  >({});

  const {
    pricingGroups,
    defaultPricingGroup,
    error,
    loading,
    operation,
    updatePricingGroups,
    clonePricingGroup,
    getAllPricingGroups,
    createPricingGroup,
    createdId,
  } = usePricingGroups();

  useEffect(() => {
    if (isFocused) {
      getAllPricingGroups();
    }
  }, [isFocused, getAllPricingGroups]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.DELETE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupsDeletedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.UPDATE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupsSavedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.CREATE && createdId) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingCreatedSuccessfully'),
      });
      navigation.navigate('PriceListSettings', {
        pricingGroupId: createdId,
        isNavigatedFromCreate: true,
      });
    }
  }, [
    loading,
    operation,
    closeModal,
    showNotification,
    translate,
    error,
    createdId,
    navigation,
  ]);

  useEffect(() => {
    if (!error && !loading && pricingGroups) {
      setPriceListsData(pricingGroups);
    }
  }, [error, loading, pricingGroups]);

  const priceListsArray = useMemo(() => {
    if (defaultPricingGroup) {
      const data = Object.values(priceListsData || {}).filter(
        obj => obj.id !== defaultPricingGroup?.id,
      );
      [defaultPricingGroup, ...data] as PricingGroup[];
      return [defaultPricingGroup, ...data] as PricingGroup[];
    } else {
      return Object.values(priceListsData || {}) as PricingGroup[];
    }
  }, [priceListsData, defaultPricingGroup]);

  const filteredList = useMemo(() => {
    const filtered = priceListsArray.filter(list =>
      list.name.toLowerCase().includes(searchString.toLowerCase()),
    );

    return filtered.sort((a, b) => {
      // Sorts list so that Standard price list is always shown first
      if (a.name === DEFAULT_PRICING_GROUP) return -1;
      if (b.name === DEFAULT_PRICING_GROUP) return 1;
      return a.name.localeCompare(b.name);
    });
  }, [priceListsArray, searchString]);

  const pageItems = useMemo(() => {
    return filteredList.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [filteredList, currentPage]);

  const onPressCreate = useCallback(() => {
    showModal(<CreatePriceListModal onCreate={createPricingGroup} />);
  }, [createPricingGroup, showModal]);

  const onPressCopy = useCallback(
    (id: string): void => {
      if (id && pricingGroups[id]) {
        const selectedPricingGroup = pricingGroups[id];
        showModal(
          <ConfirmationDialog
            type="neutral"
            title={translate('pricings.copyPricingGroup')}
            message={translate('pricings.pricingGroupCopyDescription', {
              name: selectedPricingGroup.name,
            })}
            onConfirm={() => {
              clonePricingGroup({
                id: selectedPricingGroup.id,
              });
            }}
            confirmLabel={translate('pricings.copy')}
            onCancel={() => closeModal()}
          />,
        );
      }
    },
    [pricingGroups, showModal, translate, closeModal, clonePricingGroup],
  );

  const onPressStatusChange = useCallback(
    (id: string, status: boolean) => {
      showModal(
        <ConfirmationDialog
          title={
            status
              ? translate('pricings.enable')
              : translate('pricings.disable')
          }
          message={
            status
              ? translate('pricings.enablePricingGroup')
              : translate('pricings.disablePricingGroup')
          }
          onConfirm={() => {
            closeModal();
            updatePricingGroups([{ id, isActive: status }]);
          }}
        />,
      );
    },
    [showModal, , updatePricingGroups, closeModal, translate],
  );

  return (
    <ScreenLayout hideFooter loading={loading} title="Price Lists | Oolio">
      <Section
        title={translate('pricings.pricingGroups')}
        subtitle={translate('pricings.pricingGroupsDescription')}
        layoutWidth="medium"
      >
        <View style={styles.filters}>
          <Search
            testID="search-priceLists"
            value={searchString}
            onChangeText={setSearchString}
            placeholder={translate('pricings.priceListsSearchPlaceholder')}
            containerStyle={styles.search}
          />
          <CreateButton
            onPress={onPressCreate}
            containerStyles={styles.btnCreate}
          />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('form.name')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerProducts]}>
              {translate('backOfficeProducts.products')}
            </Text>
          </View>
          <View>
            {pageItems.length > 0 ? (
              pageItems.map((list: PricingGroup, i: number) => (
                <PriceListRow
                  key={i}
                  list={list}
                  onPressStatusChange={onPressStatusChange}
                  onPressCopy={onPressCopy}
                />
              ))
            ) : (
              <View style={theme.tables.emptyView}>
                <Text testID="empty-text" style={theme.tables.emptyText}>
                  {translate('pricings.noPricingGroupsMessage')}
                </Text>
              </View>
            )}
          </View>
        </View>
        <Pagination
          page={currentPage}
          onPageChange={setCurrentPage}
          dataLength={filteredList.length}
          entityName={translate('pricings.priceListsName')}
        />
      </Section>
    </ScreenLayout>
  );
};
