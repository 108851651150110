import gql from 'graphql-tag';

const SHIFT_FRAGMENT = `
  id
  createdAt
  closedAt
  createdBy {
    id
    name
  }
  closedBy {
    id
    name
  }
  createdByDevice {
    id
    name
  }
  closedByDevice {
    id
    name
  }
  store {
    id
    name
    showHourlySplit
  }
  shiftNumber
  shiftStatus
  shiftType
  totalSalesCount
  totalSales
  refundCount
  avgSalesPerHour
  refundItemsCount
  totalRefund
  transactionsCount
  totalSalesAmount
  totalAmount
  totalDiscount
  totalSurcharge
  totalRecorded
  totalEstimatedFees
  totalPaymentSurcharge
  totalNetDeposit
  movedMoney
  moneyIn
  moneyOut
  otherCharges
  salesByHour {
    hour
    amount
    count
  }
  onAccountSales {
    Sales {
      customer {
        id
        name
      }
      amount
    }
    Payments {
      customer {
        id
        name
      }
      paymentType {
        id
        name
      }
      amount
    }
  }
  salesByPaymentType {
    paymentType {
      id
      name
    }
    amount
    salesCount
    refundsCount
    refundAmount
    totalCounted
    variance
    roundingAmount
    recordedAmount
    movedMoney
    moneyIn
    moneyInCount
    moneyOut
    moneyOutCount
    paymentSurcharge
    estimatedFees
    netDeposit
    tips
  }
  salesAverage
  taxes {
    tax {
      id
      name
      rate
      code
    }
    amount
  }
  totalCounted
  difference
  orderDiscount
  totalGrossSales
  totalGrossRefund
  totalRefundByAmount
  totalNetAmount
  totalNetRevenue
  totalTax
  totalCostPrice
  totalRoundingAmount
  profit
  profitPercentage
  customers {
    id
    count
  }
  salesByProductType {
    productType {
      id
      name
    }
    amount
    count
    isComboReportingGroup
  }
  tips
  voidedAmountByReason {
    reason
    amount
  }
  voidedItemsCount
  totalVoidAmount
  balancePayment
  discountByReward {
    quantity
    amount
    reward {
      id
      rewardName
    }
  }
`;

export const GET_SHIFTS = gql`
  query Shifts($input: ShiftInput) {
    shifts(input: $input) {
      ${SHIFT_FRAGMENT}
    }
  }
`;

export const CLOSE_SHIFT = gql`
  mutation closeShift($input: CloseShiftInput!) {
    closeShift(input: $input) {
      ${SHIFT_FRAGMENT}
    }
  }
`;

export const GET_SHIFTS_REPORT = gql`
  query Shifts($input: ShiftInput) {
    shifts(input: $input) {
      ${SHIFT_FRAGMENT}
      venue {
        id
        name
      }
    }
  }
`;

export const GET_SHIFTS_DIFFERENCE = gql`
  query ShiftsDifference($input: ShiftInput) {
    shifts(input: $input) {
      difference
    }
  }
`;
