import { useQuery } from '@apollo/client/react/hooks';
import { GET_PAYMENT_TYPES_QUERY } from '../../graphql/settings';
import { parseApolloError } from '../../utils/errorHandlers';
import { PaymentType } from '@oolio-group/domain';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { useNetworkStatus } from './useNetworkStatus';

interface UsePaymentTypesInput {
  fetchPolicy?: WatchQueryFetchPolicy;
}
export function usePaymentTypes(input?: UsePaymentTypesInput): {
  paymentTypes: Array<PaymentType>;
  refetch: () => void;
  status: {
    loading: boolean;
    error: string | undefined;
  };
} {
  const { isConnected } = useNetworkStatus();

  const { data, error, loading, refetch } = useQuery(GET_PAYMENT_TYPES_QUERY, {
    fetchPolicy:
      input?.fetchPolicy || (isConnected ? 'cache-and-network' : 'cache-first'),
  });

  return {
    paymentTypes: data?.paymentTypes || [],
    refetch,
    status: {
      loading,
      error: error ? parseApolloError(error) : undefined,
    },
  };
}
