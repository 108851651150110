/* eslint-disable react-native/no-inline-styles */
import React, { useState, useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import {
  startOfDay,
  endOfDay,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
} from 'date-fns';
import { PRESETS } from '../../../../utils/dateHelper';
import styles from './DateRangePicker.styles';
import SelectDate from '../../../Shared/Select/SelectDate';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import { paramCase } from 'change-case';
import ModalTitle from '../ModalTitle/ModalTitle';

export interface DateRangePickerProps {
  value?: { start: Date; end: Date };
  onSelectDate: (start: Date, end: Date) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onSelectDate: onSelectCustomDates,
}: DateRangePickerProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(
    startOfDay(value?.start || new Date()),
  );
  const [endDate, setEndDate] = useState<Date>(
    endOfDay(value?.end || new Date()),
  );

  const onPressCustomDates = useCallback(() => {
    onSelectCustomDates(startDate, endDate);
    closeModal();
  }, [closeModal, endDate, onSelectCustomDates, startDate]);

  useEffect(() => {
    if (value?.start || value?.end) {
      setStartDate(startOfDay(value.start));
      setEndDate(endOfDay(value.end));
    }
  }, [value?.start, value?.end]);

  const onPressPreset = useCallback(
    (preset: string) => {
      const today = startOfDay(new Date());
      switch (preset) {
        case PRESETS.TODAY:
          onSelectCustomDates(today, endOfDay(today));
          break;
        case PRESETS.YESTERDAY:
          const yesterday = subDays(today, 1);
          onSelectCustomDates(startOfDay(yesterday), endOfDay(yesterday));
          break;
        case PRESETS.THIS_WEEK:
          const thisWeekStart = startOfWeek(today, { weekStartsOn: 1 });
          const thisWeekEnd = endOfWeek(today, { weekStartsOn: 1 });
          onSelectCustomDates(thisWeekStart, thisWeekEnd);
          break;
        case PRESETS.LAST_WEEK:
          const lastWeekStart = startOfWeek(subDays(today, 7), {
            weekStartsOn: 1,
          });
          const lastWeekEnd = endOfWeek(subDays(today, 7), { weekStartsOn: 1 });
          onSelectCustomDates(lastWeekStart, lastWeekEnd);
          break;
        case PRESETS.THIS_MONTH:
          const thisMonthStart = startOfMonth(today);
          const thisMonthEnd = endOfMonth(today);
          onSelectCustomDates(thisMonthStart, thisMonthEnd);
          break;
        case PRESETS.LAST_MONTH:
          const lastMonthStart = startOfMonth(subMonths(today, 1));
          const lastMonthEnd = endOfMonth(subMonths(today, 1));
          onSelectCustomDates(lastMonthStart, lastMonthEnd);
          break;
        case PRESETS.THIS_YEAR:
          const thisYearStart = startOfYear(today);
          const thisYearEnd = endOfYear(today);
          onSelectCustomDates(thisYearStart, thisYearEnd);
          break;
        case PRESETS.LAST_YEAR:
          const lastYearStart = startOfYear(subYears(today, 1));
          const lastYearEnd = endOfYear(subYears(today, 1));
          onSelectCustomDates(lastYearStart, lastYearEnd);
          break;
        default:
          onSelectCustomDates(startDate, endDate);
          break;
      }
      closeModal();
    },
    [closeModal, endDate, onSelectCustomDates, startDate],
  );

  return (
    <View style={styles.container}>
      <ModalTitle title={translate('form.dateRangeTitle')} />
      <View style={styles.content}>
        <View style={styles.custom}>
          <SelectDate
            testID="date-start"
            title={translate('backOfficeReports.header.startDate')}
            valueDate={startDate}
            onChangeDate={date => {
              if (date !== undefined) {
                setStartDate(date);
              }
            }}
            containerStyle={[styles.inputDate, { zIndex: 4 }]}
          />
          <SelectDate
            testID="date-end"
            title={translate('backOfficeReports.header.endDate')}
            valueDate={endDate}
            onChangeDate={date => {
              if (date !== undefined) {
                setEndDate(date);
              }
            }}
            containerStyle={[styles.inputDate, { zIndex: 3 }]}
          />
          <TreatButton
            testID="btn-submit"
            type="positive"
            onPress={onPressCustomDates}
            label={translate('button.applyChanges')}
          />
        </View>
        <View style={styles.presets}>
          {Object.values(PRESETS).map((preset, i) => {
            const id = `btn-preset-${paramCase(preset)}`;

            return (
              <TreatButton
                key={id}
                testID={id}
                label={preset}
                type="neutralLight"
                onPress={(): void => onPressPreset(preset)}
                containerStyle={[
                  styles.btnPreset,
                  { marginRight: i % 2 === 0 ? 10 : 0 },
                ]}
              />
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default DateRangePicker;
