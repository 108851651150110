import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../../common/default-theme';

const textStyle: TextStyle = {
  fontSize: 14,
  lineHeight: 20,
  color: theme.colors.black1,
  fontFamily: theme.fonts.regular,
  fontVariant: ['tabular-nums'],
  marginRight: 20,
};

const styles = StyleSheet.create({
  container: {
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    paddingLeft: 12,
  },
  cellAge: {
    minWidth: 60,
    maxWidth: 60,
    marginHorizontal: 10,
  },
  cellPlacedAt: {
    width: 124,
    marginRight: 20,
    ...textStyle,
  },
  cell100: {
    width: 100,
    marginRight: 20,
    ...textStyle,
  },
  cellName: {
    flex: 1,
    marginRight: 20,
    ...textStyle,
  },
  cellNote: {
    flex: 1,
    marginRight: 20,
    ...textStyle,
  },
  cellLastAction: {
    width: 100,
    ...textStyle,
    textAlign: 'right',
    marginRight: 20,
  },
  cellAmount: {
    width: 74,
    ...textStyle,
    textAlign: 'right',
    fontVariant: ['tabular-nums'],
    marginRight: 20,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
  },
});

export default styles;
