import React, { useCallback } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import styles from './TransferSuccess.styles';
import Icon from '../../../Icon/Icon';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import { OrderItem, Section } from '@oolio-group/domain';
import CartProduct from '../../Cart/CartProduct/CartProduct';
import { useSession } from '../../../../hooks/app/useSession';
import { keyBy } from 'lodash';

export interface TransferSuccessProps {
  items?: OrderItem[];
  onResend?: () => void;
  onClose?: () => void;
  sourceTable?: string;
  targetTable?: string;
}

const TransferSuccess: React.FC<TransferSuccessProps> = ({
  items = [],
  onResend,
  onClose,
  sourceTable = '',
  targetTable = '',
}: TransferSuccessProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [{ deviceProfile }] = useSession();

  const allTablesMap = keyBy(
    deviceProfile?.sections?.flatMap((section: Section) => section?.tables) ??
      [],
    'id',
  );

  const fromTable = allTablesMap[sourceTable]?.name ?? '--';
  const toTable = allTablesMap[targetTable]?.name ?? '--';

  const onPressDismiss = useCallback(() => {
    onClose && onClose();
    closeModal();
  }, [closeModal, onClose]);

  const onPressResend = useCallback(() => {
    closeModal();
    onResend && onResend();
  }, [closeModal, onResend]);

  return (
    <View style={styles.container}>
      <View style={styles.icon}>
        <Icon
          name="check-circle"
          size={48}
          color={theme.colors.states.positive}
        />
      </View>
      <View style={styles.title}>
        <Text style={styles.titleText}> {translate('order.transferred')}!</Text>
        <Text style={styles.subTitleText}>
          {translate('order.transferSuccessMessage', {
            fromTable,
            toTable,
          })}
        </Text>
      </View>
      <ScrollView style={styles.scrollView}>
        {items.map(item => (
          <CartProduct
            key={item.id}
            testID={'order-item'}
            orderItem={item}
            minimised
          />
        ))}
      </ScrollView>
      <TreatButton
        testID="btn-confirm"
        type={'neutral'}
        label={'Resend Kitchen Dockets'}
        onPress={onPressResend}
        containerStyle={styles.button}
      />
      <TreatButton
        testID="btn-dismiss"
        type="cancel"
        label={'Dismiss'}
        onPress={onPressDismiss}
        containerStyle={styles.button}
      />
    </View>
  );
};

export default TransferSuccess;
