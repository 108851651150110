import { Platform } from 'react-native';
import { ApolloStorageWrapper } from './ApolloStorageWrapper';
import { MMKVStorageWrapper } from 'apollo3-cache-persist';
import { storageInterface } from './mmkv';
import Database from './database';

export const getPreferredApolloStorage = async () => {
  switch (Platform.OS) {
    case 'android':
      return new MMKVStorageWrapper(storageInterface);
    default:
      const dbInstance = await Database.init();
      return new ApolloStorageWrapper(dbInstance);
  }
};
