import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';

import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import {
  GET_DEVICE_BY_ID_QUERY,
  GET_DEVICES_BY_STORE_QUERY,
  CREATE_DEVICE,
  UPDATE_DEVICE,
  UPDATE_DEVICE_TOKEN,
  DELETE_DEVICE,
  RESET_DEVICE,
} from '../../graphql/devices';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import {
  Device,
  UpdateDeviceInput,
  CreateDeviceInput,
  CreateOrUpdateDevicePrinterSetupInput,
  UpdateDevicePushNotificationTokenInput,
} from '@oolio-group/domain';
import { ApolloError } from '@apollo/client';
import { useApolloClient } from '@apollo/client/react/hooks';
import keyBy from 'lodash/keyBy';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { CREATE_OR_UPDATE_PRINTING_SETUP } from '../../graphql/printerTemplates';
import { useSession } from './useSession';
import isEqual from 'lodash/isEqual';
import unionBy from 'lodash/unionBy';
import pick from 'lodash/pick';
import { stripProperties } from '../../utils/stripObjectProps';
import { useSettings } from '../app/useSettings';
import { useNetworkStatus } from './useNetworkStatus';

export interface UseDevicesProps {
  loading: boolean;
  error: string | undefined;
  devices: { [key: string]: Device };
  createdDeviceId: string;
  updatedDeviceId: string;
  deletedDevice: boolean;
  resetedDeviceCode: boolean;
  getDevices: () => void;
  createDevice: (deviceCreate: CreateDeviceInput) => void;
  updateDevice: (devicesInput: UpdateDeviceInput) => void;
  updateDeviceToken: (
    deviceTokenInput: UpdateDevicePushNotificationTokenInput,
  ) => void;
  getDevicesSynchronously: (storeId?: string | undefined) => Promise<Device[]>;
  resetDeviceCode: (id: string) => void;
  deleteDevice: (id: string) => void;
  createOrUpdateDevicePrintingOptions: (
    input: CreateOrUpdateDevicePrinterSetupInput,
  ) => Promise<boolean>;
  getDeviceDataById: (deviceId?: string) => void;
}

export interface Props {
  deviceId?: string;
  storeId?: string;
  onCreateDeviceCompleted?: (id: string) => void;
}

export const useDevices = (props?: Props): UseDevicesProps => {
  const { deviceId, storeId } = props || {};
  const [devices, setDevices] = useState<Record<string, Device>>({});
  const [createdDeviceId, setCreatedDeviceId] = useState('');
  const [updatedDeviceId, setUpdatedDeviceId] = useState('');
  const [deletedDevice, setDeletedDevice] = useState(false);
  const [resetedDeviceCode, setResetedDeviceCode] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenNumber, setTokenNumber] = useSettings<number>('tokenNumber');
  const devicesRef = useRef<Record<string, Device>>({});
  const [session, setSession] = useSession();
  const client = useApolloClient();
  const { isConnected } = useNetworkStatus();

  // Update device in session
  const updateDeviceInSession = useCallback(
    (devices: { [key: string]: Device }) => {
      try {
        const updatedSession = { ...session };
        if (
          updatedSession.currentStore &&
          updatedSession.currentStore?.id === storeId
        ) {
          updatedSession.currentStore.devices = unionBy(
            Object.values(devices),
            session.currentStore?.devices || [],
            'id',
          );
        }

        const attachedDevice = Object.values(devices).find(
          device => device.id === session.device?.id,
        );
        if (attachedDevice) {
          const updatedDeviceData = pick(devices[attachedDevice.id], [
            'id',
            'name',
            'details',
            'uuid',
            'salesPrefix',
            'returnPrefix',
            'appVersion',
            'deviceProfile',
          ]);

          updatedSession.device = {
            ...session.device,
            ...updatedDeviceData,
          };
        }

        // update device in session if updated
        setSession(updatedSession);
      } catch (error) {
        console.error(`Error while updating device in session: ${error}`);
      }
    },
    [session, setSession, storeId],
  );

  // Remove device from session if deleted
  const resetDeviceInSession = useCallback(
    (id: string): void => {
      const updatedSession = { ...session };
      if (
        updatedSession.currentStore &&
        updatedSession.currentStore?.id === storeId
      ) {
        updatedSession.currentStore.devices =
          session.currentStore?.devices?.filter(
            (device: { id: string }) => device.id !== id,
          ) || [];
      }

      if (session.device?.id === id) {
        updatedSession.device = undefined;
        updatedSession.deviceProfile = undefined;
        updatedSession.currentStore = undefined;
        updatedSession.currentVenue = undefined;
      }
      setSession(updatedSession);
    },
    [session, setSession, storeId],
  );

  // Whenever devices change call updateDeviceInSession
  useEffect(() => {
    // return if devices are not changed
    if (isEqual(devices, devicesRef.current)) return;
    devicesRef.current = devices;
    updateDeviceInSession(devices);
  }, [devices, updateDeviceInSession]);

  const updateTokenNumberInStorage = useCallback(
    (device: Device) => {
      // update current token number
      const tokenActive = device.isTokenNumberActive;
      if (tokenActive) {
        const currentToken = device.currentTokenNumber;
        const startRange = device.tokenSettings?.tokenRange?.start;
        const endRange = device.tokenSettings?.tokenRange?.end;

        if (
          Number.isInteger(currentToken) &&
          (currentToken || currentToken == 0)
        ) {
          if (
            endRange &&
            startRange &&
            (currentToken >= endRange || currentToken < startRange)
          ) {
            setTokenNumber(startRange);
          } else {
            setTokenNumber(currentToken + 1);
          }
        } else if (
          Number.isInteger(startRange) &&
          (startRange || startRange == 0)
        ) {
          setTokenNumber(startRange);
        }
      }
    },
    [setTokenNumber],
  );

  // get device
  const onCompleteGetDeviceRequest = useCallback(
    data => {
      if (data && data.device) {
        const deviceData = data.device;
        setDevices(devices => {
          const devicesTemp = { ...devices };
          devicesTemp[deviceData.id] = deviceData;
          return devicesTemp;
        });
        // Update property device inside session in case they are the same id
        if (session.device?.id === data.device.id) {
          updateTokenNumberInStorage(deviceData);
          setSession({
            ...session,
            device: { ...session.device, ...deviceData },
          });
        }
      }
    },
    [session, setSession, updateTokenNumberInStorage],
  );

  const [getDevice, getDeviceResponse] = useLazyQuery(GET_DEVICE_BY_ID_QUERY, {
    fetchPolicy: isConnected ? 'cache-and-network' : 'cache-only',
    onCompleted: onCompleteGetDeviceRequest,
    onError: noopHandler,
  });

  const getDeviceDataById = useCallback(
    (deviceId?: string) => {
      deviceId && getDevice({ variables: { id: deviceId } });
    },
    [getDevice],
  );

  // get devices
  const onCompleteGetDevicesRequest = useCallback(data => {
    if (data) {
      const devicesData = data.devices;
      if (devicesData) {
        const devicesDict: Record<string, Device> = keyBy(devicesData, 'id');
        setDevices(devicesDict);
      }
    }
  }, []);

  const [getDevices, getDevicesResponse] = useLazyQuery(
    GET_DEVICES_BY_STORE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      context: {
        headers: { store: storeId },
      },
      onCompleted: onCompleteGetDevicesRequest,
      onError: noopHandler,
    },
  );

  // device mutations
  const [createDeviceRequest, createDeviceResponse] = useMutation(
    CREATE_DEVICE,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
      onCompleted: data => {
        if (props?.onCreateDeviceCompleted) {
          props.onCreateDeviceCompleted(data.createDevice.id);
        }
      },
    },
  );

  // delete device
  const onCompleteDeleteDeviceRequest = useCallback(
    (deviceId, data) => {
      if (deviceId && data?.deleteDevice) {
        resetDeviceInSession(deviceId);
      }
    },
    [resetDeviceInSession],
  );

  const onCompleteResetDeviceRequest = useCallback(
    (deviceId, data) => {
      if (deviceId && data?.resetDeviceCode) {
        resetDeviceInSession(deviceId);
      }
    },
    [resetDeviceInSession],
  );

  const [deleteDeviceRequest, deleteDeviceResponse] = useMutation(
    DELETE_DEVICE,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
      onCompleted: onCompleteDeleteDeviceRequest.bind(null, deviceId),
    },
  );

  const [resetDeviceRequest, resetDeviceResponse] = useMutation(RESET_DEVICE, {
    onError: noopHandler,
    context: {
      headers: { store: storeId },
    },
    onCompleted: onCompleteResetDeviceRequest.bind(null, deviceId),
  });

  const [updateDeviceRequest, updateDeviceResponse] = useMutation(
    UPDATE_DEVICE,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
    },
  );

  const [createOrUpdatePrintingSetup, createOrUpdatePrintingSetupResponse] =
    useMutation(CREATE_OR_UPDATE_PRINTING_SETUP, {
      onError: noopHandler,
    });

  const createOrUpdateDevicePrintingOptions = useCallback(
    async (input: CreateOrUpdateDevicePrinterSetupInput): Promise<boolean> => {
      const response = await createOrUpdatePrintingSetup({
        variables: {
          input,
        },
      });
      // formatted response
      if (
        response?.data &&
        response?.data?.createOrUpdateDevicePrintingOptions
      ) {
        setDevices(_devices => ({
          ..._devices,
          [input.deviceId]: {
            ..._devices[input.deviceId],
            printingOptions: response.data.createOrUpdateDevicePrintingOptions,
          },
        }));
        return true;
      }
      return false;
    },
    [createOrUpdatePrintingSetup],
  );

  useEffect(() => {
    if (deviceId) {
      getDevice({ variables: { id: deviceId } });
    }
  }, [getDevice, deviceId]);

  // update
  useEffect(() => {
    if (updateDeviceResponse.data) {
      const deviceData = updateDeviceResponse.data.updateDevice as Device;
      setDevices(devices => {
        const devicesTemp = { ...devices };
        devicesTemp[deviceData.id] = deviceData;
        return devicesTemp;
      });
      setUpdatedDeviceId(deviceData.id);
    }
  }, [updateDeviceResponse.data]);

  const [updateDeviceTokenRequest, updateDeviceTokenResponse] = useMutation(
    UPDATE_DEVICE_TOKEN,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
    },
  );

  useEffect(() => {
    if (updateDeviceTokenResponse.data) {
      const deviceData = updateDeviceTokenResponse.data
        .updateDevicePushNotificationToken as Device;
      setDevices(devices => {
        const devicesTemp = { ...devices };
        devicesTemp[deviceData.id] = deviceData;
        return devicesTemp;
      });
      setUpdatedDeviceId(deviceData.id);
    }
  }, [updateDeviceTokenResponse.data]);

  const updateDevice = useCallback(
    (device: UpdateDeviceInput) => {
      setUpdatedDeviceId('');
      updateDeviceRequest({
        variables: {
          input: device,
        },
      });
    },
    [updateDeviceRequest],
  );

  // delete
  useEffect(() => {
    if (deleteDeviceResponse.data) {
      setDeletedDevice(deleteDeviceResponse.data.deleteDevice);
    }
  }, [deleteDeviceResponse.data]);

  // reset
  useEffect(() => {
    if (resetDeviceResponse.data) {
      setResetedDeviceCode(true);
    }
  }, [resetDeviceResponse, resetDeviceResponse.data]);

  // create
  useEffect(() => {
    if (createDeviceResponse.data) {
      const deviceData = stripProperties(
        createDeviceResponse.data.createDevice as Device,
        '__typename',
      );
      setDevices(devices => {
        return { ...devices, [deviceData.id]: deviceData };
      });
      setCreatedDeviceId(deviceData.id);
    }
  }, [createDeviceResponse.data]);

  const createDevice = useCallback(
    (deviceInput: CreateDeviceInput) => {
      createDeviceRequest({
        variables: {
          input: deviceInput,
        },
      });
    },
    [createDeviceRequest],
  );

  const deleteDevice = useCallback(
    (id: string) => {
      deleteDeviceRequest({
        variables: {
          id,
        },
      });
    },
    [deleteDeviceRequest],
  );

  const resetDeviceCode = useCallback(
    (id: string) => {
      resetDeviceRequest({
        variables: {
          input: { deviceId: id },
        },
      });
    },
    [resetDeviceRequest],
  );

  const updateDeviceToken = useCallback(
    (deviceTokenInput: UpdateDevicePushNotificationTokenInput) => {
      setUpdatedDeviceId('');
      updateDeviceTokenRequest({
        variables: {
          input: deviceTokenInput,
        },
      });
    },
    [updateDeviceTokenRequest],
  );

  const getDevicesSynchronously = useCallback(
    async (store?: string) => {
      return (
        (
          await client.query<{ devices: Device[] }>({
            query: GET_DEVICES_BY_STORE_QUERY,
            context: {
              headers: { store: store || storeId },
            },
          })
        )?.data?.devices || []
      );
    },
    [client, storeId],
  );

  const RESPONSES = [
    getDevicesResponse,
    getDeviceResponse,
    updateDeviceResponse,
    deleteDeviceResponse,
    resetDeviceResponse,
    createDeviceResponse,
    updateDeviceTokenResponse,
    createOrUpdatePrintingSetupResponse,
  ];

  const error: ApolloError | undefined = getError(RESPONSES);
  const loading: boolean = isLoading(RESPONSES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      devices,
      createdDeviceId,
      updatedDeviceId,
      deletedDevice,
      resetedDeviceCode,
      getDevicesSynchronously,
      getDevices,
      createDevice,
      updateDevice,
      updateDeviceToken,
      deleteDevice,
      resetDeviceCode,
      createOrUpdateDevicePrintingOptions,
      getDeviceDataById,
    }),
    [
      loading,
      error,
      devices,
      createdDeviceId,
      updatedDeviceId,
      deletedDevice,
      resetedDeviceCode,
      getDevices,
      getDevicesSynchronously,
      createDevice,
      updateDevice,
      updateDeviceToken,
      deleteDevice,
      resetDeviceCode,
      createOrUpdateDevicePrintingOptions,
      getDeviceDataById,
    ],
  );
};
