import {
  NotificationMode,
  NotificationType,
  OrderAction,
  OrderPaymentStatus,
  OrderType,
} from '@oolio-group/domain';
import { Order } from '@oolio-group/domain';
import { OrderHistoryItem } from '../screens/POS/Orders/OpenOrders/OpenOrders.types';
import { Session } from '../state/Session';
import { userUtility } from '../state/userUtility';
import { OrderTypesMap, createOrderTypesMap } from './orderTypesHelper';
import { v4 as uuidv4 } from 'uuid';
import { IN_COMPLETE_PAYMENT_STATUS_LIST } from '../types/Common';
import { getOrderIdentifier } from '../utils/customerHelper';
import { disableTokenNumberForOrderType } from './tokenNumber';

export const mapOrderToOrderHistoryView = (
  obj: Order,
  orderTypes: OrderTypesMap,
): OrderHistoryItem => {
  const orderType = (obj.orderType || {}) as unknown as OrderType;
  const customerName = getOrderIdentifier(obj);
  return {
    id: obj.id,
    status: obj.status,
    orderNumber: obj.orderNumber,
    createdAt: obj.createdAt,
    completedAt: obj.updatedAt || 0,
    customer: obj.customer,
    customerName: customerName,
    orderType: {
      ...orderType,
      colorId: orderTypes[orderType.id]?.colorId || 0,
      code: orderTypes[orderType.id]?.code || orderType.code || '',
    },
    payTypes: obj.payments
      .filter(
        payment =>
          !IN_COMPLETE_PAYMENT_STATUS_LIST.includes(
            payment.status as OrderPaymentStatus,
          ),
      )
      .map(payment => {
        return {
          id: payment?.paymentType?.id,
          name: payment?.paymentType?.name,
          amount: payment?.amount || 0,
        };
      }),
    totalValue: obj.totalPaymentAmount + (obj?.roundingAmount || 0),
    table: obj.table,
    refundOf: obj.refundOf ? obj.refundOf : undefined,
    tokenNumber:
      Number.isInteger(obj.tokenNumber) &&
      !disableTokenNumberForOrderType(obj.orderType)
        ? obj.tokenNumber?.toString()
        : '',
  };
};

export const sendOrderReceipt = async (
  order: Order,
  email: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  syncEventsCallback: any,
  session?: Session,
) => {
  if (session && order?.id) {
    const event = {
      action: OrderAction.CUSTOMER_NOTIFICATION,
      orderId: order.id,
      id: uuidv4(),
      notificationType: NotificationType.SEND_RECEIPT,
      notificationMode: NotificationMode.EMAIL,
      organizationId: session.currentOrganization?.id || '',
      venueId: session.currentVenue?.id || '',
      storeId: session.currentStore?.id || '',
      deviceId: session.device?.id || '',
      timestamp: new Date().getTime(),
      triggeredBy: userUtility.userActivity.posUser?.id,
      email: email,
    };
    syncEventsCallback({ variables: { input: [event] } });
  }
};

export const mapOrdersToOrdersView = (
  orders: Order[],
  orderTypes: OrderType[],
): OrderHistoryItem[] => {
  const orderTypesDict = createOrderTypesMap(orderTypes);
  return orders.map(item => mapOrderToOrderHistoryView(item, orderTypesDict));
};
