/* eslint-disable react-native/no-inline-styles */
import React, { FC, useState, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { ProductListItem } from '../types';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { formatToDigitsStr } from '@oolio-group/client-utils';
import styles from './ProductListView.styles';
import theme from '../../../../../common/default-theme';
import Icon from '../../../../../components/Icon/Icon';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import Pagination from '../../../../../components/Office/Pagination/Pagination';
import Identifier from '../../../../../components/Shared/Identifier/Identifier';
import { FilterValue } from '../ProductList';
import { canRenderVariant } from '../ProductHeaderFilters';
import useOfficeUserAuthorization from '../../../../../hooks/app/users/useOfficeUserAuthorization';
import { Resource } from '@oolio-group/domain';

export interface ProductListViewProps {
  data: ProductListItem[];
  dataMapper: {
    [key: string]: ProductListItem;
  };
  selectedProducts: ProductListItem[];
  onCopy: (selectedProduct: ProductListItem) => void;
  onDelete: (itemId: string, itemName: string) => void;
  onSelectAll: () => void;
  onSelectItem: (id: string) => void;
  onChangeItem: (
    id: string,
    prop: string,
    value: string,
    variantId?: string,
  ) => void;
  page: number;
  onPageChange: (page: number) => void;
  filter: FilterValue;
}

const ProductListView: FC<ProductListViewProps> = ({
  data,
  dataMapper,
  onCopy,
  onDelete,
  onSelectAll,
  onSelectItem,
  onChangeItem,
  selectedProducts,
  page,
  onPageChange,
  filter,
}: ProductListViewProps) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { currencySymbol } = useCurrency();
  const [showVariations, setShowVariations] = useState<string>('');

  const { canI } = useOfficeUserAuthorization();
  const pageItems = useMemo(() => {
    return data
      ?.filter(x => !x.isVariantProduct)
      .slice((page - 1) * 10, page * 10);
  }, [page, data]);

  const getSelectIcon = (item: ProductListItem) => {
    if (item.isVariantProduct) {
      return 'minus-square-full';
    } else {
      return item.isSelected ? 'check-square' : 'square-full';
    }
  };

  const getSellingIcon = (item: ProductListItem) => {
    if (item.productIds) {
      const sellability: boolean[] = [];
      item.productIds.map(variantId => {
        const s = dataMapper[variantId]?.isSellable;
        if (s !== undefined) {
          sellability.push(s);
        }
      });
      if (sellability.every(x => x === true)) {
        return 'check-circle';
      } else if (sellability.every(x => x === false)) {
        return 'exclamation-circle';
      } else {
        return 'minus-circle';
      }
    } else {
      return item?.isSellable ? 'check-circle' : 'exclamation-circle';
    }
  };

  // DOM
  const renderItemRow = (item: ProductListItem, variantId?: string) => {
    return (
      <View style={styles.rowContainer}>
        <TouchableOpacity
          style={styles.checkbox}
          onPress={() => {
            onSelectItem(item.id);
            if (item.isVariant) {
              setShowVariations(item.id);
            }
          }}
          testID="btn-row-checkbox"
          disabled={item.isVariantProduct}
        >
          {!item.isVariantProduct ? (
            <Icon
              size={20}
              testID="row-checkbox"
              name={getSelectIcon(item)}
              color={
                item.isSelected
                  ? theme.colors.states.positive
                  : theme.colors.grey5
              }
            />
          ) : undefined}
        </TouchableOpacity>
        <View style={styles.nameContainer}>
          {item.isVariant && (
            <TouchableOpacity
              testID="btn-expand"
              onPress={() =>
                setShowVariations(item.id === showVariations ? '' : item.id)
              }
              style={styles.btnExpand}
            >
              <Icon
                size={20}
                color={theme.colors.accent}
                name={showVariations === item.id ? 'angle-down' : 'angle-right'}
              />
            </TouchableOpacity>
          )}
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {item.isVariantProduct && (
              <View style={{ marginLeft: 38, marginRight: 12 }}>
                <Icon
                  size={20}
                  color={theme.colors.grey5}
                  name="corner-down-right-alt"
                />
              </View>
            )}
            <InputText
              editable={false}
              testID="product-name"
              placeholder="Item Name"
              value={item['name'] as string}
              onChangeText={text =>
                onChangeItem(item.id, 'name', text, variantId)
              }
              numberOfLines={1}
              containerStyle={{
                width: item.isVariantProduct ? 202 : 240,
                marginLeft: !item.isVariant && !item.isVariantProduct ? 32 : 0,
              }}
            />
            {item.isCombo && (
              <Identifier entity="combo" containerStyles={styles.identifier} />
            )}
          </View>
        </View>
        <View
          style={[
            styles.checkbox,
            { width: 60, justifyContent: 'center', marginRight: 20 },
          ]}
        >
          <Icon
            size={20}
            name={getSellingIcon(item)}
            color={
              getSellingIcon(item) === 'check-circle'
                ? theme.colors.states.positive
                : getSellingIcon(item) === 'minus-circle'
                ? theme.colors.states.neutral
                : theme.colors.states.negative
            }
          />
        </View>
        {item.isVariant ? (
          <InputText
            editable={false}
            testID="product-price"
            placeholder={'–'}
            alignText="center"
            containerStyle={{ width: 120 }}
          />
        ) : (
          <InputText
            testID="product-price"
            label={currencySymbol}
            value={item.price}
            placeholder="0.00"
            onChangeText={value => {
              onChangeItem(
                item.id,
                'price',
                formatToDigitsStr(value, false),
                variantId,
              );
            }}
            maxLength={7}
            alignText="right"
            keyboardType="decimal-pad"
            containerStyle={{ width: 120 }}
          />
        )}
        <View style={{ flex: 1 }} />
        <TouchableOpacity
          testID="btn-edit"
          onPress={() =>
            navigation.navigate('ProductSettings', {
              title: item.name,
              productId: item.id,
              isVariant: item.isVariant,
              isVariantProduct: item.isVariantProduct,
            })
          }
          style={[styles.btn, { backgroundColor: theme.colors.blueLight }]}
        >
          <Icon name="pen" size={20} color={theme.colors.blue} />
        </TouchableOpacity>
        {!item.isVariantProduct && (
          <TouchableOpacity
            testID="btn-copy"
            onPress={() => onCopy(item)}
            style={[styles.btn, { backgroundColor: theme.colors.blueLight }]}
          >
            <Icon name="copy" size={20} color={theme.colors.blue} />
          </TouchableOpacity>
        )}
        {canI([
          { doOperations: ['update'], onResource: Resource.MANAGE_PRODUCTS },
        ]) && (
          <TouchableOpacity
            testID="btn-delete"
            onPress={() => onDelete(item.id, item.name)}
            style={[
              styles.btn,
              { backgroundColor: theme.colors.redLight, marginLeft: 20 },
            ]}
          >
            <Icon name="trash-alt" size={20} color={theme.colors.red} />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  return (
    <View style={styles.tableContainer}>
      {/* Table Header */}
      <View style={styles.headerContainer}>
        <TouchableOpacity
          testID="btn-title-checkbox"
          onPress={onSelectAll}
          style={styles.checkbox}
        >
          <Icon
            size={20}
            testID={'title-checkbox'}
            name={
              selectedProducts.length === data.length
                ? 'check-square'
                : selectedProducts.length > 0
                ? 'minus-square-full'
                : 'square-full'
            }
            color={
              selectedProducts.length === data.length
                ? theme.colors.states.positive
                : selectedProducts.length > 0
                ? theme.colors.states.neutral
                : theme.colors.grey5
            }
          />
        </TouchableOpacity>
        <Text style={[styles.headerText, styles.colTextName]}>
          {translate('backOfficeProducts.productName')}
        </Text>
        <Text style={[styles.headerText, styles.colTextSelling]}>
          {translate('backOfficeProducts.productSellingConfig')}
        </Text>
        <Text style={[styles.headerText, styles.colTextPrice]}>
          {translate('backOfficeProducts.productPrice')}
        </Text>
        <View style={{ flex: 1 }} />
      </View>
      {/* Table Row */}
      {pageItems.map((item: ProductListItem) => {
        return (
          <View key={`product-${item.id}`}>
            {!item.isVariantProduct && renderItemRow(item)}
            {/* Variant product is already rendered here, no need to be rendered
            befrore */}
            {item.isVariant &&
              showVariations === item.id &&
              item.productIds?.map((varId: string) => {
                const variantProduct = dataMapper[varId];
                if (
                  variantProduct &&
                  canRenderVariant(variantProduct, filter)
                ) {
                  return renderItemRow(variantProduct, item.id);
                }
              })}
          </View>
        );
      })}
      {/* Table Footer */}
      <Pagination
        page={page}
        onPageChange={onPageChange}
        dataLength={data.length}
        pageLength={pageItems.length}
        entityName={translate('backOfficeProducts.products')}
      />
    </View>
  );
};

export default ProductListView;
