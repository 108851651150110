/* eslint-disable react-native/no-inline-styles */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import {
  Order,
  OrderAction,
  OrderPaymentEvent,
  RefundOrderItemsEvent,
  PaymentType,
  ReceiptPrintOption,
  AssignRefundReasonEvent,
  InitiateRefundInput,
  Resource,
  DefaultPaymentTypes,
  IntegrationApps,
  FeatureIDs,
  OrderPaymentStatus,
  OrderPayment,
  RefundType,
  RefundOrderAmountEvent,
  OrderStatus,
  Icons,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { SYNC_ORDER_EVENTS } from '../../../graphql/syncEvents';
import { IMap } from '../../../screens/BackOffice/Reports/types';
import { isOolioPayRefundLoading } from '../../../state/cache';
import { DEFAULT_PAYMENT_CURRENCY } from '../../../types/Common';
import { cloneJSON } from '@oolio-group/client-utils';
import { noopHandler } from '../../../utils/errorHandlers';
import { getRoundingAmount } from '../../../utils/roundOffHelper';
import { sendOrderReceipt } from '../../../utils/OrderHistoryHelper';
import keyBy from 'lodash/keyBy';
import { useSession } from '../../../hooks/app/useSession';
import { usePayments } from '../../../hooks/app/usePayments';
import { usePrinting } from '../../../hooks/PrintingProvider';
import { Notification, useNotification } from '../../../hooks/Notification';
import { useCart } from '../../../hooks/orders/useCart';
import { GET_REFUND_ORDER_QUERY } from '../../../hooks/app/orders/graphql';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { useCachedRefundReasons } from '../../../hooks/app/refundReason/useCachedRefundReasons';
import { useIntegrationPartners } from '../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import styles from './OrderPreview.styles';
import theme from '../../../common/default-theme';
import Icon from '../../../components/Icon/Icon';
import Sticker from '../../../components/Shared/Sticker/Sticker';
import CartNote from '../../../components/POS/Cart/CartNote/CartNote';
import CartTotals from '../../../components/POS/Cart/CartTotals/CartTotals';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import CartProduct from '../../../components/POS/Cart/CartProduct/CartProduct';
import ModalPicker from '../Modals/ModalPicker/ModalPicker';
import RefundModal from '../Modals/Refunds/RefundModal';
import PaymentConfirmationModal from '../../../components/Modals/PaymentConfirmation/PaymentConfirmation';
import RefundPaymentTypesModal from '../Modals/Refunds/RefundPaymentTypes/RefundPaymentTypes';
import RefundOrderPaymentStatus from '../Modals/Refunds/RefundOrderPaymentStatus/RefundOrderPaymentStatus';
import { limitDecimalCount, sumDecimals } from '@oolio-group/order-helper';
import {
  getRemainingRefundAmount,
  mergeOrderPaymentsForCash,
} from '../../../utils/refundOrderAmountHelper';
import RefundCashConfirmationModal from '../Modals/Refunds/RefundCashConfirmationModal/RefundCashConfirmationModal';
import RefundRequestModal from '../Modals/Refunds/RefundRequestModal/RefundRequestModal';
import PaymentIcon from '../../../components/Shared/Icons/IconPayment/IconPayment';
import { useOrdersHistory } from '../../../hooks/app/orders/useOrdersHistory';
import { OrderHistoryItem } from '../../../screens/POS/Orders/OpenOrders/OpenOrders.types';
import { OnAccountItem } from '../../../screens/POS/Customers/OnAccountOrders/OnAccountOrdersTable';
import { createNewOrderStore } from '../../../store/OrderStore';
import { useNetworkStatus } from '../../../hooks/app/useNetworkStatus';

interface OrderPreviewProps {
  order: OrderHistoryItem | OnAccountItem;
  onClose: () => void;
  onCompleteRefund?: (refundOrder: Order | null) => void;
  fromOnAccount?: boolean;
  onPressPayBalance?: (order: Order) => void;
  merchantCode?: string;
  openCashDrawer?: () => Promise<void | Notification>;
}

const merchantCodeErrorMessage = {
  invalidMerchantCode: 'invalid merchant account',
  incorrectConfigureMerchantCode:
    'merchant code configured for refunds is incorrect',
};

const OrderPreview: React.FC<OrderPreviewProps> = ({
  order,
  onClose,
  onCompleteRefund,
  fromOnAccount = false,
  onPressPayBalance,
  openCashDrawer,
  merchantCode,
}) => {
  const orderIdRef = useRef<string>('');
  const refundReason = useRef<string>('Unknown');
  const refundOrderRef = useRef<Order | undefined>(undefined);
  const refundTypeRef = useRef<RefundType>();
  const refundOrderForSelectedProducts = useRef<Order>();
  const processOrderPaymentsRef = useRef<OrderPayment[]>([]);
  const cashAmountForRefundRef = useRef<number>(0);
  const orderStoreRef = useRef(createNewOrderStore());
  const originalPaymentDetailsRef = useRef<
    Pick<Order, 'payments' | 'totalPaymentAmount'> | undefined
  >(undefined);

  const [session] = useSession();
  const { printBill } = usePrinting();
  const { translate } = useTranslation();
  const safeHeight = theme.useSafeHeight();
  const { canI } = usePOSUserAuthorization();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { isConnected } = useNetworkStatus();
  const isFeatureEnabled = useCheckFeatureEnabled();

  const [disableConfirmRefundBtn, setDisableConfirmRefundBtn] =
    useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>(
    undefined as unknown as Order,
  );
  const receiptPrintOption = session.deviceProfile?.receiptPrintOption;

  const isRefundReasonsEnabled = isFeatureEnabled(FeatureIDs.REFUND_REASON);
  const orderPaymentRef = useRef<OrderPayment>();

  const [syncEvents] = useMutation(SYNC_ORDER_EVENTS, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        success: true,
        message: translate('common.sendSaleReceiptToCustomerSuccess'),
      });
    },
  });

  const [getRefundOrderReq, getRefundOrderRes] = useLazyQuery(
    GET_REFUND_ORDER_QUERY,
    {
      fetchPolicy: 'network-only',
      onError: noopHandler,
    },
  );

  const {
    error: refundReasonError,
    refundReasons,
    getRefundReasons,
  } = useCachedRefundReasons({ fetchPolicy: 'cache-first' });

  const {
    order: refundOrder,
    status: { error: orderError },
    updateCart,
    initiateRefund,
    discardChanges,
    openOrderCart,
  } = useCart(orderStoreRef.current);

  const {
    loading: isRefundLoading,
    error: oolioRefundError,
    paymentResponse: oolioRefundResponse,
    initiateRefund: initiateOolioRefund,
    resetPaymentResponse,
  } = usePayments();

  const refundPaymentStatusRef = useRef({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    verifyRefundPaymentStatus: (_orderPayment: OrderPayment, _error?: string) =>
      String,
  });

  const {
    loading: integrationLoading,
    error: integrationError,
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
  } = useIntegrationPartners();

  const { getOrderById } = useOrdersHistory();

  const currentStoreId = session?.currentStore?.id;

  const refundReasonMaps = useMemo(
    () => keyBy(refundReasons, 'id'),
    [refundReasons],
  );

  // FIXME: Using reactive to show loader for Refund api.
  // "isRefundLoading" when passed as prop is not updating the component,
  // useCallback is not updating on state dependency update.
  useEffect(() => {
    isOolioPayRefundLoading(isRefundLoading || integrationLoading);
  }, [integrationLoading, isRefundLoading]);

  const isMerchantCodeError = (
    error: string,
    type?: 'invalidMerchantCode' | 'incorrectConfigureMerchantCode',
  ) => {
    const errorMessage = error?.toLowerCase()?.trim();
    if (type) {
      return !!errorMessage.includes(merchantCodeErrorMessage[type]);
    }
    return Object.values(merchantCodeErrorMessage).some(e =>
      errorMessage.includes(e),
    );
  };

  useEffect(() => {
    if (oolioRefundError || integrationError) {
      const error = oolioRefundError || integrationError || '';
      if (isMerchantCodeError(error)) {
        const incorrectConfigureCode = isMerchantCodeError(
          error,
          'incorrectConfigureMerchantCode',
        );
        const title = incorrectConfigureCode
          ? translate('refundOrder.refundNotAllowedHeading')
          : translate('refundOrder.refundFailedModalHeading');
        const description = incorrectConfigureCode
          ? translate('refundOrder.refundNotAllowedDescription')
          : translate('refundOrder.refundFailedModalDescription');
        showModal(
          <RefundRequestModal title={title} description={description} />,
        );
      } else {
        showNotification({
          error: true,
          message: error ?? '',
        });
        closeModal();
      }
    }
  }, [
    integrationError,
    oolioRefundError,
    showNotification,
    closeModal,
    showModal,
    translate,
  ]);

  useEffect(() => {
    if (!fromOnAccount && currentStoreId) {
      getIntegrationPartnerSettings({
        appName: IntegrationApps.OOLIO_STORE,
        store: currentStoreId,
      });
    }
  }, [getIntegrationPartnerSettings, currentStoreId, fromOnAccount]);

  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);

  const onlineIntegrationSettings = currentStoreId
    ? integrationPartners[currentStoreId]?.preferences?.onlineOrdering
    : undefined;

  const ifNetworkUnavailable = useCallback(() => {
    if (!isConnected) {
      closeModal();
      showNotification({
        error: true,
        message: translate('offline.orderHistory'),
      });
      return true;
    }
    return false;
  }, [closeModal, isConnected, showNotification, translate]);

  useEffect(() => {
    if (isConnected && selectedOrder && !fromOnAccount) {
      getRefundOrderReq({
        variables: { refundOf: selectedOrder.id },
      });
    }
  }, [getRefundOrderReq, isConnected, selectedOrder, fromOnAccount]);

  useEffect(() => {
    getRefundReasons();
  }, [getRefundReasons]);

  useEffect(() => {
    if (order?.id && orderIdRef.current !== order.id) {
      async function getData() {
        const withCache = ![
          OrderStatus.ON_ACCOUNT,
          OrderStatus.REFUNDED,
        ].includes(order.status);
        const selectedOrder = await getOrderById(order.id, withCache);
        if (selectedOrder) {
          orderIdRef.current = selectedOrder.id;
          originalPaymentDetailsRef.current = {
            payments: selectedOrder.payments?.filter(
              payment =>
                payment.status === undefined ||
                payment.status === OrderPaymentStatus.COMPLETE,
            ),
            totalPaymentAmount: selectedOrder.totalPaymentAmount,
          };
          setSelectedOrder(cloneJSON(selectedOrder) as Order);
        }
        setDisableConfirmRefundBtn(true);
      }
      getData();
    }
  }, [
    getOrderById,
    getRefundOrderReq,
    order?.id,
    order?.status,
    session.currentStore?.id,
  ]);

  useEffect(() => {
    if (getRefundOrderRes.data) {
      if (getRefundOrderRes.data.refundOrder) {
        setDisableConfirmRefundBtn(true);
      } else {
        setDisableConfirmRefundBtn(false);
      }
    }
  }, [getRefundOrderRes.data, selectedOrder]);

  useEffect(() => {
    if (!isConnected && selectedOrder?.refundOf) {
      setDisableConfirmRefundBtn(false);
    }
  }, [isConnected, selectedOrder]);

  useEffect(() => {
    if (refundReasonError) {
      showNotification({
        error: true,
        message: refundReasonError,
      });
    }

    if (getRefundOrderRes.error) {
      showNotification({
        error: true,
        message: getRefundOrderRes.error.message,
      });
    }
  }, [
    getRefundOrderRes.error,
    ifNetworkUnavailable,
    orderError,
    refundReasonError,
    selectedOrder,
    showNotification,
  ]);

  const onPressPrintReceipt = useCallback(
    async (order: Order | null) => {
      closeModal();
      if (order) {
        updateCart(OrderAction.ORDER_PRINT);
        const result = await printBill(order);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [closeModal, printBill, showNotification, updateCart],
  );

  useEffect(() => {
    if (refundOrder) {
      refundOrderRef.current = refundOrder;
    }
  }, [onPressPrintReceipt, refundOrder]);

  const onPressSendRefundReceipt = useCallback(
    async (email: string) => {
      await sendOrderReceipt(
        refundOrderRef.current as unknown as Order,
        email,
        syncEvents,
        session,
      );
      closeModal();
    },
    [closeModal, session, syncEvents],
  );

  const showPromptModal = useCallback(
    savedOrder => {
      if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
        showModal(
          <PaymentConfirmationModal
            isRefundOrder={true}
            onPressPrintReceipt={onPressPrintReceipt.bind(null, savedOrder)}
            sendReceipt={onPressSendRefundReceipt}
          />,
          {
            onBackdropPress: closeModal,
          },
        );
      } else {
        closeModal();
      }
    },
    [
      closeModal,
      onPressPrintReceipt,
      onPressSendRefundReceipt,
      showModal,
      receiptPrintOption,
    ],
  );

  const onConfirmRefund = useCallback(
    (savedOrder: Order | null) => {
      openCashDrawer?.();
      setTimeout(() => {
        showPromptModal(savedOrder);
      });
    },
    [openCashDrawer, showPromptModal],
  );

  const showCashConfirmationModal = useCallback(
    (savedOrder: Order | null) => {
      const isCardOrOnlineRefund = processOrderPaymentsRef.current?.some(p =>
        [DefaultPaymentTypes.CARD, DefaultPaymentTypes.ONLINE].includes(
          p.paymentType?.name as DefaultPaymentTypes,
        ),
      );
      if (isCardOrOnlineRefund) {
        showModal(
          <RefundRequestModal
            title={translate('refundOrder.refundRequestTitle')}
            description={translate('refundOrder.refundRequestDescription')}
            iconName={Icons.CheckCircle}
            iconColor={theme.colors.states.positive}
            type="cancel"
          />,
        );
      } else if (cashAmountForRefundRef.current) {
        showModal(
          <RefundCashConfirmationModal
            amount={cashAmountForRefundRef.current}
            onConfirmRefund={() => onConfirmRefund(savedOrder)}
          />,
        );
      } else {
        showPromptModal(savedOrder);
      }
      cashAmountForRefundRef.current = 0;
      processOrderPaymentsRef.current = [];
    },
    [showModal, onConfirmRefund, showPromptModal, translate],
  );

  const onSaveOrderCompleted = useCallback(
    (savedOrder: Order | null) => {
      onCompleteRefund?.(savedOrder);
      if (receiptPrintOption === ReceiptPrintOption.AUTO) {
        onPressPrintReceipt(savedOrder);
      }
      showCashConfirmationModal(savedOrder);
    },
    [
      onPressPrintReceipt,
      onCompleteRefund,
      receiptPrintOption,
      showCashConfirmationModal,
    ],
  );

  const isOnlineOrder = useCallback(() => {
    if (
      selectedOrder &&
      (selectedOrder.isOnline ||
        selectedOrder.integrationInfo?.app === IntegrationApps.OOLIO_STORE)
    )
      return true;
    else return false;
  }, [selectedOrder]);

  const finalizeRefund = useCallback(() => {
    updateCart(
      OrderAction.ORDER_SAVE,
      undefined,
      undefined,
      onSaveOrderCompleted,
    );
    setDisableConfirmRefundBtn(true);
    orderIdRef.current = '';
    refundReason.current = 'Unknown';
    orderPaymentRef.current = undefined;
    refundTypeRef.current = undefined;
    refundOrderForSelectedProducts.current = undefined;
  }, [onSaveOrderCompleted, updateCart]);

  const processOrFinalizedRefundOrder = useCallback(
    (errorMessage?: string) => {
      if (processOrderPaymentsRef.current?.length > 1) {
        refundPaymentStatusRef.current.verifyRefundPaymentStatus(
          orderPaymentRef.current as OrderPayment,
          errorMessage,
        );
      } else {
        finalizeRefund();
      }
    },
    [finalizeRefund],
  );

  const onCompleteRefundPayment = useCallback(() => {
    if (refundOrderRef.current) {
      const orderPayment = orderPaymentRef.current;
      const paymentType = orderPayment?.paymentType as PaymentType;
      let tenderedAmount = orderPayment?.tendered || 0;
      let originalOrderPaymentRoundingAmount =
        refundTypeRef.current === RefundType.FULL
          ? orderPayment?.roundOffDifference ?? 0
          : 0;
      if (paymentType?.name === DefaultPaymentTypes.CASH) {
        const roundingAmount = +getRoundingAmount(
          session,
          paymentType,
          orderPayment,
        ).toFixed(2);
        /* Rounding scenarios
          1. For Amount and Product
          2. For Refund All
        */
        if (
          [RefundType.BY_AMOUNT, RefundType.By_PRODUCT].includes(
            refundTypeRef.current as RefundType,
          )
        ) {
          originalOrderPaymentRoundingAmount += roundingAmount;
          tenderedAmount += originalOrderPaymentRoundingAmount;
        } else {
          tenderedAmount += roundingAmount;
          if (orderPayment?.tendered !== orderPayment?.amount)
            originalOrderPaymentRoundingAmount += roundingAmount;
        }
        cashAmountForRefundRef.current += tenderedAmount;
      }

      // only refund payment surcharge for full-refund
      let totalPaymentSurcharge = 0;
      if (refundTypeRef.current === RefundType.FULL) {
        // this is a full-refund
        totalPaymentSurcharge = orderPaymentRef.current?.paymentSurcharge || 0;
      }

      const isCardOrOnlinePayment = [
        DefaultPaymentTypes.CARD,
        DefaultPaymentTypes.ONLINE,
      ].includes(paymentType.name as DefaultPaymentTypes);

      updateCart<OrderPaymentEvent>(OrderAction.ORDER_PAYMENT, {
        tendered: tenderedAmount,
        paymentTypeId: paymentType.id,
        tip: orderPaymentRef.current?.tip || 0,
        change: 0,
        roundOffDifference: originalOrderPaymentRoundingAmount,
        refundOrder: true,
        paymentTypeName: paymentType.name,
        ...(isCardOrOnlinePayment && {
          paymentStatus: OrderPaymentStatus.REFUND_IN_PROGRESS,
          paymentTransactionRef: orderPayment?.paymentTransactionRef,
        }),
        ...(refundTypeRef.current === RefundType.FULL && {
          refundType: RefundType.FULL,
        }),
        ...(orderPayment?.paymentTransactionRef && {
          paymentTransactionRef: orderPayment?.paymentTransactionRef,
        }),
        ...(orderPayment?.paymentCompletedAt && {
          paymentCompletedAt: orderPayment.paymentCompletedAt,
        }),
        ...(orderPayment?.paymentReceipt && {
          paymentReceipt: orderPayment.paymentReceipt,
        }),
        ...(orderPayment?.cardType && { cardType: orderPayment.cardType }),
        ...(totalPaymentSurcharge > 0 && {
          paymentSurcharge: totalPaymentSurcharge,
        }),
      });
      processOrFinalizedRefundOrder();
    }
  }, [session, updateCart, processOrFinalizedRefundOrder]);

  const onPressRefundPayType = useCallback(
    (orderPayment: OrderPayment) => {
      if (!ifNetworkUnavailable()) {
        const paymentType = orderPayment.paymentType.name;
        orderPaymentRef.current = { ...orderPayment };
        if (
          paymentType === DefaultPaymentTypes.CARD ||
          paymentType === DefaultPaymentTypes.ONLINE
        ) {
          const refundPayload = {
            deviceId: session.device?.deviceCode as string,
            terminalId: session.device?.paymentTerminal?.uuid as string,
            transactionRef: orderPayment?.paymentTransactionRef as string,
            transactionCompletedAt: orderPayment?.paymentCompletedAt as string,
            paymentType: orderPayment.paymentType.name,
            merchantAccount: !isOnlineOrder()
              ? merchantCode
              : onlineIntegrationSettings?.merchantCode,
            orderId: refundOrderRef.current?.refundOf,
            amount: orderPayment.tendered ?? 0,
            currency:
              session.currentOrganization?.currencyCode ||
              DEFAULT_PAYMENT_CURRENCY,
            transactionId: refundOrderRef.current?.orderNumber,
          } as InitiateRefundInput;

          initiateOolioRefund(refundPayload);
          return;
        } else {
          onCompleteRefundPayment();
        }
      }
    },
    [
      ifNetworkUnavailable,
      initiateOolioRefund,
      onCompleteRefundPayment,
      onlineIntegrationSettings?.merchantCode,
      session.device?.deviceCode,
      session.device?.paymentTerminal?.uuid,
      session.currentOrganization?.currencyCode,
      isOnlineOrder,
      merchantCode,
    ],
  );

  useEffect(() => {
    if (!isRefundLoading && oolioRefundResponse) {
      if (oolioRefundResponse.success) {
        onCompleteRefundPayment();
      } else {
        showNotification({
          error: true,
          message: oolioRefundResponse.message,
        });
        processOrFinalizedRefundOrder(oolioRefundResponse.message);
      }
      resetPaymentResponse();
    }
  }, [
    isRefundLoading,
    onCompleteRefundPayment,
    oolioRefundResponse,
    resetPaymentResponse,
    showNotification,
    translate,
    processOrFinalizedRefundOrder,
  ]);

  const getPaymentIcon = (paymentName: string, type: string) => {
    switch (paymentName) {
      case DefaultPaymentTypes.CASH:
        return <PaymentIcon.Cash />;
      case DefaultPaymentTypes.CARD:
        return <PaymentIcon.Card type={type} />;
      case DefaultPaymentTypes.ONLINE:
        return <PaymentIcon.Online type={type} />;
      default:
        return <PaymentIcon.Other />;
    }
  };

  const showPaymentStatusModal = useCallback(
    (orderPayments: OrderPayment[]) => {
      if (!ifNetworkUnavailable()) {
        processOrderPaymentsRef.current = orderPayments;
        const amountToRefund = limitDecimalCount(
          orderPayments.reduce((acc, p) => sumDecimals([acc, p.tendered]), 0),
        );
        if (refundTypeRef.current === RefundType.BY_AMOUNT) {
          updateCart<RefundOrderAmountEvent>(OrderAction.ORDER_REFUND_AMOUNT, {
            amount: amountToRefund,
          });
        }

        showModal(
          <RefundOrderPaymentStatus
            ref={refundPaymentStatusRef}
            orderPayments={orderPayments}
            onPressRefundPayType={onPressRefundPayType}
            onCompletedRefund={finalizeRefund}
            getPaymentIcon={getPaymentIcon}
          />,
          {
            onBackdropPress: closeModal,
          },
        );
      }
    },
    [
      closeModal,
      showModal,
      finalizeRefund,
      onPressRefundPayType,
      updateCart,
      ifNetworkUnavailable,
    ],
  );

  const showPaymentTypesModal = useCallback(() => {
    if (selectedOrder && originalPaymentDetailsRef.current?.payments?.length) {
      const mergedPayments = mergeOrderPaymentsForCash(
        originalPaymentDetailsRef.current?.payments ?? [],
      );
      const remainingRefundAmount = getRemainingRefundAmount(selectedOrder);

      showModal(
        <RefundPaymentTypesModal
          originalPaymentDetails={mergedPayments}
          onConfirm={orderPayments => showPaymentStatusModal(orderPayments)}
          refundType={refundTypeRef.current as RefundType}
          refundOrderForSelectedProducts={
            refundOrderForSelectedProducts.current
          }
          remainingRefundAmount={remainingRefundAmount}
          getPaymentIcon={getPaymentIcon}
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    }
  }, [closeModal, selectedOrder, showModal, showPaymentStatusModal]);

  const onSelectRefundReason = useCallback(
    (reason: string) => {
      if (!ifNetworkUnavailable()) {
        refundReason.current = refundReasonMaps[reason].name;
        updateCart<AssignRefundReasonEvent>(
          OrderAction.ORDER_ASSIGN_REFUND_REASON,
          {
            reason: refundReason.current,
          },
        );
        showPaymentTypesModal();
      }
    },
    [ifNetworkUnavailable, refundReasonMaps, showPaymentTypesModal, updateCart],
  );

  const showRefundReasonsModal = useCallback(
    async (
      refundedOrderItems: IMap<number>,
      refundOrderForProducts: Order,
      refundType: RefundType,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const orderItems: any[] = [];
      refundOrderForSelectedProducts.current = undefined;
      refundTypeRef.current = refundType;
      for (const [key, value] of Object.entries(refundedOrderItems)) {
        orderItems.push({ id: key, quantity: value });
      }

      if (refundType === RefundType.By_PRODUCT) {
        updateCart<RefundOrderItemsEvent>(OrderAction.ORDER_REFUND_ITEMS, {
          orderItems,
        });
        refundOrderForSelectedProducts.current = refundOrderForProducts;
      }
      if (!ifNetworkUnavailable()) {
        if (isRefundReasonsEnabled) {
          showModal(
            <ModalPicker
              title={translate('refundOrder.selectRefundReason')}
              onSelect={onSelectRefundReason}
              options={(refundReasons || []).map(x => ({
                label: x.name,
                value: x.id,
              }))}
            />,
          );
        } else {
          showPaymentTypesModal();
        }
      }
    },
    [
      ifNetworkUnavailable,
      isRefundReasonsEnabled,
      onSelectRefundReason,
      refundReasons,
      showModal,
      showPaymentTypesModal,
      translate,
      updateCart,
    ],
  );

  const showRefundModal = useCallback(async () => {
    discardChanges();
    await openOrderCart(selectedOrder.id);
    initiateRefund({ ...selectedOrder }, refundReason.current);
    if (!ifNetworkUnavailable()) {
      onClose();
      if (selectedOrder) {
        showModal(
          <RefundModal
            order={selectedOrder}
            onPressBtn={showRefundReasonsModal}
          />,
          {
            onBackdropPress: closeModal,
          },
        );
      }
    }
  }, [
    discardChanges,
    openOrderCart,
    selectedOrder,
    initiateRefund,
    ifNetworkUnavailable,
    onClose,
    showModal,
    showRefundReasonsModal,
    closeModal,
  ]);

  const onPressConfirm = useCallback(() => {
    if (selectedOrder) {
      const hasAccess = canI([{ onResource: Resource.REFUND_ORDER }], {
        prompt: true,
      });
      if (hasAccess) {
        if (selectedOrder.refundOf) {
          onPressPrintReceipt(selectedOrder);
        } else {
          showRefundModal();
        }
      }
    }
  }, [canI, selectedOrder, onPressPrintReceipt, showRefundModal]);

  const loading = getRefundOrderRes.loading || selectedOrder?.id !== order?.id;
  const isDineIn = selectedOrder?.table?.name;

  const ActionButton = useMemo(() => {
    if (!selectedOrder) return null;
    if (fromOnAccount) {
      return (
        <View style={styles.btnContainer}>
          <TreatButton
            testID="btn-payBalance"
            height={60}
            type={'positive'}
            onPress={() => onPressPayBalance?.(selectedOrder)}
            containerStyle={{ flex: 1, marginRight: 4 }}
            label={translate('onAccount.payBalance')}
          />
          <TreatButton
            testID="btn-printReceipt"
            height={60}
            type={'neutral'}
            onPress={() => onPressPrintReceipt(selectedOrder)}
            containerStyle={{ flex: 1, marginLeft: 4 }}
            label={translate('payment.printReceipt')}
          />
        </View>
      );
    } else {
      return (
        <TreatButton
          testID="btn-refund"
          height={60}
          type={!!selectedOrder?.refundOf ? 'neutral' : 'focus'}
          onPress={onPressConfirm}
          label={translate(
            disableConfirmRefundBtn
              ? 'refundOrder.refundCompleted'
              : !!selectedOrder?.refundOf
              ? 'refundOrder.printReceipt'
              : 'refundOrder.confirm',
          )}
          isLoading={loading}
          disabled={disableConfirmRefundBtn}
        />
      );
    }
  }, [
    selectedOrder,
    fromOnAccount,
    translate,
    onPressPayBalance,
    onPressPrintReceipt,
    onPressConfirm,
    disableConfirmRefundBtn,
    loading,
  ]);

  if (!order?.id) return <></>;

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      {loading ? (
        <View style={styles.placeholder}>
          <ActivityIndicator size={20} color={theme.colors.grey5} />
        </View>
      ) : !isConnected ? (
        <View style={styles.placeholder}>
          <Icon
            name="wifi-slash"
            size={20}
            color={theme.colors.states.negative}
          />
          <Text style={styles.errorText}>
            {translate('offline.orderHistory')}
          </Text>
          <TreatButton
            testID="btn-close"
            type="cancel"
            onPress={onClose}
            label={translate('button.dismiss')}
          />
        </View>
      ) : selectedOrder && !loading ? (
        <>
          <View style={styles.title}>
            <TouchableOpacity
              testID="btn-close"
              style={styles.btnClose}
              onPress={onClose}
            >
              <Icon name="times" size={20} color={theme.colors.dark} />
            </TouchableOpacity>
            <Text testID="order-no" style={styles.titleText}>
              {selectedOrder?.orderNumber}
            </Text>
            <Sticker
              testID="sticker-orderType"
              type={isDineIn ? 'neutralLight' : 'teal'}
              label={
                selectedOrder?.table?.name || selectedOrder?.orderType?.code
              }
              containerStyle={styles.orderType}
            />
          </View>
          {selectedOrder.customer ? (
            <View style={styles.customer}>
              <View style={styles.customerIcon}>
                <Icon name="user-circle" size={20} color={theme.colors.dark} />
              </View>
              <Text testID="order-customer">{`${selectedOrder.customer.firstName} ${selectedOrder.customer.lastName}`}</Text>
            </View>
          ) : null}
          <ScrollView style={styles.content}>
            {selectedOrder.orderNote ? (
              <CartNote testID="order-note" note={selectedOrder.orderNote} />
            ) : null}
            {selectedOrder.orderItems.map((item, i) => (
              <CartProduct
                testID={`order-item-${i}`}
                key={i}
                orderItem={item}
              />
            ))}
          </ScrollView>
          <CartTotals
            order={selectedOrder}
            containerStyle={styles.cartTotals}
          />
          {ActionButton}
        </>
      ) : null}
    </View>
  );
};

export default OrderPreview;
