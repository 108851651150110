import React, { FC, useCallback } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import styles from './PrintingError.styles';
import Icon from '../../Icon/Icon';
import theme from '../../../common/default-theme';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import { analyticsService } from '../../../analytics/AnalyticsService';

interface PrintFailedOverlayProps {
  onDismiss: () => void;
}

const PrintFailedOverlay: FC<PrintFailedOverlayProps> = ({ onDismiss }) => {
  const { translate } = useTranslation();
  const { height: vh, width: vw } = theme.useResponsiveDimensions();

  const onPressDismiss = useCallback(() => {
    onDismiss();
    analyticsService.capture('print_error', {
      action: 'dismissed_label',
    });
  }, [onDismiss]);

  return (
    <View style={[styles.mdlBg, { height: vh, width: vw }]}>
      <View style={styles.mdlBody}>
        <Icon
          size={44}
          name="exclamation-triangle"
          color={theme.colors.states.negative}
        />
        <View style={styles.mdlText}>
          <Text style={styles.mdlTitle}>
            {translate('printing.printFailedTitle')}
          </Text>
          <Text style={styles.mdlSubtitle}>
            {translate('printing.printFailedError')}
          </Text>
        </View>
        <TreatButton
          testID="btn-dismiss"
          type="negative"
          label={translate('button.dismiss')}
          onPress={onPressDismiss}
          containerStyle={styles.btnDismiss}
        />
      </View>
    </View>
  );
};

export default PrintFailedOverlay;
