import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { OrderAction, OrderEvent } from '@oolio-group/domain';
import { netWorkStatus } from '../../hooks/app/useNetworkStatusVar';

class CdsEventLink extends ApolloLink {
  private eventByOrderMap: Record<string, OrderEvent[]> = {};
  private events: OrderEvent[] = [];

  request = (operation: Operation, forward: NextLink) => {
    if (operation.operationName !== 'syncCdsEvents') return forward(operation);
    const event = operation.variables['input'][0] as OrderEvent | undefined;
    if (!event) return null;
    const handlers = [
      this.handleDuplicatePreviousEvent,
      this.handleNoNetworkEvent,
    ];
    let newOperation: Operation | null = operation;
    for (let i = 0; i < handlers.length; i++) {
      const handler = handlers[i];
      newOperation = handler(newOperation);
      if (!newOperation) return newOperation;
    }
    return forward(newOperation);
  };

  // filter duplicate event by a strange issue happen when syncSyncCdsEvent is called inside setState callback
  private handleDuplicatePreviousEvent = (
    operation: Operation,
  ): Operation | null => {
    if (operation.operationName !== 'syncCdsEvents') return operation;

    const event = operation.variables['input'][0] as OrderEvent | undefined;
    if (!event) return null;
    if (event.action === OrderAction.ORDER_OPEN) return operation;

    const { orderId } = event;
    if (event.action === OrderAction.ORDER_CLOSE) {
      this.eventByOrderMap[orderId] = [];
      return operation;
    }
    const events = this.eventByOrderMap[orderId];

    if (!events) {
      this.eventByOrderMap[orderId] = [];
      this.eventByOrderMap[orderId].push(event);
      return operation;
    }

    if (events.some(e => e.previous === event.previous)) return null;

    this.eventByOrderMap[orderId].push(event);
    return operation;
  };

  private handleNoNetworkEvent = (operation: Operation): Operation | null => {
    const events = operation.variables['input'] as OrderEvent[];

    if (events?.length) {
      const currentOrderId = this.events[0]?.orderId;
      if (!currentOrderId || currentOrderId !== events[0].orderId) {
        this.events = [];
      }
      this.events.push(...events);
    }

    if (!netWorkStatus()) {
      return null;
    }

    operation.variables['input'] = [...this.events];
    this.events = [];
    return operation;
  };

  cleanUp = () => {
    this.eventByOrderMap = {};
    this.events = [];
  };
}

const cdsEventLink = new CdsEventLink();

export { cdsEventLink };
