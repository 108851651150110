/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useMemo } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { App } from '@oolio-group/domain';
import { useTranslation, getSupportNumber } from '@oolio-group/localization';
import { OfficeUser, PosUser } from '../../../state/userUtility';
import { orderBy, concat } from 'lodash';
import styles from './ActiveUsers.styles';
import UserProfileTile from './UserProfileTile';
import Logo from '../../../components/Logo/Logo';
import Clock from '../../../components/Shared/Clock/Clock';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import { WorklogStatus } from '@oolio-group/domain';
import { useSession } from '../../../hooks/app/useSession';
import { useNetworkStatus } from '../../../hooks/app/useNetworkStatus';

export interface UserLockProfilesProps {
  app?: App;
  users: Array<OfficeUser | PosUser>;
  onSelectedUser: (user: OfficeUser | PosUser) => void;
  btnAttrs: {
    title: string;
    onPress: () => void;
  };
}

const ActiveUsersComponent: React.FC<UserLockProfilesProps> = ({
  users,
  onSelectedUser,
  btnAttrs,
  app = App.POS_APP,
}) => {
  const { isConnected } = useNetworkStatus();
  const { translate } = useTranslation();
  const [session] = useSession();
  const supportNumber = getSupportNumber(session.currentOrganization?.country);

  let box = 8 - users.length;
  if (box < 0) {
    box = (box % 4) + 4;
  }
  const onSelectUser = useCallback(
    (user: OfficeUser | PosUser) => {
      onSelectedUser(user);
    },
    [onSelectedUser],
  );

  const clockedInUsers = orderBy(
    users,
    [user => (user as PosUser)?.lastWorklog?.clockInTime],
    ['desc'],
  ).filter(
    user =>
      (user as PosUser)?.lastWorklog?.status === WorklogStatus.CLOCKED_IN ||
      (user as PosUser)?.lastWorklog?.status === WorklogStatus.IN_BREAK,
  );

  const notClockedInUsers = orderBy(
    users,
    [user => (user as PosUser)?.lastWorklog?.clockOutTime],
    ['desc'],
  ).filter(
    user =>
      (user as PosUser)?.lastWorklog?.status === WorklogStatus.CLOCKED_OUT ||
      !(user as PosUser)?.lastWorklog?.status,
  );

  const sortedWorkingUsers = useMemo(
    () => concat(clockedInUsers, notClockedInUsers),
    [clockedInUsers, notClockedInUsers],
  ) as PosUser[];

  const numberOfRowRequired = Math.max(
    Math.ceil(sortedWorkingUsers.length / 4),
    2,
  );

  return (
    <>
      <Clock containerStyle={styles.clock} />
      <View style={styles.container}>
        {!isConnected && (
          <Text style={styles.errorMessage} testID="noInternetInfoText">
            {translate('offline.noInternet')}
          </Text>
        )}
        <Logo type="logotype" color="primary" />
        <Text testID="prompt" style={styles.prompt}>
          {translate('settings.switchApp', {
            app: app === App.BACKOFFICE ? 'Office' : 'POS',
          })}
        </Text>
        <ScrollView
          style={styles.usersContainer}
          showsVerticalScrollIndicator={true}
        >
          <View
            style={styles.usersContentContainer}
            testID="user-lock-profiles"
          >
            {Array(numberOfRowRequired * 4)
              .fill(null)
              .map((_, i) => {
                const user = sortedWorkingUsers[i];
                if (user)
                  return (
                    <UserProfileTile
                      key={user.id}
                      user={user}
                      idx={i}
                      onSelectUser={onSelectUser}
                      disabled={!isConnected}
                      containerStyle={{ marginBottom: 20 }}
                    />
                  );
                return (
                  <View
                    key={i}
                    style={[styles.emptyUserContainer, { marginBottom: 20 }]}
                  />
                );
              })}
          </View>
        </ScrollView>
        <View style={styles.actions}>
          {!!supportNumber && (
            <Text style={styles.support}>
              {translate('settings.callSupport', {
                number: supportNumber,
              })}
            </Text>
          )}
          <TreatButton
            testID="btn-switchApp"
            label={btnAttrs.title}
            type="accent"
            onPress={btnAttrs.onPress}
          />
        </View>
      </View>
    </>
  );
};

const ActiveUsers = React.memo(ActiveUsersComponent);
ActiveUsers.displayName = 'ActiveUsers';

export default ActiveUsers;
